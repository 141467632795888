import React, { useEffect, useRef } from "react";
import { Animated, Easing } from "react-native";
import Icon from "src/components/Icon";

export default function TCLogoIcon({
  spinning = false,
  size = 20,
  color = "white",
  style = {}
}) {
  const rotateAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (spinning) {
      Animated.loop(
        Animated.timing(rotateAnim, {
          toValue: 1,
          duration: 1200,
          easing: Easing.linear,
          useNativeDriver: true
        })
      ).start();
    } else {
      rotateAnim.stopAnimation();
      rotateAnim.setValue(0);
    }
  }, [spinning]);

  const rotate = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"]
  });

  return (
    <Animated.View
      style={[
        {
          transform: [{ rotate }],
          alignItems: "center",
          justifyContent: "center",
          width: size * 1.4,
          height: size * 1.4
        },
        style
      ]}
    >
      <Icon
        icon="transparent-classroom-logo-circle"
        size={size}
        color={color}
        style={{ alignSelf: "center" }}
      />
    </Animated.View>
  );
}
