import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  Platform
} from "react-native";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Moment from "moment";
import Util from "src/utility";
import ExportOrdersButton from "src/components/ExportOrdersButton";
import Rex from "src/globalState";
import SearchBar from "src/components/searchBar";
import Icon from "src/components/Icon";
import { Skeleton } from "native-base";

const { height, width } = Glob.get("dimensions");
const LOADING = "LOADING";

const ProductItem = ({ product }) => (
  <View style={styles.productItem}>
    <Text style={styles.productName}>
      {product.name} {product.quantity > 1 && `(Quantity: ${product.quantity})`}{" "}
      •{" "}
      <Text style={styles.productDetails}>
        ${(product.amount / 100).toFixed(2)}
      </Text>
    </Text>
    {product.description && (
      <Text style={styles.productDescription}>{product.description}</Text>
    )}
  </View>
);

const OrderCard = ({ order, navigation, user }) => {
  const questionResponses = JSON.parse(
    order.metadata?.questionResponses || "{}"
  );
  const isCanceled = !!order.canceledAt;
  const cancelationMessage = order.canceledAt
    ? `Subscription canceled on ${Moment(order.canceledAt).format(
        "MMM D, YYYY"
      )}`
    : "";
  const { customerDetails } = order;
  let customerName = "(Anonymous)";
  if (user && (user.firstName || user.lastName))
    customerName = `${user.firstName} ${user.lastName}`;
  else if (customerDetails) customerName = customerDetails.name;
  const customerEmail =
    user?.email || customerDetails?.email || "(No email found)";

  const handleUserPress = async () => {
    // console.log("order");
    // console.log(order);
    if (user) {
      navigation.push("manageUser", {
        user,
        disableUserEditing: true
      });
    } else {
      Util.alert(
        "App Member Not Found",
        "Unable to retrieve member details. They might have checked out as a guest, or their member account might have been deleted."
      );
    }
  };

  const [showCharges, setShowCharges] = useState(false);

  const renderSubscriptionCharges = () => {
    if (!order.subscriptionCharges?.length) return null;

    return (
      <View>
        <TouchableOpacity
          onPress={() => setShowCharges(!showCharges)}
          style={styles.receiptButton}
        >
          <Text style={styles.receiptButtonText}>
            {showCharges ? "Hide" : "Show"} All Payments (
            {order.subscriptionCharges.length})
          </Text>
        </TouchableOpacity>

        {showCharges && (
          <View style={styles.chargesList}>
            {order.subscriptionCharges.map((charge) => (
              <Text key={charge.id} style={styles.chargeItem}>
                {Moment(charge.date).format("MMM D, YYYY")} • $
                {(charge.amount / 100).toFixed(2)}
                {charge.receiptUrl && (
                  <>
                    {" ("}
                    <Text
                      style={styles.receiptLink}
                      onPress={() => Util.openURL(charge.receiptUrl)}
                    >
                      Receipt
                    </Text>
                    )
                  </>
                )}
              </Text>
            ))}
          </View>
        )}
      </View>
    );
  };

  return (
    <View style={styles.orderCard}>
      <View style={styles.orderHeader}>
        <Text style={styles.orderDate}>
          {Moment(order.timestamp).format("MMM D, YYYY h:mm A")}
        </Text>
        <View style={styles.priceContainer}>
          {order.isSubscription && (
            <Icon
              icon="47b848ee-e819-41b8-bc53-a9e02d2a37f4"
              size={16}
              color="#2DD881"
              style={{ marginRight: 4 }}
            />
          )}
          <Text style={styles.orderPrice}>
            ${(order.price / 100).toFixed(2)}
          </Text>
        </View>
      </View>

      {isCanceled && (
        <View style={styles.cancellationBanner}>
          <Icon
            icon="ca34233d-eb11-4855-805f-bb7b4f8e1fcb"
            size={14}
            color="#E53E3E"
            style={{ marginRight: 4 }}
          />
          <Text style={styles.cancellationText}>{cancelationMessage}</Text>
        </View>
      )}

      <View style={styles.orderContentWrapper}>
        <TouchableOpacity
          onPress={handleUserPress}
          style={styles.customerInfoWrapper}
        >
          <View style={styles.orderDetails}>
            <View style={styles.customerNameContainer}>
              <Text
                style={[
                  styles.customerName,
                  { color: Rex.getConfig()?.colors?.text || "#2D3748" }
                ]}
              >
                {customerName}
              </Text>
              <Icon
                icon={
                  user
                    ? "0e866c6c-da2b-4852-973d-bc39d769e7a7"
                    : "4daf375a-e725-4534-a98e-801132c6388e"
                }
                size={12}
                color={user ? Rex.getConfig()?.colors?.text || "black" : "#ccc"}
                style={{ marginLeft: 6 }}
              />
            </View>
            <Text style={styles.customerEmail}>{customerEmail}</Text>
          </View>
        </TouchableOpacity>
        <View style={styles.productListWrapper}>
          <View style={styles.productList}>
            {order.products.map((product, index) => (
              <ProductItem key={`${order.id}-${index}`} product={product} />
            ))}
          </View>
        </View>
        {Object.keys(questionResponses).length > 0 && (
          <View style={styles.questionResponses}>
            {Object.entries(questionResponses).map(
              ([question, response], index) => (
                <View key={index} style={styles.questionResponseItem}>
                  <Text style={styles.question}>{question}</Text>
                  <Text style={styles.response}>{response}</Text>
                </View>
              )
            )}
          </View>
        )}
      </View>
      {order.receiptUrl && !order.isSubscription && (
        <TouchableOpacity
          style={styles.receiptButton}
          onPress={() => Util.openURL(order.receiptUrl)}
        >
          <Text style={styles.receiptButtonText}>Receipt</Text>
        </TouchableOpacity>
      )}
      {order.isSubscription && renderSubscriptionCharges()}
    </View>
  );
};

const SummarySkeletonLoader = () => (
  <View style={styles.summaryCard}>
    <View style={styles.summaryContainer}>
      <Skeleton h="8" w="50%" rounded="md" startColor="#eee" endColor="#ddd" />
      <Skeleton
        h="4"
        w="30%"
        rounded="full"
        mt="2"
        startColor="#eee"
        endColor="#ddd"
      />
    </View>
  </View>
);

const OrderSkeletonLoader = () => (
  <View style={styles.orderCard}>
    <View style={styles.orderHeader}>
      <Skeleton
        h="4"
        w="40%"
        rounded="full"
        startColor="#eee"
        endColor="#ddd"
      />
      <Skeleton h="6" w="20%" rounded="md" startColor="#eee" endColor="#ddd" />
    </View>

    <View style={styles.orderContentWrapper}>
      <View style={styles.customerInfoWrapper}>
        <View style={styles.orderDetails}>
          <Skeleton
            h="5"
            w="70%"
            rounded="md"
            mb="2"
            startColor="#eee"
            endColor="#ddd"
          />
          <Skeleton
            h="3"
            w="50%"
            rounded="full"
            startColor="#eee"
            endColor="#ddd"
          />
        </View>
      </View>

      <View style={styles.productListWrapper}>
        <View style={styles.productList}>
          {[1, 2].map((item) => (
            <View key={item} style={styles.productItem}>
              <Skeleton
                h="4"
                w="90%"
                rounded="full"
                mb="1"
                startColor="#eee"
                endColor="#ddd"
              />
              <Skeleton
                h="3"
                w="60%"
                rounded="full"
                startColor="#eee"
                endColor="#ddd"
              />
            </View>
          ))}
        </View>
      </View>
    </View>
  </View>
);

export default function CommerceOrderHistory({ navigation }) {
  const [loadedOrders, setLoadedOrders] = useState(false);
  const [orders, setOrders] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState(null);
  const [totalEarnings, setTotalEarnings] = useState(LOADING);
  const [users, setUsers] = useState({});

  useEffect(() => {
    Analytics.logEvent("view_commerceOrderHistory");
    fetchStripeOrders();
    Database.fetchAllUsers().then((allUsers) => {
      const userObj = {};
      allUsers.forEach((user) => {
        userObj[user.uid] = user;
      });
      setUsers(userObj);
    });
    fetchStripeOrderTotals();
  }, []);

  useEffect(() => {
    if (orders) {
      setFilteredOrders(orders);
      fetchStripeOrderTotals();
    }
  }, [orders]);

  const fetchStripeOrderTotals = async () => {
    const analyticsResponse = await Database.stripeConnectedAccountFetchChargesAnalytics();
    if (analyticsResponse.success) {
      const { totalEarnings: total } = analyticsResponse;
      setTotalEarnings(total);
    }
  };

  const fetchStripeOrders = async () => {
    const stripeOrders = await Database.fetchStripeConnectedAccountOrders();
    if (stripeOrders && Array.isArray(stripeOrders)) {
      // Fetch subscription charges for subscription orders
      const subscriptionOrders = stripeOrders.filter(
        (order) => order.isSubscription && order.subscriptionID
      );

      const subscriptionsPromises = subscriptionOrders.map((order) =>
        Database.fetchStripeConnectedAccountSubscriptionDetails(
          order.subscriptionID
        )
          .then((details) => ({
            ...details,
            charges: details.charges
              .filter((charge) => charge.status === "paid")
              .sort((a, b) => new Date(a.date) - new Date(b.date))
              .map((charge) => ({
                date: charge.date,
                amount: charge.amount,
                id: charge.id,
                receiptUrl: charge.receiptUrl,
                pdfUrl: charge.pdfUrl
              }))
          }))
          .catch((error) => {
            console.error(`Failed to fetch subscription details: ${error}`);
            return { charges: [] };
          })
      );

      const subscriptionsResults = await Promise.all(subscriptionsPromises);
      const subscriptionDetailsMap = subscriptionsResults.reduce(
        (acc, subscription) => {
          acc[subscription.subscriptionID] = subscription;
          return acc;
        },
        {}
      );

      // Add subscription details and charges to orders
      const ordersWithSearchableText = stripeOrders.map((order) => ({
        ...order,
        searchableText: createSearchableText(order),
        ...(order.subscriptionID && {
          subscriptionCharges:
            subscriptionDetailsMap[order.subscriptionID]?.charges || []
        })
      }));

      setOrders(ordersWithSearchableText);
    }
    setLoadedOrders(true);
  };

  const createSearchableText = (order) => {
    const user = users[order.metadata?.userID];
    const { customerDetails } = order;
    let customerName = user ? `${user.firstName} ${user.lastName}` : "";
    if (!customerName && customerDetails) customerName = customerDetails.name;
    const customerEmail = user?.email || customerDetails?.email || "";
    const orderDate = Moment(order.timestamp).format("MMM D, YYYY h:mm A");
    const orderPrice = (order.price / 100).toFixed(2);
    const productNames = order.products
      .map((product) => product.name)
      .join(" ");
    const productDescriptions = order.products
      .map((product) => product.description)
      .join(" ");
    const questionResponses = JSON.parse(
      order.metadata?.questionResponses || "{}"
    );
    const questionResponsesText = Object.entries(questionResponses)
      .map(([question, response]) => `${question}: ${response}`)
      .join(" ");
    return `${customerName} ${customerEmail} ${orderDate} ${orderPrice} ${productNames} ${productDescriptions} ${questionResponsesText}`.toLowerCase();
  };

  const handleSearch = (query) => {
    if (query.trim() === "") {
      setFilteredOrders(orders);
    } else {
      const searchResults = Util.searchItems(orders, query, "searchableText");
      setFilteredOrders(searchResults);
    }
  };

  if (!loadedOrders)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Order History" />
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.scrollViewContent}
        >
          <SummarySkeletonLoader />
          <View
            style={{ width: "100%", alignItems: "center", marginBottom: 12 }}
          >
            <SearchBar placeholder="Search orders..." disabled />
          </View>
          {[1, 2, 3].map((item) => (
            <OrderSkeletonLoader key={item} />
          ))}
        </ScrollView>
      </View>
    );

  if (!orders || orders.length < 1)
    return (
      <View style={[styles.pageContent, { backgroundColor: "white" }]}>
        <NavBar navigation={navigation} text="Order History" />
        <ScrollView
          style={{ paddingHorizontal: 20, width }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          <Image
            source={Glob.get("cardboardBoxOpen")}
            style={{
              height: height * 0.3,
              width: height * 0.3,
              resizeMode: "contain",
              alignSelf: "center"
            }}
          />
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 18,
              textAlign: "center"
            }}
          >
            You haven't received any orders yet.
          </Text>
        </ScrollView>
      </View>
    );

  return (
    <View style={styles.pageContent}>
      <NavBar
        navigation={navigation}
        text="Order History"
        // TODO: Make the export button export everything
        RightButton={
          Platform.OS === "web" ? (
            <ExportOrdersButton
              title="Order History"
              orders={filteredOrders || []}
            />
          ) : null
        }
      />
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.scrollViewContent}
      >
        <View style={styles.summaryCard}>
          <View style={styles.summaryContainer}>
            {totalEarnings === LOADING ? (
              <Skeleton
                h="8"
                w="50%"
                rounded="md"
                startColor="#eee"
                endColor="#ddd"
                speed={3}
              />
            ) : (
              <Text style={styles.summaryAmount}>
                $
                {(totalEarnings / 100).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </Text>
            )}
            <Text style={styles.summaryLabel}>
              {totalEarnings === LOADING
                ? "Calculating Total Revenue..."
                : "Total Revenue"}
            </Text>
          </View>
        </View>
        <View style={{ width: "100%", alignItems: "center", marginBottom: 12 }}>
          <SearchBar
            placeholder="Search orders..."
            onChangeText={handleSearch}
          />
        </View>
        {(filteredOrders || []).map((order) => (
          <OrderCard
            key={order.id}
            order={order}
            navigation={navigation}
            user={users[order.metadata?.userID] || null}
          />
        ))}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F5F7FA"
  },
  scrollView: {
    width
  },
  scrollViewContent: {
    paddingHorizontal: 20,
    paddingBottom: 50
  },
  orderCard: {
    backgroundColor: "white",
    borderRadius: 12,
    padding: 16,
    marginBottom: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
    overflow: "hidden"
  },
  orderHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 6
  },
  orderDate: {
    fontSize: 14,
    color: "#A0AEC0"
  },
  orderPrice: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#2DD881"
  },
  customerNameContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 2
  },
  customerName: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#2D3748"
  },
  customerEmail: {
    fontSize: 12,
    color: "#718096"
  },
  productListHeader: {
    fontSize: 16,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 8
  },
  productItem: {
    marginBottom: 8
  },
  productName: {
    fontSize: 14,
    fontWeight: "500",
    color: "#2D3748"
  },
  productDetails: {
    fontSize: 12,
    color: "#718096"
  },
  productDescription: {
    fontSize: 12,
    color: "#718096",
    marginTop: 2
  },
  orderContentWrapper: {
    flexDirection: Glob.deviceHasiPhoneXDimensions() ? "column" : "row"
  },
  customerInfoWrapper: {
    flex: 1,
    minWidth: 200,
    minHeight: "auto",
    paddingRight: 12,
    ...(Glob.deviceHasiPhoneXDimensions()
      ? {
          marginBottom: 12,
          borderBottomWidth: 1,
          borderBottomColor: "#E2E8F0",
          paddingBottom: 12
        }
      : {
          borderRightWidth: 1,
          borderRightColor: "#E2E8F0"
        })
  },
  productListWrapper: {
    flex: 2,
    minWidth: 300,
    ...(Glob.deviceHasiPhoneXDimensions()
      ? { paddingTop: 0 }
      : { paddingLeft: 12 })
  },
  orderDetails: {
    paddingTop: 12
  },
  productList: {
    paddingTop: Glob.deviceHasiPhoneXDimensions() ? 0 : 12
  },
  questionResponses: {
    marginTop: 12
  },
  questionResponseItem: {
    marginBottom: 8
  },
  question: {
    fontSize: 14,
    fontWeight: "500"
  },
  response: {
    fontSize: 14,
    color: "#718096",
    marginLeft: 10
  },
  cancellationBanner: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFF5F5",
    padding: 8,
    borderRadius: 4,
    marginBottom: 12
  },
  cancellationText: {
    color: "#E53E3E",
    fontSize: 12,
    fontWeight: "500"
  },
  chargesHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 4
  },
  subscriptionChargesHeader: {
    fontSize: 14,
    fontWeight: "500",
    color: "#4A5568"
  },
  expandIcon: {
    transform: [{ rotate: "0deg" }]
  },
  expandIconRotated: {
    transform: [{ rotate: "180deg" }]
  },
  chargesList: {
    marginTop: 8,
    paddingLeft: 13
  },
  chargeItem: {
    fontSize: 12,
    color: "#718096",
    marginBottom: 4
  },
  receiptLink: {
    color: "#4299E1",
    textDecorationLine: "underline"
  },
  receiptButton: {
    backgroundColor: "#F7FAFC",
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 4,
    alignSelf: "flex-start",
    marginTop: 12,
    borderWidth: 1,
    borderColor: "#E2E8F0"
  },
  receiptButtonText: {
    color: "#4A5568",
    fontSize: 12,
    fontWeight: "500"
  },
  priceContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  summaryCard: {
    backgroundColor: "white",
    borderRadius: 12,
    marginVertical: 16,
    width: "100%",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3
  },
  summaryContainer: {
    padding: 16,
    alignItems: "center"
  },
  summaryAmount: {
    fontSize: 32,
    fontWeight: "bold",
    color: "#2D3748"
  },
  summaryLabel: {
    fontSize: 14,
    color: "#718096",
    marginTop: 4
  }
});
