import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Alert,
  Modal,
  TouchableWithoutFeedback
} from "react-native";
import Glob from "src/globalConstants";
import NavBar from "src/components/navBar";
import { MaterialIcons } from "@expo/vector-icons";
import moment from "moment";
import Database from "src/backend/database";
import Util from "src/utility";
import ChildCard from "src/components/ChildCard";
import Rex from "src/globalState";
import { Skeleton } from "native-base";

const { width } = Glob.get("dimensions");

export default function CommerceInvoiceDetails({ route, navigation }) {
  const { invoice } = route.params;
  const [isLoading, setIsLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [children, setChildren] = useState({});
  const [classrooms, setClassrooms] = useState({});
  const [isLoadingChild, setIsLoadingChild] = useState(false);

  useEffect(() => {
    if (
      invoice?.metadata?.tcChildID &&
      Rex.getConfig()?.transparentClassroomIntegrationEnabled
    ) {
      fetchChildData();
    }
  }, [invoice?.metadata?.tcChildID]);

  const fetchChildData = async () => {
    try {
      setIsLoadingChild(true);
      const response = await Database.tcCheckIntegrationStatus();
      const { status } = response || {};
      const connected = status === Glob.get("tcIntegrationStatuses").CONNECTED;

      if (connected) {
        const childrenResponse = await Database.tcFetchChildren();
        const childrenByID = {};
        (childrenResponse?.data || []).forEach((child) => {
          childrenByID[child.id] = child;
        });
        setChildren(childrenByID);

        // Fetch classrooms if needed
        if (
          childrenByID[invoice.metadata.tcChildID]?.classroom_ids?.length > 0
        ) {
          const classroomsResponse = await Database.tcFetchClassrooms();
          const classroomsByID = {};
          (classroomsResponse?.data || []).forEach((classroom) => {
            classroomsByID[classroom.id] = classroom;
          });
          setClassrooms(classroomsByID);
        }
      }
    } catch (error) {
      console.error("Error fetching child data:", error);
    } finally {
      setIsLoadingChild(false);
    }
  };

  // Calculate discount information
  const hasDiscounts = invoice.discounts && invoice.discounts.length > 0;

  // Format discount description
  const getDiscountDescription = (discount) => {
    if (discount.coupon) {
      if (discount.coupon.percent_off) {
        return `${discount.coupon.percent_off}% off`;
      }
      if (discount.coupon.amount_off) {
        return `$${(discount.coupon.amount_off / 100).toFixed(2)} off`;
      }
    }
    return "Discount applied";
  };

  const openInvoiceURL = (url) => {
    if (url) Util.openURL(url);
  };

  const handleCancelInvoice = async () => {
    setShowCancelModal(true);
  };

  const confirmCancelInvoice = async () => {
    try {
      setIsLoading(true);
      setShowCancelModal(false);
      await Database.stripeConnectedAccountCancelInvoice(invoice.id);

      // Call the callback if it exists
      if (route.params?.onInvoiceUpdated) {
        route.params.onInvoiceUpdated();
      }

      navigation.goBack();
    } catch (error) {
      console.error("Error canceling invoice:", error);
      Alert.alert("Error", "Failed to cancel invoice. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const child = invoice?.metadata?.tcChildID
    ? children[invoice.metadata.tcChildID]
    : null;

  const handleChildPress = (childData) => {
    // Navigate to child detail screen
    navigation.push("tcChildDetails", {
      childID: childData.id,
      onUpdateChild: () => {
        // Refresh child data if needed
        fetchChildData();
      }
    });
  };

  const renderChildContent = () => {
    if (isLoadingChild) {
      return (
        <View style={styles.childCard}>
          <Skeleton size="10" rounded="full" style={{ marginRight: 12 }} />
          <View style={{ flex: 1 }}>
            <Skeleton.Text lines={2} />
          </View>
        </View>
      );
    }

    if (child) {
      return (
        <ChildCard
          child={child}
          classrooms={classrooms}
          onPress={() => handleChildPress(child)}
        />
      );
    }

    return null;
  };

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="Invoice Details" />
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.scrollViewContent}
      >
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Customer Information</Text>
          <View style={styles.detailRow}>
            <Text style={styles.label}>Name</Text>
            <Text style={styles.value}>{invoice.customer_name}</Text>
          </View>
          <View style={styles.detailRow}>
            <Text style={styles.label}>Email</Text>
            <Text style={styles.value}>{invoice.customer_email}</Text>
          </View>
          {invoice?.metadata?.tcChildID && renderChildContent()}
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Invoice Summary</Text>
          <View style={styles.detailRow}>
            <Text style={styles.label}>Status</Text>
            <Text
              style={[
                styles.value,
                { color: invoice.status === "paid" ? "#2DD881" : "#F6AD55" }
              ]}
            >
              {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
            </Text>
          </View>
          <View style={styles.detailRow}>
            <Text style={styles.label}>Amount Due</Text>
            <Text style={styles.value}>
              ${(invoice.amount_due / 100).toFixed(2)}
            </Text>
          </View>
          <View style={styles.detailRow}>
            <Text style={styles.label}>Created</Text>
            <Text style={styles.value}>
              {moment(invoice.created * 1000).format("MMM D, YYYY")}
            </Text>
          </View>
          {invoice.due_date && (
            <View style={styles.detailRow}>
              <Text style={styles.label}>Due Date</Text>
              <Text style={styles.value}>
                {moment(invoice.due_date * 1000).format("MMM D, YYYY")}
              </Text>
            </View>
          )}
          {invoice.paid && (
            <View style={styles.detailRow}>
              <Text style={styles.label}>Paid On</Text>
              <Text style={styles.value}>
                {moment(invoice.paid_at * 1000).format("MMM D, YYYY")}
              </Text>
            </View>
          )}
        </View>

        {/* New Discounts section - only show if discounts exist */}
        {hasDiscounts && (
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Discounts Applied</Text>
            {invoice.discounts.map((discount, index) => (
              <View key={index} style={styles.discountItem}>
                <View style={styles.discountDetails}>
                  <Text style={styles.discountName}>
                    {discount.coupon?.name || "Discount"}
                  </Text>
                  <Text style={styles.discountDescription}>
                    {getDiscountDescription(discount)}
                  </Text>
                </View>
                <View style={styles.discountBadge}>
                  <Text style={styles.discountBadgeText}>
                    {(() => {
                      const duration = discount.coupon?.duration;
                      if (duration === "once") return "One-time";
                      if (duration === "repeating") return "Recurring";
                      return "Forever";
                    })()}
                  </Text>
                </View>
              </View>
            ))}
            {invoice.subtotal !== invoice.total && (
              <View style={styles.totalSavings}>
                <Text style={styles.totalSavingsLabel}>Total Savings:</Text>
                <Text style={styles.totalSavingsAmount}>
                  ${((invoice.subtotal - invoice.total) / 100).toFixed(2)}
                </Text>
              </View>
            )}
          </View>
        )}

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Line Items</Text>
          {invoice.lines.data.map((item, index) => (
            <View key={index} style={styles.lineItem}>
              <Text style={styles.itemDescription}>{item.description}</Text>
              <Text style={styles.itemAmount}>
                ${(item.amount / 100).toFixed(2)}
              </Text>
            </View>
          ))}
        </View>

        <View style={styles.actions}>
          <TouchableOpacity
            style={styles.actionButton}
            onPress={() => openInvoiceURL(invoice.hosted_invoice_url)}
          >
            <MaterialIcons name="receipt" size={20} color="#4A5568" />
            <Text style={styles.actionButtonText}>View Invoice Online</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.actionButton}
            onPress={() => openInvoiceURL(invoice.invoice_pdf)}
          >
            <MaterialIcons name="picture-as-pdf" size={20} color="#4A5568" />
            <Text style={styles.actionButtonText}>View PDF</Text>
          </TouchableOpacity>

          {!invoice.paid && (
            <TouchableOpacity
              style={[styles.actionButton, styles.cancelButton]}
              disabled={isLoading}
              onPress={handleCancelInvoice}
            >
              <MaterialIcons name="close" size={18} color="#DC2626" />
              <Text style={[styles.actionButtonText, styles.cancelButtonText]}>
                {isLoading ? "Canceling..." : "Cancel Invoice"}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </ScrollView>

      <Modal
        visible={showCancelModal}
        transparent
        animationType="fade"
        onRequestClose={() => setShowCancelModal(false)}
      >
        <TouchableWithoutFeedback onPress={() => setShowCancelModal(false)}>
          <View style={styles.modalOverlay}>
            <TouchableWithoutFeedback>
              <View style={styles.modalContent}>
                <Text style={styles.modalTitle}>Cancel Invoice</Text>
                <Text style={styles.modalMessage}>
                  Are you sure you want to cancel this invoice? This action
                  cannot be undone.
                </Text>
                <View style={styles.modalButtons}>
                  <TouchableOpacity
                    style={[styles.modalButton, styles.modalCancelButton]}
                    onPress={() => setShowCancelModal(false)}
                  >
                    <Text style={styles.modalCancelText}>No, Keep Invoice</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.modalButton, styles.modalConfirmButton]}
                    onPress={confirmCancelInvoice}
                  >
                    <Text style={styles.modalConfirmText}>
                      Yes, Cancel Invoice
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F7FAFC"
  },
  scrollView: {
    flex: 1,
    padding: 16,
    width
  },
  scrollViewContent: {
    paddingBottom: 40
  },
  section: {
    backgroundColor: "white",
    borderRadius: 8,
    padding: 16,
    marginBottom: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 2
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 16
  },
  detailRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: "#EDF2F7"
  },
  label: {
    fontSize: 16,
    color: "#718096"
  },
  value: {
    fontSize: 16,
    color: "#2D3748",
    fontWeight: "500"
  },
  lineItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0"
  },
  itemDescription: {
    flex: 1,
    fontSize: 16,
    color: "#2D3748"
  },
  itemAmount: {
    fontSize: 16,
    color: "#2D3748",
    fontWeight: "500"
  },
  actions: {
    marginTop: 8,
    marginBottom: 20
  },
  actionButton: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    padding: 16,
    borderRadius: 8,
    marginBottom: 12,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 2
  },
  actionButtonText: {
    marginLeft: 12,
    fontSize: 16,
    color: "#4A5568",
    fontWeight: "500"
  },
  cancelButton: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "#FECACA"
  },
  cancelButtonText: {
    color: "#DC2626"
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    padding: 20
  },
  modalContent: {
    backgroundColor: "white",
    borderRadius: 12,
    padding: 20,
    width: "100%",
    maxWidth: 500,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#2D3748",
    marginBottom: 10
  },
  modalMessage: {
    fontSize: 16,
    color: "#4A5568",
    marginBottom: 20
  },
  modalButtons: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10
  },
  modalButton: {
    flex: 1,
    padding: 14,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 5
  },
  modalCancelButton: {
    backgroundColor: "#EDF2F7",
    borderWidth: 1,
    borderColor: "#E2E8F0"
  },
  modalConfirmButton: {
    backgroundColor: "#DC2626"
  },
  modalCancelText: {
    color: "#4A5568",
    fontWeight: "500",
    fontSize: 16
  },
  modalConfirmText: {
    color: "white",
    fontWeight: "500",
    fontSize: 16
  },
  discountItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0"
  },
  discountDetails: {
    flex: 1
  },
  discountName: {
    fontSize: 16,
    fontWeight: "500",
    color: "#2D3748"
  },
  discountDescription: {
    fontSize: 14,
    color: "#805AD5",
    marginTop: 2
  },
  discountBadge: {
    backgroundColor: "#EDF2F7",
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4
  },
  discountBadgeText: {
    fontSize: 12,
    color: "#4A5568"
  },
  totalSavings: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 12,
    paddingTop: 12,
    borderTopWidth: 1,
    borderTopColor: "#E2E8F0"
  },
  totalSavingsLabel: {
    fontSize: 16,
    fontWeight: "500",
    color: "#2D3748"
  },
  totalSavingsAmount: {
    fontSize: 16,
    fontWeight: "600",
    color: "#805AD5"
  },
  childSection: {
    marginTop: 16,
    borderTopWidth: 1,
    borderTopColor: "#EDF2F7",
    paddingTop: 16
  },
  childSectionTitle: {
    fontSize: 16,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 12
  },
  childCard: {
    flexDirection: "row",
    alignItems: "center",
    padding: 12
  }
});
