import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  Text,
  RefreshControl,
  TouchableOpacity,
  Platform
} from "react-native";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import ChildCard from "src/components/ChildCard";
import { Skeleton } from "native-base";
import NavBar from "src/components/navBar";
import SearchBar from "src/components/searchBar";
import * as Haptics from "expo-haptics";
import Glob from "src/globalConstants";

const { width } = Glob.get("dimensions");

export default function TCAllChildren({ navigation }) {
  const [children, setChildren] = useState([]);
  const [classrooms, setClassrooms] = useState({});
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [selectedClassroom, setSelectedClassroom] = useState("all");

  useEffect(() => {
    Analytics.logEvent("view_tcAllChildren");
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      // Get all children from the school
      const childrenResponse = await Database.tcFetchChildren();
      setChildren(childrenResponse.data || []);

      // Get classrooms for display purposes
      const classroomsResponse = await Database.tcFetchClassrooms();
      const classroomsMap = {};
      (classroomsResponse.data || []).forEach((classroom) => {
        classroomsMap[classroom.id] = classroom;
      });
      setClassrooms(classroomsMap);
    } catch (error) {
      console.error("Error fetching children data:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const onRefresh = () => {
    setRefreshing(true);
    fetchData();
  };

  const handleChildPress = (child) => {
    // Navigate to child detail screen
    navigation.push("tcChildDetails", {
      childID: child.id,
      onUpdateChild: () => {
        fetchData();
      }
    });
  };

  const onPressFilter = (classroomID) => {
    Analytics.logEvent("touch_tcAllChildren_filterClassroom", {
      classroomID
    });
    if (Platform.OS !== "web") Haptics.selectionAsync();
    setSelectedClassroom(classroomID);
  };

  const renderContent = () => {
    if (loading) {
      return (
        <View style={styles.loadingContainer}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
            <View key={`skeleton-${i}`} style={styles.childCardContainer}>
              <Skeleton
                size="10"
                rounded="full"
                startColor="#e2e8f0"
                style={{ marginRight: 12 }}
              />
              <View style={{ flex: 1 }}>
                <Skeleton.Text lines={2} />
              </View>
            </View>
          ))}
        </View>
      );
    }

    if (children.length === 0) {
      return (
        <View style={styles.emptyContainer}>
          <Text style={styles.emptyText}>No children found.</Text>
        </View>
      );
    }

    // Filter children based on search phrase and selected classroom
    let filteredChildren = children;

    // Filter by classroom if a specific one is selected
    if (selectedClassroom !== "all") {
      filteredChildren = filteredChildren.filter(
        (child) => (child.classroom_ids || []).indexOf(selectedClassroom) !== -1
      );
    }

    // Then filter by search phrase
    if (searchPhrase) {
      filteredChildren = filteredChildren.filter((child) => {
        const searchableText = `${child.first_name} ${child.last_name}`.toLowerCase();
        return searchableText.includes(searchPhrase.toLowerCase());
      });
    }

    return (
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        style={styles.scrollView}
      >
        <View style={styles.childrenList}>
          {filteredChildren.map((child) => (
            <ChildCard
              key={child.id}
              child={child}
              classrooms={classrooms}
              onPress={() => handleChildPress(child)}
            />
          ))}
        </View>
      </ScrollView>
    );
  };

  // Render the children count title
  const renderChildrenCount = () => {
    // Filter children based on search phrase and selected classroom
    let filteredChildren = children;

    // Filter by classroom if a specific one is selected
    if (selectedClassroom !== "all") {
      filteredChildren = filteredChildren.filter(
        (child) => (child.classroom_ids || []).indexOf(selectedClassroom) !== -1
      );
    }

    // Then filter by search phrase
    if (searchPhrase) {
      filteredChildren = filteredChildren.filter((child) => {
        const searchableText = `${child.first_name} ${child.last_name}`.toLowerCase();
        return searchableText.includes(searchPhrase.toLowerCase());
      });
    }

    let text = `${filteredChildren.length} ${
      filteredChildren.length === 1 ? "child" : "children"
    }`;
    if (filteredChildren.length === 0) text = "No children found";
    if (loading) text = "Loading...";

    return (
      <View style={styles.childrenCountContainer}>
        <Text style={styles.childrenCountText}>{text}</Text>
      </View>
    );
  };

  // Render classroom filter pills
  const renderClassroomFilters = () => {
    const classroomList = Object.values(classrooms || {});

    return (
      <View style={styles.filterContainer}>
        <View style={styles.filterContent}>
          <TouchableOpacity
            style={{
              ...styles.filterPill,
              backgroundColor:
                selectedClassroom === "all"
                  ? Rex.getConfig()?.colors?.button || "black"
                  : "#f0f0f0"
            }}
            activeOpacity={0.7}
            onPress={() => onPressFilter("all")}
          >
            <Text
              style={[
                styles.filterText,
                selectedClassroom === "all" && styles.filterTextActive
              ]}
            >
              All Classrooms
            </Text>
          </TouchableOpacity>

          {classroomList.map((classroom) => (
            <TouchableOpacity
              key={classroom.id}
              style={{
                ...styles.filterPill,
                backgroundColor:
                  selectedClassroom === classroom.id
                    ? Rex.getConfig()?.colors?.button || "black"
                    : "#f0f0f0"
              }}
              activeOpacity={0.7}
              onPress={() => onPressFilter(classroom.id)}
            >
              <Text
                style={[
                  styles.filterText,
                  selectedClassroom === classroom.id && styles.filterTextActive
                ]}
              >
                {classroom.name}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NavBar navigation={navigation} text="All Children" />
      <View style={{ flex: 1, width, alignItems: "center" }}>
        {renderChildrenCount()}
        {renderClassroomFilters()}
        <SearchBar
          placeholder="Search by name"
          onChangeText={setSearchPhrase}
        />
        {renderContent()}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#fff"
  },
  scrollView: {
    flex: 1,
    width
  },
  childrenList: {
    flex: 1,
    padding: 16
  },
  loadingContainer: {
    flex: 1,
    width,
    padding: 16
  },
  childCardContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#f0f0f0"
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 16
  },
  emptyText: {
    fontSize: 16,
    color: "#666",
    textAlign: "center"
  },
  filterContainer: {
    width: "100%",
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#f0f0f0"
  },
  filterContent: {
    paddingHorizontal: 16,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center"
  },
  filterPill: {
    paddingHorizontal: 10,
    justifyContent: "center",
    height: 24,
    borderRadius: 20,
    backgroundColor: "#f0f0f0",
    marginRight: 6,
    marginBottom: 6
  },
  filterText: {
    fontSize: 12,
    color: "#666"
  },
  filterTextActive: {
    color: "white",
    fontWeight: "bold"
  },
  childrenCountContainer: {
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 10
  },
  childrenCountText: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#333"
  }
});
