import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  KeyboardAvoidingView,
  ScrollView,
  Platform
} from "react-native";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Util from "src/utility";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import InputBox from "src/components/InputBox";
import LottieView from "src/components/Lottie";
import Checkbox from "src/components/Checkbox";
import PreviewTextMessage from "src/components/PreviewTextMessage";
import HelpText from "src/components/HelpText";

const PAPER_AIRPLANE_ANIMATION = require("resources/animations/paperAirplane.json");

const { width } = Glob.get("dimensions");

export default function SendTextsAndCalls({ navigation, route }) {
  const { params: { credits, savedPhoneNumbers } = {} } = route || {};
  const [userName, setUserName] = useState("");
  const [phoneNumbersText, setPhoneNumbersText] = useState(
    savedPhoneNumbers ? savedPhoneNumbers.join(", ") : ""
  );
  const [phoneNumbers, setPhoneNumbers] = useState(savedPhoneNumbers || null);
  const [message, setMessage] = useState("");
  const [invalidPhoneNumberCount, setInvalidPhoneNumberCount] = useState(0);
  const [didSendMessage, setDidSendMessage] = useState(false);
  const [shouldText, setShouldText] = useState(
    !!Rex.getConfig()?.textMessagingEnabled
  );
  const [shouldCall, setShouldCall] = useState(
    !Rex.getConfig()?.textMessagingEnabled &&
      !!Rex.getConfig()?.phoneCallsEnabled
  );

  useEffect(() => {
    Analytics.logEvent("view_sendTextsAndCalls");
    Database.fetchAllUserData().then((data) =>
      setUserName(`${data?.firstName} ${data?.lastName}`)
    );
  }, []);

  const onChangePhoneNumbersText = (text) => {
    setPhoneNumbersText(text);
    if (!text) {
      setPhoneNumbers([]);
      setInvalidPhoneNumberCount(0);
    } else {
      const [newNumbers, numInvalidNumbers] = Util.parsePhoneNumberList(text);
      setPhoneNumbers(newNumbers);
      setInvalidPhoneNumberCount(numInvalidNumbers);
    }
  };

  const onPressSendMessage = () => {
    Analytics.logEvent("touch_sendTextsAndCalls_send", {
      message,
      shouldText,
      shouldCall
    });
    setDidSendMessage(true);
    Database.sendTextsAndCalls(phoneNumbers, message, shouldText, shouldCall);
  };

  if (didSendMessage) {
    const plural = phoneNumbers?.length !== 1;
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Sending Messages" />
        <View style={{ flex: 2, maxHeight: "50%", width }}>
          <LottieView
            style={{ height: "100%", width: "100%" }}
            autoPlay
            loop
            source={PAPER_AIRPLANE_ANIMATION}
          />
        </View>
        <View
          style={{ flex: 1, paddingHorizontal: 20, marginBottom: 20, width }}
        >
          <Text style={Style.get("headerText")}>Sending now!</Text>
          <Text style={Style.get("subheaderText")}>
            Over the next few minutes, your message{plural ? "s" : ""} will be
            sent to {plural ? "these " : "this"}
            {plural ? phoneNumbers?.length || 0 : ""} phone number
            {plural ? "s" : ""}:
          </Text>
          <ScrollView
            style={{ marginBottom: 20 }}
            scrollIndicatorInsets={{ right: 1 }}
          >
            <Text style={Style.get("subheaderText")}>
              {(phoneNumbers || ["None"]).join(", ")}
            </Text>
          </ScrollView>
          <Button text="Done" onPress={navigation.goBack} />
        </View>
      </View>
    );
  }

  const bottomContainerStyle = {
    paddingTop: 24,
    paddingBottom: 32,
    backgroundColor: "white",
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: -5 },
    shadowRadius: 3,
    elevation: 20,
    width: "100%"
  };
  if (Platform.OS === "web")
    bottomContainerStyle.boxShadow = "0px -5px 3px rgba(0,0,0,0.15)";

  let messageLengthColor = "gray";
  if (message.length > Glob.get("maxTextMessageCharacters"))
    messageLengthColor = Glob.get("dangerRed");
  else if (message.length >= Glob.get("maxTextMessageCharacters") - 20)
    messageLengthColor = "#ebcd34";
  const notEnoughCredits = phoneNumbers?.length > credits;
  const canSend =
    !notEnoughCredits &&
    phoneNumbers?.length > 0 &&
    message?.length > 0 &&
    message?.length <= Glob.get("maxTextMessageCharacters") &&
    (shouldText || shouldCall);
  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text="Send Texts & Calls" />

      <ScrollView
        contentContainerStyle={{ width: width * 0.9 }}
        scrollIndicatorInsets={{ right: 1 }}
        keyboardDismissMode="on-drag"
      >
        <InputBox
          key="phoneNumbers"
          header="Phone Numbers (U.S. only)"
          placeholder="(123) 456-7890, +1.098.765.4321"
          description="List the phone number(s) of everyone you'd like to send a message to. Separate the phone numbers with commas, new lines, or tabs. Once you send a message, we'll save all valid phone numbers here for next time."
          onChangeText={onChangePhoneNumbersText}
          value={phoneNumbersText}
          multiline
          minHeight={100}
          autoCapitalize="none"
        />
        {invalidPhoneNumberCount > 0 && (
          <Text style={{ color: "gray" }}>
            {invalidPhoneNumberCount} invalid number
            {invalidPhoneNumberCount === 1 ? "" : "s"} will be skipped
          </Text>
        )}
        <InputBox
          key="message"
          header="Message"
          placeholder="Hey there!"
          onChangeText={setMessage}
          value={message}
          multiline
          isInvalid={message.length > Glob.get("maxTextMessageCharacters")}
        />
        <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
          <Text
            style={{
              color: messageLengthColor,
              fontWeight:
                message.length < Glob.get("maxTextMessageCharacters") - 20
                  ? "normal"
                  : "bold",
              marginRight: "9%"
            }}
          >
            {message.length}/{Glob.get("maxTextMessageCharacters")}
          </Text>
        </View>
        <Text style={[Style.get("headerText"), { marginTop: 10 }]}>
          Delivery
        </Text>
        {!!Rex.getConfig()?.textMessagingEnabled && (
          <Checkbox
            checked={shouldText}
            text="Send as text message"
            onChange={setShouldText}
          />
        )}
        {!!Rex.getConfig()?.phoneCallsEnabled && (
          <Checkbox
            checked={shouldCall}
            text="Send as automated phone call"
            onChange={setShouldCall}
          />
        )}
        {shouldCall && (
          <HelpText
            text={`Automated phone calls will begin by saying that the message was sent by ${userName} at ${
              Rex.getConfig()?.names?.full
            }.${
              shouldText
                ? ""
                : "\n\nTip: Automated phone calls won't leave a voicemail if unanswered, so we recommend sending your message as a text too."
            }`}
            noteStyle
          />
        )}
        {shouldText && (
          <>
            <Text
              style={[
                Style.get("headerText"),
                { marginTop: 20, marginBottom: 10 }
              ]}
            >
              Text Message Preview
            </Text>
            <PreviewTextMessage
              message={`From ${
                Rex.getConfig()?.names?.nickname
              }:\n${message}\n\n(Automated, don't reply)`}
              isTruncated={false}
            />
          </>
        )}
      </ScrollView>
      <View style={bottomContainerStyle}>
        <Button
          text={`Send to ${phoneNumbers?.length || "0"} number${
            phoneNumbers?.length === 1 ? "" : "s"
          }`}
          icon="send"
          onPress={onPressSendMessage}
          disabled={!canSend}
          style={{ opacity: canSend ? 1 : 0.4 }}
        />
        <Text
          style={{
            fontSize: 16,
            textAlign: "center",
            color: notEnoughCredits ? Glob.get("dangerRed") : "gray",
            fontWeight: notEnoughCredits ? "bold" : "normal"
          }}
        >
          This will use {phoneNumbers?.length || "0"} phone credit
          {phoneNumbers?.length === 1 ? "" : "s"} (out of{" "}
          {credits.toLocaleString()})
        </Text>
      </View>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white",
    alignItems: "center"
  }
});
