import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity
} from "react-native";
import moment from "moment";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import SearchBar from "src/components/searchBar";
import TCLogoIcon from "src/components/TCLogoIcon";

const { width } = Glob.get("dimensions");

export default function TCChildrensBirthdays({ navigation }) {
  const [loading, setLoading] = useState(true);
  const [tcChildren, setTCChildren] = useState(null); // Example: [{"birth_date": "2019-01-16", "classroom_ids": [Array], "first_day": "2024-09-01", "first_name": "Timmy", "gender": "X", "id": 699779, "last_name": "Montessori"}]
  const [filteredChildren, setFilteredChildren] = useState(null);

  const fetchTCData = async () => {
    Database.tcFetchChildren().then((response) => {
      const { success, data } = response || {};
      if (success && Array.isArray(data)) {
        const formattedData = data.map((child) => ({
          ...child,
          searchableFullName: `${child.first_name} ${child.last_name}`.toLowerCase()
        }));
        setTCChildren(formattedData);
        setFilteredChildren(formattedData);
      }
      setLoading(false);
    });
  };

  const searchChildren = (searchKey) => {
    if (!tcChildren) return;

    if (searchKey) {
      const searchLower = searchKey.toLowerCase();
      const filtered = (tcChildren || []).filter((child) =>
        child.searchableFullName.includes(searchLower)
      );
      setFilteredChildren(filtered);
    } else {
      setFilteredChildren(tcChildren);
    }
  };

  const handleChildPress = (child) => {
    // Navigate to child detail screen
    navigation.push("tcChildDetails", {
      childID: child.id,
      onUpdateChild: () => {
        fetchTCData();
      }
    });
  };

  useEffect(() => {
    Analytics.logEvent("view_tcChildrensBirthdays");
    fetchTCData();
  }, []);

  if (loading && !tcChildren) {
    return (
      <KeyboardAvoidingView
        style={styles.pageContent}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <NavBar navigation={navigation} text="Upcoming Birthdays" />
        <TCLogoIcon size={40} spinning style={{ marginTop: 40 }} />
      </KeyboardAvoidingView>
    );
  }

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text="Upcoming Birthdays" />
      <View style={styles.searchContainer}>
        <SearchBar
          placeholder="Search children by name"
          onChangeText={searchChildren}
        />
      </View>
      <ScrollView
        style={{ width }}
        contentContainerStyle={styles.container}
        scrollIndicatorInsets={{ right: 1 }}
        showsVerticalScrollIndicator={false}
      >
        {Array.from({ length: 12 }, (_, i) => {
          const currentMonth = new Date().getMonth() + 1;
          const month = ((currentMonth - 1 + i) % 12) + 1;

          const childrenInMonth = (filteredChildren || [])
            .filter((child) => {
              const [, birthMonth] = child.birth_date.split("-");
              return parseInt(birthMonth, 10) === month;
            })
            .sort((a, b) => {
              const [, , aDay] = a.birth_date.split("-");
              const [, , bDay] = b.birth_date.split("-");
              return parseInt(aDay, 10) - parseInt(bDay, 10);
            });

          if (childrenInMonth.length === 0) return null;

          let monthName = new Date(2024, month - 1).toLocaleString("default", {
            month: "long"
          });
          if (month < currentMonth) monthName = `${monthName} (Next Year)`;

          return (
            <View key={month} style={styles.monthSection}>
              <View style={styles.monthCard}>
                <Text style={styles.monthHeader}>{monthName}</Text>
                {childrenInMonth.map((child) => {
                  const birthDate = moment(child.birth_date);
                  const isToday =
                    moment().format("MM-DD") === birthDate.format("MM-DD");
                  const currentMonthString = moment().format("MM");
                  const birthMonthString = birthDate.format("MM");
                  // If it's this month, but the day has passed
                  const hasPassed =
                    currentMonthString === birthMonthString &&
                    moment().format("DD") > birthDate.format("DD");
                  const isTomorrow =
                    moment()
                      .add(1, "day")
                      .format("MM-DD") === birthDate.format("MM-DD");
                  const wasYesterday =
                    moment()
                      .subtract(1, "day")
                      .format("MM-DD") === birthDate.format("MM-DD");
                  const isFuture = !hasPassed && !isToday;
                  let age = moment().diff(birthDate, "years");
                  if (isFuture) age += 1;

                  return (
                    <TouchableOpacity
                      key={child.id}
                      style={[styles.childCard, isToday && styles.todayCard]}
                      onPress={() => handleChildPress(child)}
                    >
                      <View style={styles.dateCircle}>
                        <Text style={styles.dateDay}>
                          {birthDate.format("D")}
                        </Text>
                      </View>
                      <View style={styles.childInfo}>
                        <Text style={styles.childName}>
                          {child.first_name} {child.last_name}
                        </Text>
                        <Text style={styles.turningText}>
                          {hasPassed ? "turned" : "turns"} {age}{" "}
                          {isToday && "today! 🎉"}
                          {isTomorrow && "tomorrow. 🎈"}
                          {wasYesterday && "yesterday."}
                          {!isToday &&
                            !isTomorrow &&
                            !wasYesterday &&
                            `on ${birthDate.format("dddd, MMM D")}.`}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  );
                })}
              </View>
            </View>
          );
        })}
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "#f0f2f5",
    alignItems: "center"
  },
  container: {
    paddingHorizontal: 16,
    paddingVertical: 8
  },
  monthSection: {
    marginBottom: 16
  },
  monthCard: {
    borderRadius: 16,
    padding: 16,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 3
  },
  monthHeader: {
    fontSize: 24,
    fontWeight: "700",
    color: "#1a1a1a",
    marginBottom: 16,
    letterSpacing: 0.5
  },
  childCard: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#f8fafc",
    borderRadius: 12,
    padding: 12,
    marginBottom: 8,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.05,
    shadowRadius: 3,
    elevation: 1
  },
  todayCard: {
    backgroundColor: "#fff8e6",
    borderWidth: 1,
    borderColor: "#ffd700"
  },
  dateCircle: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: "#e8eef4",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12
  },
  dateDay: {
    fontSize: 18,
    fontWeight: "600",
    color: "#4a4a4a"
  },
  childInfo: {
    flex: 1
  },
  childName: {
    fontSize: 16,
    fontWeight: "600",
    color: "#1a1a1a",
    marginBottom: 2
  },
  turningText: {
    fontSize: 14,
    color: "#666666"
  },
  searchContainer: {
    width: "100%",
    paddingBottom: 10
  }
});
