import React, { useState, useEffect } from "react";
import { View, ScrollView, TouchableOpacity, Text } from "react-native";
import {
  signInWithEmailAndPassword,
  signInWithCustomToken
} from "firebase/auth/react-native";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Util from "src/utility";
import School from "school/school";
import Firebase from "src/backend/firebase";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import AlertModal from "src/components/AlertModal";
import NavBar from "src/components/navBar";
import AuthWidget from "src/components/auth/AuthWidget";

const { width } = Glob.get("dimensions");

export default function Login(props) {
  const { navigation, route } = props;
  const { sso } = route.params || {};
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [shouldSetSelfAsCreator, setShouldSetSelfAsCreator] = useState(false);
  const [allAccountTypeDetails, setAllAccountTypeDetails] = useState({});
  const [tcRoleToAccountType, setTcRoleToAccountType] = useState({});
  const [alert, setAlert] = useState(null);

  const signInWithTC = sso === "transparentClassroom";

  useEffect(() => {
    Analytics.logEvent("view_login");
    Database.fetchAppMetadata().then((appMetadata) => {
      if (!appMetadata?.creator) {
        School.appWasBuiltOnThisDeviceAsync().then((wasBuiltOnThisDevice) => {
          const isOwner =
            wasBuiltOnThisDevice || !!Rex.getConfig()?.appIsUnclaimed;
          setShouldSetSelfAsCreator(isOwner);
        });
      }
    });
    if (signInWithTC) {
      Database.fetchAllAccountTypeDetails().then((details) => {
        if (details) {
          setAllAccountTypeDetails(details);
          const mapRoleToType = {};
          Object.keys(details).forEach((key) => {
            const accountTypeDetails = details[key];
            if (accountTypeDetails.tcRole)
              mapRoleToType[accountTypeDetails.tcRole] = key;
          });
          setTcRoleToAccountType(mapRoleToType);
        }
      });
    }
  }, []);

  const navigate = (screenName, additionalProps = {}) => {
    navigation.push(screenName, { ...additionalProps, ...props });
  };

  const portalsAreValid = (portals) =>
    portals !== "" &&
    portals !== "[]" &&
    portals !== null &&
    portals !== undefined &&
    !(portals.length <= 0);

  const logUserIn = (userData = null) => {
    // If no owner has joined/claimed this app yet, mark it as claimed whenever the first person signs up
    if (Rex.getConfig()?.appIsUnclaimed) Database.markAppAsClaimed();
    if (shouldSetSelfAsCreator) {
      Analytics.logEvent("action_login_setSelfAsAppCreator");
      Database.setSelfAsAppCreator();
    }
    Rex.setLoginStatus(true);
    Analytics.setUserID();
    if (userData)
      Database.writeUserData({
        tcIDs: {
          tcUserID: userData.id,
          tcSchoolID: userData.school_id,
          signedInWithTC: true
        },
        ...(userData.phoneNumber ? { phoneNumber: userData.phoneNumber } : {})
      });
    setIsLoading(false);
    navigate("root");
  };

  const handleUserExistsButNotInThisApp = (userData = null) => {
    Rex.setLoginStatus(true);
    Analytics.setUserID();
    Analytics.logEvent("error_login_accountExistsButNotInThisApp", {
      email
    });
    setIsLoading(false);
    Util.alert(
      "Logged in! 🙌",
      `Now you can join ${Rex.getConfig()?.names?.nickname || "this app"}.`,
      [{ text: "OK", onPress: () => {} }]
    );
    if (userData) {
      const fullName = `${userData.first_name} ${userData.last_name}`;
      const accountTypeID =
        userData.primaryRole && userData.primaryRole in tcRoleToAccountType
          ? tcRoleToAccountType[userData.primaryRole]
          : undefined;
      const userInvitation = {
        firstName: userData.first_name,
        lastName: userData.last_name,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        tcIDs: {
          tcUserID: userData.id,
          tcSchoolID: userData.school_id,
          signedInWithTC: true
        }
      };
      if (accountTypeID && accountTypeID in allAccountTypeDetails) {
        Rex.setUserType(accountTypeID);
        navigate("signup", {
          fullName,
          accountType: {
            ...allAccountTypeDetails[accountTypeID],
            key: accountTypeID
          },
          userInvitation
        });
      } else navigate("pickAccountType", { fullName, userInvitation });
    } else {
      navigate("welcome");
    }
  };

  const login = async (
    alreadyAuthenticatedWithSSO = false,
    ssoUserData = null
  ) => {
    setIsLoading(true);
    try {
      if (!alreadyAuthenticatedWithSSO)
        await signInWithEmailAndPassword(Firebase.getAuth(), email, password);
      // first, check if this user exists in this app
      const user = await Database.fetchAllUserData();
      if (user && user.email) {
        Database.getUserPortals()
          .then((userPortalsValue) => {
            if (portalsAreValid(userPortalsValue)) {
              logUserIn(ssoUserData);
            } else {
              Database.listenUserHomeOrder((homeOrderValue) => {
                if (portalsAreValid(homeOrderValue)) {
                  Database.setUserPortals(JSON.parse(homeOrderValue));
                  logUserIn(ssoUserData);
                } else {
                  logUserIn(ssoUserData);
                }
              });
            }
          })
          .catch(() => handleUserExistsButNotInThisApp(ssoUserData));
      } else {
        handleUserExistsButNotInThisApp(ssoUserData);
      }
    } catch (e) {
      Analytics.logEvent("error_login_loginFailed", { email });
      setIsLoading(false);
      setAlert({
        title: "Login failed 😕",
        message:
          "Check your email/password and try again. Or if you've never created an account yet, go back and create an account first."
      });
    }
  };

  // todo: maybe handle if this user's email already exists in our database
  // todo: handle if the user had changed their onespot password (but not their TC password)
  const loginWithTC = async () => {
    setIsLoading(true);
    try {
      const response = await Database.tcSignIn(email, password);
      if (
        !response ||
        !response.success ||
        !response.firebaseAuthToken ||
        !response.data ||
        !response.data.id
      )
        throw new Error(
          response.message || "Failed to sign in with Transparent Classroom."
        );
      // TC sign in was successful.
      const userCredential = await signInWithCustomToken(
        Firebase.getAuth(),
        response.firebaseAuthToken
      );
      console.log("userCredential");
      console.log(userCredential);
      login(true, response.data);
    } catch (error) {
      Analytics.logEvent("error_login_signInWithTCFailed", { email });
      setIsLoading(false);
      console.log("Error:");
      console.log(error);
      setAlert({
        title: "Signing in with Transparent Classroom failed 😕",
        message: `${error.message ||
          error ||
          ""}\n\nYou could try checking your email/password and trying again. Or if you don't have a Transparent Classroom account, go back and select a different option to create an account in this app instead.`
      });
    }
  };

  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        alignItems: "center",
        backgroundColor: "white"
      }}
    >
      <NavBar
        navigation={navigation}
        text=""
        backgroundColor="white"
        buttonColor={Rex.getConfig()?.colors?.button}
      />
      <ScrollView
        contentContainerStyle={{ width, padding: 20 }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        <AuthWidget
          navigation={navigation}
          topImage={
            signInWithTC ? Glob.get("transparentClassroomLogoHorizontal") : null
          }
          title={signInWithTC ? "Sign in" : "Log in"}
          subtitle={
            signInWithTC
              ? "Enter the email and password for your Transparent Classroom account to sign in to your school's app."
              : `Welcome back to ${Rex.getConfig()?.names?.full || "this app"}.`
          }
          buttonText={signInWithTC ? "Sign in" : "Log in"}
          email={email}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
          isLoading={isLoading}
          showForgotPassword
          forgotPasswordLink={
            signInWithTC
              ? "https://www.transparentclassroom.com/souls/password/new"
              : null
          }
          userIsAlreadyLoggedIn={false}
          onEmailAuth={() => {
            Analytics.logEvent("touch_login_loginButton", { email });
            if (signInWithTC) loginWithTC();
            else login();
          }}
        />
        {!signInWithTC ? (
          <TouchableOpacity
            activeOpacity={0.7}
            style={{ marginTop: 40, alignItems: "center" }}
            onPress={() => {
              Analytics.logEvent("touch_login_dontHaveAccountSignUp");
              navigation.goBack();
            }}
          >
            <Text style={{ color: "#868686" }}>
              Don't have an account yet?{" "}
              <Text
                style={{
                  color: Rex.getConfig()?.colors?.button,
                  fontWeight: "bold"
                }}
              >
                Sign up
              </Text>
            </Text>
          </TouchableOpacity>
        ) : (
          <View style={{ marginTop: 40, alignItems: "center" }}>
            <Text style={{ color: "#868686", textAlign: "center" }}>
              Having trouble signing in?{"\n"}Email contact@onespotapps.com
            </Text>
          </View>
        )}
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}
