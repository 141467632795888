import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import NavBar from "src/components/navBar";
import Glob from "src/globalConstants";
import Analytics from "src/backend/analytics";
import TCActivityContainer from "src/components/TCActivityContainer";

const { width } = Glob.get("dimensions");

export default function TCActivityFeed({ route, navigation }) {
  const { childActivity, childName } = route.params || {};

  // If childActivity is passed, we're in child-specific view mode
  const isChildSpecificView = !!childActivity;

  useEffect(() => {
    // Log the appropriate analytics event based on mode
    if (isChildSpecificView) {
      Analytics.logEvent("view_tcChildActivityFeed", {
        childName
      });
    } else {
      Analytics.logEvent("view_tcActivityFeed");
    }
  }, []);

  // Content to display in the nav bar
  const navBarTitle = isChildSpecificView
    ? `${childName}'s Activity`
    : "Activity Feed";

  return (
    <View style={styles.container}>
      <NavBar navigation={navigation} text={navBarTitle} />

      <TCActivityContainer
        activityData={childActivity}
        childName={childName}
        showFilters={!isChildSpecificView}
        showSearch
        navigation={navigation}
        style={[
          styles.activityContainer,
          isChildSpecificView ? {} : { paddingHorizontal: 8, paddingTop: 16 }
        ]}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F5F7FA"
  },
  activityContainer: {
    flex: 1,
    width
    // width: "100%"
  }
});
