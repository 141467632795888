import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Platform
} from "react-native";
import Database from "src/backend/database";
import Rex from "src/globalState";
import { Skeleton, VStack, HStack } from "native-base";
import * as Haptics from "expo-haptics";
import SearchBar from "src/components/searchBar";
import TCActivityPost from "src/components/TCActivityPost";
import Util from "src/utility";

/**
 * Container component for handling fetching and displaying activity posts
 */
const TCActivityContainer = ({
  activityData,
  childId,
  childName,
  showFilters = false,
  maxItems = 0,
  showSearch = true,
  showViewMore = false,
  onViewMore,
  style,
  listStyle,
  itemStyle,
  navigation,
  numberOfLines
}) => {
  // Data state
  const [loading, setLoading] = useState(true);
  const [allPosts, setAllPosts] = useState(null);
  const [filteredPosts, setFilteredPosts] = useState(null);
  const [allChildren, setAllChildren] = useState({});
  const [allUsers, setAllUsers] = useState({});
  const [allLessons, setAllLessons] = useState({});
  const [classrooms, setClassrooms] = useState({});

  // Filter state
  const [selectedClassroom, setSelectedClassroom] = useState("all");
  const [filterLoading, setFilterLoading] = useState(false);

  // Detect if we're in child-specific view
  const isChildSpecificView = !!childId || !!activityData;

  useEffect(() => {
    fetchData();
  }, [childId, activityData]);

  const fetchData = async () => {
    if (loading === false) setLoading(true);

    try {
      // If activity data is provided, use it instead of fetching
      if (activityData) {
        await fetchSupportingData();

        // Format the data to match the structure used in the component
        const formattedData = activityData.map((post) => ({
          ...post,
          searchableAuthor: getAuthorFromPost(post).toLowerCase(),
          searchableText: post.normalized_text?.toLowerCase() || ""
        }));

        setAllPosts(formattedData);
        setFilteredPosts(formattedData);
        setLoading(false);
        return;
      }

      // If childId is provided, fetch only that child's activity
      if (childId) {
        const activityResponse = await Database.tcFetchOwnChildActivity(
          childId
        );
        if (activityResponse.success && activityResponse.data) {
          await fetchSupportingData();

          const formattedData = activityResponse.data.map((post) => ({
            ...post,
            searchableAuthor: getAuthorFromPost(post).toLowerCase(),
            searchableText: post.normalized_text?.toLowerCase() || ""
          }));

          setAllPosts(formattedData);
          setFilteredPosts(formattedData);
        }
        setLoading(false);
        return;
      }

      // Otherwise fetch all classroom posts (full activity feed view)
      const classroomsResponse = await Database.tcFetchClassrooms();
      const classroomsMap = {};
      const lessonSetIDs = [];

      (classroomsResponse.data || []).forEach((classroom) => {
        classroomsMap[classroom.id] = classroom;
        if (classroom.lesson_set_id) lessonSetIDs.push(classroom.lesson_set_id);
      });

      setClassrooms(classroomsMap);

      // Fetch supporting data
      fetchAndSetLessonNames(lessonSetIDs);
      fetchAndSetChildren();
      fetchAndSetUsers();

      // Fetch all posts
      Database.tcFetchAllClassroomPosts("all").then((response) => {
        const { success, data } = response || {};
        if (success && Array.isArray(data)) {
          const formattedData = data.map((post) => ({
            ...post,
            searchableAuthor: getAuthorFromPost(post).toLowerCase(),
            searchableText: post.normalized_text.toLowerCase()
          }));
          setAllPosts(formattedData);
          setFilteredPosts(formattedData);
        }
        setLoading(false);
      });
    } catch (error) {
      console.error("Error fetching activity data:", error);
      setLoading(false);
    }
  };

  // Fetch supporting data for child-specific view
  const fetchSupportingData = async () => {
    // Fetch classrooms
    const classroomsResponse = await Database.tcFetchOwnClassrooms();
    const classroomsMap = {};
    const lessonSetIDs = [];

    (classroomsResponse.data || []).forEach((classroom) => {
      classroomsMap[classroom.id] = classroom;
      if (classroom.lesson_set_id) lessonSetIDs.push(classroom.lesson_set_id);
    });

    setClassrooms(classroomsMap);

    // Fetch lesson details, children, and users in parallel
    await Promise.all([
      fetchAndSetLessonNames(lessonSetIDs),
      fetchAndSetChildren(),
      fetchAndSetUsers()
    ]);
  };

  const fetchAndSetChildren = async () => {
    try {
      let response;
      if (isChildSpecificView)
        response = await Database.tcFetchAllChildrenSimple();
      else response = await Database.tcFetchChildren();
      if (response?.data) {
        const childrenMap = {};
        (response.data || []).forEach((childData) => {
          childrenMap[childData.id] = childData;
        });
        setAllChildren(childrenMap);
      }
    } catch (error) {
      console.error("Error fetching children:", error);
    }
  };

  const fetchAndSetUsers = async () => {
    try {
      let response;
      if (isChildSpecificView)
        response = await Database.tcFetchAllUsersSimple();
      else response = await Database.tcFetchUsers();

      if (response?.data) {
        const usersMap = {};
        (response.data || []).forEach((user) => {
          usersMap[user.id] = user;
        });
        setAllUsers(usersMap);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchAndSetLessonNames = async (lessonSetIDs) => {
    try {
      const response = await Database.tcFetchLessonSetDetails(lessonSetIDs);
      setAllLessons(response.data);
    } catch (error) {
      console.error("Error fetching lessons:", error);
    }
  };

  const onPressLesson = (lessonSetID, lessonID) => {
    navigation.push("tcLessonDetails", { lessonSetID, lessonID });
  };

  // Helper function to extract author name from a post
  const getAuthorTextFromTag = (tag) => {
    // Handle old format where tag is the author HTML
    if (tag) {
      const author = tag.split(">")?.[1];
      const authorText = author?.split("<")?.[0] || "";
      return authorText;
    }
    return "";
  };

  // Extract author from post regardless of format
  const getAuthorFromPost = (post) => {
    // Case 1: Old format with author field containing HTML
    if (post.author) {
      return getAuthorTextFromTag(post.author);
    }

    // Case 2: New format with HTML field and author_id
    if (post.html && post.author_id) {
      // Extract first profile-link from HTML which should be the author
      const profileLinkMatch = post.html.match(
        /<a href=['"]\S+['"] class=['"]profile-link['"][^>]*>([^<]+)<\/a>/
      );
      if (profileLinkMatch && profileLinkMatch[1]) {
        return profileLinkMatch[1];
      }
    }

    return "";
  };

  const searchPosts = (searchKey) => {
    // First filter by selected classroom if applicable
    const classroomFiltered = filterPostsByClassroom(
      allPosts,
      selectedClassroom
    );

    // Then apply search filter
    if (!classroomFiltered) return;

    if (searchKey) {
      const searchLower = searchKey.toLowerCase();
      const filtered = classroomFiltered.filter(
        (post) =>
          post.searchableAuthor.includes(searchLower) ||
          post.searchableText.includes(searchLower)
      );
      setFilteredPosts(filtered);
    } else {
      setFilteredPosts(classroomFiltered);
    }
  };

  // Filter posts by classroom_id
  const filterPostsByClassroom = (posts, classroomId) => {
    if (!posts) return null;

    if (classroomId === "all") {
      return posts;
    }
    return posts.filter((post) => post.classroom_id === classroomId);
  };

  // Handle classroom filter selection
  const onPressFilter = (classroomId) => {
    if (Platform.OS !== "web") Haptics.selectionAsync();

    // Don't do anything if the same classroom is selected
    if (classroomId === selectedClassroom) return;

    setSelectedClassroom(classroomId);
    setFilterLoading(true);

    // Use setTimeout to allow the UI to update with the loading state
    setTimeout(() => {
      // Filter posts by the selected classroom
      const filtered = filterPostsByClassroom(allPosts, classroomId);
      setFilteredPosts(filtered);
      setFilterLoading(false);
    }, 300); // Small delay to show loading state
  };

  // Render classroom filter pills
  const renderClassroomFilters = () => {
    const classroomList = Object.values(classrooms || {});

    return (
      <View style={styles.filterContainer}>
        <View style={styles.filterContent}>
          <TouchableOpacity
            style={{
              ...styles.filterPill,
              backgroundColor:
                selectedClassroom === "all"
                  ? Rex.getConfig()?.colors?.button || "black"
                  : "white"
            }}
            activeOpacity={0.7}
            onPress={() => onPressFilter("all")}
          >
            <Text
              style={[
                styles.filterText,
                selectedClassroom === "all" && styles.filterTextActive
              ]}
            >
              All Classrooms
            </Text>
          </TouchableOpacity>

          {classroomList.map((classroom) => (
            <TouchableOpacity
              key={classroom.id}
              style={{
                ...styles.filterPill,
                backgroundColor:
                  selectedClassroom === classroom.id
                    ? Rex.getConfig()?.colors?.button || "black"
                    : "white"
              }}
              activeOpacity={0.7}
              onPress={() => onPressFilter(classroom.id)}
            >
              <Text
                style={[
                  styles.filterText,
                  selectedClassroom === classroom.id && styles.filterTextActive
                ]}
              >
                {classroom.name}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      </View>
    );
  };

  // Render loading skeleton
  const renderSkeletons = () => {
    return (
      <ScrollView
        style={styles.scrollContainer}
        contentContainerStyle={styles.scrollContent}
      >
        {(maxItems && maxItems < 5 ? [1] : [1, 2, 3]).map((item) => (
          <VStack
            key={item}
            space={3}
            p={4}
            mb={4}
            rounded="md"
            borderWidth={1}
            borderColor="coolGray.200"
            bgColor="white"
          >
            {/* Image placeholder */}
            <Skeleton h="150" rounded="md" startColor="coolGray.100" />

            {/* Author and date row */}
            <HStack space={2} justifyContent="space-between">
              <Skeleton w="40%" h="5" rounded="md" />
              <Skeleton w="30%" h="5" rounded="md" />
            </HStack>

            {/* Post text */}
            <Skeleton.Text lines={3} />
          </VStack>
        ))}
      </ScrollView>
    );
  };

  // Prepare the posts to display
  const getPostsToDisplay = () => {
    if (!filteredPosts) return [];

    // Sort posts by date (newest first)
    const sortedPosts = [...filteredPosts].sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );

    // If maxItems is set, limit the number of posts
    return maxItems > 0 ? sortedPosts.slice(0, maxItems) : sortedPosts;
  };

  // Main render logic
  const renderContent = () => {
    if (loading) {
      return renderSkeletons();
    }

    if (filterLoading) {
      return renderSkeletons();
    }

    if (!filteredPosts || filteredPosts.length === 0) {
      return (
        <Text style={styles.noPostsText}>
          {isChildSpecificView
            ? `No activity found for ${childName || "this child"}`
            : "No posts found"}
        </Text>
      );
    }

    const postsToDisplay = getPostsToDisplay();

    // If we're not limiting items, wrap in a ScrollView
    if (maxItems === 0) {
      return (
        <ScrollView
          style={styles.scrollContainer}
          contentContainerStyle={[styles.scrollContent, listStyle]}
          showsVerticalScrollIndicator={false}
        >
          {postsToDisplay.map((post) => (
            <TCActivityPost
              key={post.id}
              post={post}
              allUsers={allUsers}
              allChildren={allChildren}
              allLessons={allLessons}
              onPressLesson={onPressLesson}
              navigation={navigation}
              numberOfLines={numberOfLines}
              style={itemStyle}
              onUpdateChild={fetchData}
              isChildSpecificView={isChildSpecificView}
            />
          ))}
        </ScrollView>
      );
    }

    // Otherwise, just return a regular View (for dashboard)
    return (
      <View style={listStyle}>
        {postsToDisplay.map((post) => (
          <TCActivityPost
            key={post.id}
            post={post}
            allUsers={allUsers}
            allChildren={allChildren}
            allLessons={allLessons}
            onPressLesson={onPressLesson}
            navigation={navigation}
            numberOfLines={numberOfLines}
            style={itemStyle}
            onUpdateChild={fetchData}
            isChildSpecificView={isChildSpecificView}
          />
        ))}

        {showViewMore && filteredPosts.length > maxItems && (
          <TouchableOpacity
            style={[
              styles.viewMoreButton,
              {
                backgroundColor: Util.getSuperLightColor(
                  Rex.getConfig()?.colors?.button || "#000000"
                )
              }
            ]}
            onPress={onViewMore}
          >
            <Text
              style={[
                styles.viewMoreText,
                { color: Rex.getConfig()?.colors?.button || "black" }
              ]}
            >
              View all activity ({filteredPosts.length} posts)
            </Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  return (
    <View style={[styles.container, style]}>
      {showFilters && !isChildSpecificView && renderClassroomFilters()}

      {showSearch && (
        <View style={styles.searchContainer}>
          <SearchBar placeholder="Search posts" onChangeText={searchPosts} />
        </View>
      )}

      {renderContent()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%"
  },
  scrollContainer: {
    flex: 1
  },
  scrollContent: {
    paddingVertical: 8
  },
  searchContainer: {
    width: "100%",
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#f0f0f0"
  },
  filterContainer: {
    width: "100%",
    paddingHorizontal: 8
  },
  filterContent: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    width: "100%"
  },
  filterPill: {
    paddingHorizontal: 10,
    justifyContent: "center",
    height: 24,
    borderRadius: 20,
    backgroundColor: "#f0f0f0",
    marginRight: 6,
    marginBottom: 6
  },
  filterText: {
    fontSize: 12,
    color: "#666"
  },
  filterTextActive: {
    color: "white",
    fontWeight: "bold"
  },
  noPostsText: {
    fontSize: 16,
    color: "#666",
    textAlign: "center",
    marginTop: 20,
    alignSelf: "center",
    width: "100%",
    paddingHorizontal: 20
  },
  viewMoreButton: {
    alignItems: "center",
    padding: 12,
    marginTop: 4,
    backgroundColor: "#f0f0f0",
    borderRadius: 8,
    marginHorizontal: 8
  },
  viewMoreText: {
    fontSize: 14,
    fontWeight: "bold",
    color: "black"
  }
});

export default TCActivityContainer;
