import { Dimensions, Text, TextInput, Platform } from "react-native";
import * as Font from "expo-font";
import Rex from "src/globalState";
import {
  setCustomTextInput,
  setCustomText,
  setCustomTouchableOpacity
} from "react-native-global-props";

const { width } = Dimensions.get("window");

// Font.loadAsync({
//   Lato: require("resources/fonts/Lato/Lato-Regular.ttf")
// });

// TODO: Be able to handle larger text sizes for accessibilty
Text.defaultProps = Text.defaultProps || {};
TextInput.defaultProps = TextInput.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1.2;
TextInput.defaultProps.maxFontSizeMultiplier = 1.2;

if (Platform.OS === "ios") {
  setCustomText({ style: { fontFamily: "Avenir" } });
  setCustomTextInput({ style: { fontFamily: "Avenir" } });
} else if (Platform.OS === "android") {
  setCustomTextInput({ underlineColorAndroid: "transparent" });
}
setCustomTouchableOpacity({ activeOpacity: 0.6 });

export default class Style {
  static get(style) {
    const styles = {
      /* Style for normal text on buttons  */
      normalButtonText: {
        flex: 1,
        color: "white",
        fontSize: 22,
        textAlign: "center"
      },

      /* Style for a standard button */
      button: {
        backgroundColor: Rex.getConfig()?.colors?.button,
        width: 0.77 * width,
        minHeight: 50,
        maxWidth: 400,
        padding: 5,
        alignSelf: "center",
        marginVertical: 5,
        // marginBottom: 10,
        marginHorizontal: 5,
        borderRadius: 100,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
      },

      headerText: {
        fontSize: 18,
        color: Rex.getConfig()?.colors?.text || "#444",
        fontWeight: "bold"
      },

      subheaderText: {
        fontSize: 16,
        color: "gray"
      },

      card: {
        width: "100%",
        marginVertical: 10,
        borderRadius: 20,
        padding: 20,
        backgroundColor: "white",
        shadowOpacity: 0.2,
        shadowRadius: 3,
        shadowOffset: { width: 0, height: 0 },
        elevation: 3,
        ...(Platform.OS === "web"
          ? { boxShadow: "0px 0px 3px rgba(0,0,0,0.2)" }
          : {})
      }
    };
    return styles[style];
  }
}
