import React from "react";
import { View, Text, Image, StyleSheet } from "react-native";
import { Skeleton } from "native-base";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import Util from "src/utility";
import Icon from "src/components/Icon";

/**
 * Shared component for rendering activity posts with proper formatting
 * Used by both tcActivityFeed and tcChildDashboard
 */
const TCActivityPost = ({
  post,
  allUsers = {},
  allChildren = {},
  allLessons = {},
  onPressLesson,
  navigation,
  numberOfLines,
  style,
  onUpdateChild = () => {},
  isChildSpecificView
}) => {
  const postIsUntaggedPhoto = !!post.photo_url && !!post.staff_unprocessed;
  const postIsPrivate = post.private;

  // Helper function to extract author name from a post
  const getAuthorFromPost = (postData) => {
    // Case 1: Format with author field containing HTML tag
    if (postData.author) {
      return getAuthorTextFromTag(postData.author);
    }

    // Case 2: Format with author_id
    if (postData.author_id) {
      const author = allUsers[postData.author_id];
      if (author) return `${author.first_name} ${author.last_name}`;
    }

    // Case 3: New format with HTML field and author_id
    if (postData.html && postData.author_id) {
      // Extract first profile-link from HTML which should be the author
      const profileLinkMatch = postData.html.match(
        /<a href=['"]\S+['"] class=['"]profile-link['"][^>]*>([^<]+)<\/a>/
      );
      if (profileLinkMatch && profileLinkMatch[1]) {
        return profileLinkMatch[1];
      }

      // If we have author_id, try to get from allUsers
      const user = allUsers[postData.author_id];
      if (user) {
        return `${user.first_name} ${user.last_name}`;
      }
    }

    return "";
  };

  // Helper function to extract text from HTML tag
  const getAuthorTextFromTag = (authorTag) => {
    if (!authorTag) return "";
    const author = authorTag.split(">")?.[1];
    if (!author) return "";
    const authorText = author.split("<")?.[0];
    return authorText || "";
  };

  // Skeleton for loading text sections
  const TextSkeleton = () => (
    <View style={styles.textSkeletonContainer}>
      <Skeleton
        w="100%"
        h="3"
        rounded="sm"
        startColor="coolGray.200"
        endColor="coolGray.300"
        speed={2}
      />
    </View>
  );

  // Format post text to replace user/child/lesson IDs with real data
  const formatPostText = ({ normalized_text }) => {
    if (!normalized_text) return [];

    // Split the text by the tag patterns to process each part
    const parts = normalized_text.split(
      /(\[user_\d+\]|\[child_\d+\]|\[lesson_\d+\])/g
    );

    // Create array of elements with TouchableLinks for children
    return parts.map((part, index) => {
      // Check if this part is a user tag
      const userMatch = part.match(/\[user_(\d+)\]/);
      if (userMatch) {
        const userId = userMatch[1];
        const user = allUsers?.[userId];
        return user ? (
          <Text key={`user-${userId}-${index}`} style={{ fontWeight: "600" }}>
            {user.first_name} {user.last_name}
          </Text>
        ) : (
          <TextSkeleton key={`skeleton-user-${index}`} />
        );
      }

      // Check if this part is a child tag
      const childMatch = part.match(/\[child_(\d+)\]/);
      if (childMatch) {
        const childId = childMatch[1];
        const child = allChildren?.[childId];

        if (child && !isChildSpecificView) {
          return (
            <TouchableLink
              key={`child-${childId}-${index}`}
              type="button"
              text={`${child.first_name} ${child.last_name}`}
              onPress={() => {
                if (navigation) {
                  navigation.push("tcChildDetails", {
                    childID: child.id,
                    onUpdateChild
                  });
                }
              }}
              textStyle={{ fontWeight: "600" }}
            />
          );
        }
        if (child) {
          return (
            <Text
              key={`child-${childId}-${index}`}
              style={{ fontWeight: "600" }}
            >
              {`${child.first_name} ${child.last_name}`}
            </Text>
          );
        }
        return <TextSkeleton key={`skeleton-child-${index}`} />;
      }

      // Check if this part is a lesson tag
      const lessonMatch = part.match(/\[lesson_(\d+)\]/);
      if (lessonMatch) {
        const lessonId = lessonMatch[1];
        const lesson = allLessons?.[lessonId];
        return lesson && onPressLesson ? (
          <TouchableLink
            key={`lesson-${lessonId}-${index}`}
            type="button"
            text={lesson.name}
            onPress={() => onPressLesson(lesson.lessonSetID, lessonId)}
            textStyle={{ fontWeight: "600" }}
          />
        ) : (
          <TextSkeleton key={`skeleton-lesson-${index}`} />
        );
      }

      // Return other parts unchanged
      return part ? (
        <React.Fragment key={`text-${index}`}>{part}</React.Fragment>
      ) : null;
    });
  };

  return (
    <View
      style={[
        styles.postCard,
        style,
        postIsUntaggedPhoto && { backgroundColor: "#fffce0" },
        postIsPrivate && { backgroundColor: "#ddd" }
      ]}
    >
      {!!post.large_photo_url && (
        <View
          style={[
            styles.imageContainer,
            postIsUntaggedPhoto && { backgroundColor: "#fffce0" },
            postIsPrivate && { backgroundColor: "#ddd" }
          ]}
        >
          <Image
            source={{ uri: post.large_photo_url }}
            style={styles.postImage}
            resizeMode="contain"
          />
        </View>
      )}
      <View style={styles.postHeader}>
        <Text style={styles.authorName}>
          {postIsUntaggedPhoto ? "Untagged Photo" : getAuthorFromPost(post)}
          {"  "}
          {postIsPrivate && (
            <Icon
              icon="3054679b-9dcb-46da-96b7-9f89e221983a"
              size={15}
              color="#888"
            />
          )}
        </Text>
        <Text style={styles.postDate}>{Util.friendlyDate(post.date)}</Text>
      </View>
      <Text
        style={styles.postText}
        numberOfLines={numberOfLines}
        ellipsizeMode={numberOfLines ? "tail" : undefined}
      >
        {formatPostText(post).map((item, index) => (
          <React.Fragment key={index}>{item}</React.Fragment>
        ))}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  postCard: {
    backgroundColor: "white",
    borderRadius: 16,
    padding: 16,
    marginBottom: 16,
    marginHorizontal: 8,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 3
  },
  imageContainer: {
    height: 200,
    marginBottom: 12,
    backgroundColor: "#f5f5f5",
    borderRadius: 8,
    overflow: "hidden"
  },
  postImage: {
    width: "100%",
    height: "100%"
  },
  postHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8
  },
  authorName: {
    fontSize: 16,
    fontWeight: "600",
    color: "#1a1a1a"
  },
  postDate: {
    fontSize: 12,
    color: "#666666"
  },
  postText: {
    fontSize: 14,
    color: "#333333",
    lineHeight: 20
  },
  textSkeletonContainer: {
    width: 70,
    marginHorizontal: 4,
    borderRadius: 8,
    position: "relative",
    display: "inline-block"
  }
});

export default TCActivityPost;
