import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  Alert,
  Platform
} from "react-native";
import Glob from "src/globalConstants";
import NavBar from "src/components/navBar";
import { MaterialIcons } from "@expo/vector-icons";
import moment from "moment";
import Database from "src/backend/database";
import Util from "src/utility";
import Rex from "src/globalState";
import { Skeleton } from "native-base";
import ChildCard from "src/components/ChildCard";
import BottomSheetModal from "src/components/BottomSheetModal";

const { width } = Glob.get("dimensions");

export default function PaymentsCustomerSubscriptionDetails({
  route,
  navigation
}) {
  const { subscription } = route.params;
  const [isLoadingPortalURL, setIsLoadingPortalURL] = useState(false);
  const [portalURL, setPortalURL] = useState(null);
  const [children, setChildren] = useState({});
  const [classrooms, setClassrooms] = useState({});
  const [isLoadingChild, setIsLoadingChild] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(subscription);

  // Get the first item's price and product info
  const item = currentSubscription.items.data[0];
  const product = item?.price?.product;
  const interval = item?.price?.recurring?.interval || "month";
  const intervalCount = item?.price?.recurring?.interval_count || 1;
  const quantity = item?.quantity || 1;
  const unitAmount = item?.price?.unit_amount / 100; // Unit price in dollars
  const totalAmount = unitAmount * quantity; // Total amount accounting for quantity

  // Check if subscription has a child attached
  const tcChildID = currentSubscription?.metadata?.tcChildID;

  useEffect(() => {
    if (tcChildID && Rex.getConfig()?.transparentClassroomIntegrationEnabled) {
      fetchChildData();
    }
  }, [tcChildID]);

  const fetchChildData = async () => {
    try {
      setIsLoadingChild(true);
      const response = await Database.tcCheckIntegrationStatus();
      const { status } = response || {};
      const connected = status === Glob.get("tcIntegrationStatuses").CONNECTED;

      if (connected) {
        const childrenResponse = await Database.tcFetchChildren();
        const childrenByID = {};
        (childrenResponse?.data || []).forEach((child) => {
          childrenByID[child.id] = child;
        });
        setChildren(childrenByID);

        // Fetch classrooms if needed
        if (childrenByID[tcChildID]?.classroom_ids?.length > 0) {
          const classroomsResponse = await Database.tcFetchClassrooms();
          const classroomsByID = {};
          (classroomsResponse?.data || []).forEach((classroom) => {
            classroomsByID[classroom.id] = classroom;
          });
          setClassrooms(classroomsByID);
        }
      }
    } catch (error) {
      console.error("Error fetching child data:", error);
    } finally {
      setIsLoadingChild(false);
    }
  };

  // Format the billing cycle text
  let billingCycle = "";
  if (intervalCount === 1) {
    const intervalMap = {
      month: "Monthly",
      year: "Yearly",
      week: "Weekly",
      day: "Daily"
    };
    billingCycle = intervalMap[interval] || `${interval}ly`;
  } else {
    billingCycle = `Every ${intervalCount} ${interval +
      (intervalCount > 1 ? "s" : "")}`;
  }

  const openCustomerPortal = async (flowType = null) => {
    setIsLoadingPortalURL(true);

    try {
      const response = await Database.stripeConnectedAccountCreateCustomerPortalSession(
        {
          flowType,
          subscriptionId: currentSubscription.id
        }
      );

      if (response.success) {
        if (Platform.OS === "web") {
          Util.openURL(response.customerPortalSessionURL);
        } else {
          setPortalURL(response.customerPortalSessionURL);
        }
      }
    } catch (error) {
      console.error("Error creating customer portal session:", error);
      Alert.alert(
        "Error",
        "There was a problem opening the subscription management portal. Please try again."
      );
    } finally {
      setIsLoadingPortalURL(false);
    }
  };

  // Get the child data
  const child = tcChildID ? children[tcChildID] : null;

  // Render child section
  const renderChildSection = () => {
    if (!tcChildID) return null;

    if (isLoadingChild) {
      return (
        <View style={styles.childSection}>
          <Text style={styles.childSectionTitle}>Child Information</Text>
          <View style={styles.childSkeletonContainer}>
            <Skeleton size="10" rounded="full" style={{ marginRight: 12 }} />
            <View style={{ flex: 1 }}>
              <Skeleton.Text lines={2} />
            </View>
          </View>
        </View>
      );
    }

    if (child) {
      return (
        <View style={styles.childSection}>
          <Text style={styles.childSectionTitle}>Child Information</Text>
          <ChildCard child={child} classrooms={classrooms} />
        </View>
      );
    }

    return null;
  };

  return (
    <>
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Subscription Details" />
        <ScrollView style={styles.scrollView}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Subscription Summary</Text>

            <View style={styles.detailRow}>
              <Text style={styles.label}>Status</Text>
              <View style={styles.statusContainer}>
                <View
                  style={[
                    styles.statusDot,
                    {
                      backgroundColor:
                        currentSubscription.status === "active"
                          ? "#2DD881"
                          : "#F6AD55"
                    }
                  ]}
                />
                <Text
                  style={[
                    styles.value,
                    {
                      color:
                        currentSubscription.status === "active"
                          ? "#2DD881"
                          : "#F6AD55"
                    }
                  ]}
                >
                  {currentSubscription.status === "active"
                    ? "Active"
                    : currentSubscription.status.charAt(0).toUpperCase() +
                      currentSubscription.status.slice(1)}
                </Text>
              </View>
            </View>

            <View style={styles.detailRow}>
              <Text style={styles.label}>Product</Text>
              <Text style={styles.value}>
                {product?.name || "Subscription"}
              </Text>
            </View>

            <View style={styles.detailRow}>
              <Text style={styles.label}>Price</Text>
              <Text style={styles.value}>
                ${totalAmount.toFixed(2)} / {billingCycle}
              </Text>
            </View>

            {quantity > 1 && (
              <View style={styles.detailRow}>
                <Text style={styles.label}>Quantity</Text>
                <Text style={styles.value}>{quantity}</Text>
              </View>
            )}

            <View style={styles.detailRow}>
              <Text style={styles.label}>Renewal</Text>
              <Text style={styles.value}>
                {currentSubscription.cancel_at ||
                currentSubscription.cancel_at_period_end
                  ? "Cancels on "
                  : "Renews on "}
                {moment
                  .unix(currentSubscription.current_period_end)
                  .format("MMM D, YYYY")}
              </Text>
            </View>

            <View style={styles.detailRow}>
              <Text style={styles.label}>Created</Text>
              <Text style={styles.value}>
                {moment.unix(currentSubscription.created).format("MMM D, YYYY")}
              </Text>
            </View>

            <View style={styles.detailRow}>
              <Text style={styles.label}>Payment Method</Text>
              <Text style={styles.value}>
                {currentSubscription.collection_method ===
                "charge_automatically"
                  ? "Automatic payment"
                  : "Invoice"}
              </Text>
            </View>

            {currentSubscription.trial_end &&
              currentSubscription.trial_end > moment().unix() && (
                <View style={styles.detailRow}>
                  <Text style={styles.label}>Trial End</Text>
                  <Text style={[styles.value, { color: "#4299E1" }]}>
                    {moment
                      .unix(currentSubscription.trial_end)
                      .format("MMM D, YYYY")}
                  </Text>
                </View>
              )}

            {/* Cancellation notice */}
            {(currentSubscription.cancel_at ||
              currentSubscription.cancel_at_period_end) && (
              <View style={styles.cancelNotice}>
                <MaterialIcons name="info" size={20} color="#E53E3E" />
                <Text style={styles.cancelText}>
                  This subscription will{" "}
                  {currentSubscription.cancel_at
                    ? `end on ${moment
                        .unix(currentSubscription.cancel_at)
                        .format("MMM D, YYYY")}`
                    : `not renew after ${moment
                        .unix(currentSubscription.current_period_end)
                        .format("MMM D, YYYY")}`}
                </Text>
              </View>
            )}
          </View>

          {/* Child information section */}
          {tcChildID && renderChildSection()}

          <View style={styles.actions}>
            <TouchableOpacity
              style={styles.actionButton}
              onPress={() => openCustomerPortal()}
              disabled={isLoadingPortalURL}
            >
              {isLoadingPortalURL ? (
                <ActivityIndicator size="small" color="white" />
              ) : (
                <>
                  <MaterialIcons name="edit" size={20} color="#4A5568" />
                  <Text style={styles.actionButtonText}>
                    Manage Subscription
                  </Text>
                </>
              )}
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
      {!!portalURL && (
        <BottomSheetModal
          onClose={() => {
            setPortalURL(null);
            // Refresh subscription data when modal closes
            Database.stripeConnectedAccountFetchSubscriptions()
              .then((response) => {
                if (response.success && response.subscriptions) {
                  const updatedSubscription = response.subscriptions.find(
                    (sub) => sub.id === currentSubscription.id
                  );
                  if (updatedSubscription) {
                    setCurrentSubscription(updatedSubscription);
                  }
                }
              })
              .catch((error) =>
                console.error("Error refreshing subscription:", error)
              );
          }}
          url={portalURL}
          fullestStyleNative
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F7FAFC"
  },
  scrollView: {
    flex: 1,
    padding: 16,
    width
  },
  section: {
    backgroundColor: "white",
    borderRadius: 8,
    padding: 16,
    marginBottom: 16
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 16
  },
  detailRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 12
  },
  label: {
    color: "#718096",
    fontSize: 16
  },
  value: {
    color: "#2D3748",
    fontSize: 16,
    fontWeight: "500"
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 6
  },
  cancelNotice: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFF5F5",
    padding: 12,
    borderRadius: 6,
    marginTop: 16
  },
  cancelText: {
    marginLeft: 10,
    color: "#E53E3E",
    fontSize: 14
  },
  actions: {
    backgroundColor: "white",
    borderRadius: 8,
    padding: 16
  },
  actionButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
    borderRadius: 6,
    backgroundColor: "#EDF2F7",
    marginBottom: 8
  },
  primaryButton: {
    backgroundColor: "#2DD881",
    marginBottom: 16
  },
  actionButtonText: {
    marginLeft: 8,
    color: "#4A5568",
    fontSize: 16,
    fontWeight: "500"
  },
  primaryButtonText: {
    marginLeft: 8,
    color: "white",
    fontSize: 16,
    fontWeight: "500"
  },
  childSection: {
    backgroundColor: "white",
    borderRadius: 8,
    padding: 16,
    marginBottom: 16
  },
  childSectionTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 16
  },
  childSkeletonContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 12
  }
});
