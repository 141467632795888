import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity
} from "react-native";
import { Skeleton } from "native-base";
import Glob from "src/globalConstants";
import LottieView from "src/components/Lottie";
import TCLogoIcon from "src/components/TCLogoIcon";

const { height, width } = Dimensions.get("window");
const NOTIFICATION_ANIMATION = require("resources/animations/notificationPulseRed.json");

export default function SettingsItem({
  onPress = () => {},
  text = "Loading...",
  icon = null,
  isRed = false,
  showNotification = false,
  isLoading = false,
  isTransparentClassroom = false
}) {
  if (isLoading) {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingVertical: 7.5
        }}
      >
        <Skeleton size="22" rounded="full" startColor="#eee" endColor="#ddd" />
        <Skeleton
          h="4"
          w="40%"
          rounded="full"
          startColor="#eee"
          endColor="#ddd"
          style={{ marginLeft: 19 }}
        />
      </View>
    );
  }
  return (
    <View style={styles.textFieldBox}>
      <TouchableOpacity
        activeOpacity={0.7}
        onPress={onPress}
        style={styles.textBoxAndArrowContainer}
      >
        <>
          <View
            style={{
              width: 40,
              alignItems: "flex-start",
              justifyContent: "center"
            }}
          >
            {icon}
          </View>
          <Text
            style={[
              styles.textFieldText,
              isRed ? styles.textFieldTextRed : styles.textFieldTextTouchable
            ]}
          >
            {text}
          </Text>
          {isTransparentClassroom && (
            <TCLogoIcon size={10} style={{ marginLeft: 5 }} />
          )}
          {showNotification && (
            <LottieView
              style={{ width: 40, height: 40 }}
              autoPlay
              loop
              source={NOTIFICATION_ANIMATION}
            />
          )}
        </>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  /* Style for a text field box */
  textFieldBox: {
    flexDirection: "row"
  },

  /* Style for a container for an individual text box, including the text and arrow inside it */
  textBoxAndArrowContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%"
  },

  /* Style for the text inside a text field box, defaulted to a dark gray text */
  textFieldText: {
    fontSize: 14,
    color: "black",
    marginVertical: 10
  },

  /* Style (color) for a text field that can be touched */
  textFieldTextTouchable: {
    color: Glob.get("gray")
  },

  /* Style (color) for a text field that is highlighted in red */
  textFieldTextRed: {
    color: "#b47474"
  },

  /* Style for the arrow inside a text box field */
  arrowInTextBox: {
    marginRight: 0.027 * width,
    height: 0.03 * height,
    width: 0.053 * width,
    resizeMode: "contain",
    alignSelf: "center"
  }
});
