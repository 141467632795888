/* global window */
import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  KeyboardAvoidingView,
  TextInput,
  Platform,
  ActivityIndicator
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Entypo,
  FontAwesome5,
  Feather,
  AntDesign
} from "@expo/vector-icons";
import * as Haptics from "expo-haptics";
import { isMobile } from "react-device-detect";
import School from "school/school";
import Firebase from "src/backend/firebase";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Util from "src/utility";
import Button from "src/components/Button";
import NavBar from "src/components/navBar";
import DraggableList from "src/components/DraggableList";
import CustomList from "src/components/customList";
import ExpandableList from "src/components/dynamicContent/ExpandableList";
import EditableItemWrapper from "src/components/dynamicContent/EditableItemWrapper";
import TextItem from "src/components/dynamicContent/TextItem";
import ButtonItem from "src/components/dynamicContent/ButtonItem";
import MediaItem from "src/components/dynamicContent/MediaItem";
import ProductItem from "src/components/dynamicContent/ProductItem";
import DataSourceItem from "src/components/dynamicContent/DataSourceItem";
import HTMLItem from "src/components/dynamicContent/HTMLItem";
import AlertModal from "src/components/AlertModal";
import FloatingActionButton from "src/components/FloatingActionButton";
import BottomSheetModal from "src/components/BottomSheetModal";
import KeyboardToolbar from "src/components/KeyboardToolbar";
import Dropdown from "src/components/Dropdown";
import StatusMessage from "src/components/StatusMessage";
import InputBox from "src/components/InputBox";
import WebPageMetaTitle from "src/components/WebPageMetaTitle";
import Question from "src/components/Question";

const { height, width, fullWidth } = Glob.get("dimensions");
const screenMarginWidth = (fullWidth - width) / 2;
const POSITION_TO_ADD_ITEM = 0.4 * height;
const HELP_PAGE_URL =
  "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NAf6iKhG8KyHls5vmeC";

export default function ContentRenderer(props) {
  const {
    route,
    navigation,
    portalOverride: portalOverrideProp,
    // If ContentRenderer is used within a form portal, pass these props:
    formAnswers = {},
    setFormAnswers = () => {},
    formContent = {},
    FormSubmitButton = null
  } = props;
  const portalOverride = route?.params?.portalOverride || portalOverrideProp;
  const portalOverrideIsFromMiniScreen = !!portalOverride?.isFromMiniScreen;
  const portalName = route?.params?.navName;
  const [content, setContent] = useState([]); // list of JSON objects   // React Native objects
  const [originalContent, setOriginalContent] = useState([]);
  const [loadedContent, setLoadedContent] = useState(false);
  const [title, setTitle] = useState("");
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);
  const [isEditingAnItem, setIsEditingAnItem] = useState(false);
  const [isShowingHelpPage, setIsShowingHelpPage] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [isShowingAIEditor, setIsShowingAIEditor] = useState(false);
  const [newItemKeyNumber, setNewItemKeyNumber] = useState(0); // Used (and incremented) to enable unique keys, even if items are deleted
  const [alert, setAlert] = useState(null);
  const [dataSourceData, setDataSourceData] = useState({});
  const [isInHackerMode, setIsInHackerMode] = useState(false);
  const [hackerModeCode, setHackerModeCode] = useState(null);
  const [keyToIndexAndHeight, setKeyToIndexAndHeight] = useState({});
  const [scrollY, setScrollY] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [indexToAddItem, setIndexToAddItem] = useState(0);
  const [keyboardToolbarComponents, setKeyboardToolbarComponents] = useState(
    null
  );
  // Format: { dataSourceID1: { columnName1: { type: "[exact/search]", value: "[string]" }, columnName5: ... } }
  // Example: { dataSourceID1: { "Name": { type: "exact", value: "Tim Seldin" }, "Expertise": { type: "search", value: "leadersh" }}}
  const [dataSourceFilters, setDataSourceFilters] = useState({});
  // Format: { dataSourceID1: true, dataSourceID3: true }
  const [showDataSourceFilters, setShowDataSourceFilters] = useState({});
  const [openAIPrompt, setOpenAIPrompt] = useState("");
  const [openAIMessages, setOpenAIMessages] = useState([]);
  const [loadingAIGeneration, setLoadingAIGeneration] = useState(false);
  const [lastAIGenerationFailed, setLastAIGenerationFailed] = useState(false);
  const [user, setUser] = useState(null);
  const [productDropdownItems, setProductDropdownItems] = useState([]);
  const [commerceProducts, setCommerceProducts] = useState([]);
  const [commerceCheckoutSessionURL, setCommerceCheckoutSessionURL] = useState(
    null
  );

  const lastBotMessageIndex = openAIMessages
    .map((msg) => msg.role)
    .lastIndexOf("assistant");

  const draggableListRef = useRef(null);

  const isForm = FormSubmitButton !== null;

  const CONTENT_ITEM_TEMPLATES = {
    text: {
      type: "text",
      text: ""
    },
    header: {
      type: "text_header",
      text: ""
    },
    button_web: {
      type: "button_web",
      title: "Link",
      url: ""
    },
    button_nav: {
      type: "button_nav",
      title: "",
      portal: ""
    },
    button_chat: {
      type: "button_chat",
      title: "Message Me",
      recipient: null // this will be populated when item is added
    },
    button_app_switcher: {
      type: "button_app_switcher",
      title: "App Switcher",
      appID: "Testing"
    },
    button_sms: {
      type: "button_sms",
      title: "Tell a Friend",
      recipient: "",
      content: `Want to learn more? Check out the ${
        Rex.getConfig()?.names?.nickname
      } mobile app!`
    },
    button_email: {
      type: "button_email",
      title: "Email Us",
      email: ""
    },
    button_call: {
      type: "button_call",
      title: "Call Us",
      number: ""
    },
    image: {
      type: "image",
      image: "https://picsum.photos/500?grayscale"
    },
    video: {
      type: "video",
      video: "https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4"
    },
    video_youtube: {
      type: "video",
      video: "https://www.youtube.com/watch?v=IkKhm-iLFB4"
    },
    video_vimeo: {
      type: "video",
      video: "https://vimeo.com/618506702"
    },
    commerce_product: {
      type: "commerce_product",
      productID: null
    },
    horizontal_line: { type: "horizontal_line" },
    blank_space: { type: "blank_space" },
    link_list: {
      type: "link_list",
      rows: [
        {
          title: "Facebook",
          url: "https://www.facebook.com"
        },
        {
          title: "Instagram",
          url: "https://www.instagram.com"
        },
        {
          title: "YouTube",
          url: "https://www.youtube.com"
        }
      ]
    },
    expandable_list: {
      type: "expandable_list",
      content: [
        {
          body: [
            {
              text: null,
              type: "text"
            }
          ],
          header: [
            {
              style: {
                textAlign: "left"
              },
              text: null,
              type: "text_header"
            }
          ]
        }
      ]
    },
    data_source_start: {
      type: "data_source",
      isStart: true,
      isRepeating: true,
      googleSheetID: Glob.get("exampleGoogleSheetID"),
      googleSheetName: "Directory"
    },
    data_source_example_text: {
      type: "text",
      text: "{{First Name}}"
    },
    data_source_end: {
      type: "data_source",
      isStart: false
    },
    html: {
      type: "html",
      code: `<p style="color:orchid">onespot is magical ✨</p>`
    },
    button_share_app: {
      type: "button_share_app",
      title: "Share This App"
    },
    button_pdf: {
      type: "button_pdf",
      title: "View PDF",
      url: ""
    },
    form_question: {
      type: "form_question",
      inputType: "text",
      question: "",
      answer: ""
    }
  };

  useEffect(() => {
    if (Platform.OS === "web") {
      setScrollY(window.scrollY);
      window.addEventListener("scroll", (e) => {
        setScrollY(e.currentTarget.scrollY);
      });
    }
    Database.fetchAllUserData(true).then(setUser);
    if (Rex.getConfig()?.commerceEnabled) {
      Database.fetchCommerce()
        .then(async (commerce) => {
          if (commerce?.config?.stripeConnect?.accountID) {
            const stripeAccount = await Database.fetchStripeConnectedAccount();
            const accountIsConfigured =
              stripeAccount?.charges_enabled && stripeAccount?.payouts_enabled;
            if (accountIsConfigured) {
              const allProducts = await fetchAllCommerceProducts();
              const productItems = convertCommerceProductsToDropdownItems(
                allProducts
              );
              if (productItems?.length > 0) {
                setProductDropdownItems(productItems);
                setCommerceProducts(allProducts);
              }
            }
          }
        })
        .catch((error) => {
          console.log(
            "Error fetching commerce. User might not be authenticated."
          );
          console.log(error);
        });
    }

    if (portalOverride) {
      if (portalOverride.content) {
        Analytics.logEvent("view_dynamic", {
          key: portalName,
          title: portalOverride.title,
          isFromMiniScreen: portalOverride.isFromMiniScreen
        });
        formatContentAsync(portalOverride.content);
        setTitle(portalOverride.title);
        setBackgroundColor(portalOverride.backgroundColor);
      }
      // TODO: Remove this legacy code once all dynamic content is hosted in Firebase instead of in any School.js file
      // For now, this handles the legacy system and also lets us easily import the portal data from School.js to Firebase
      else if (School.dynamic) {
        const valueBackup = School.dynamic(portalName);
        setTitle(valueBackup.title);
        formatContentAsync(valueBackup.content);
      } else {
        setLoadedContent(true);
      }
      setCanEdit(!portalOverrideIsFromMiniScreen);
      setIsEditing(!portalOverrideIsFromMiniScreen);
    } else {
      Database.getPortalContentNew(portalName).then((value) => {
        if (value) {
          setTitle(value.title);
          setBackgroundColor(value.backgroundColor);
          Analytics.logEvent("view_dynamic", {
            key: portalName,
            title: value.title
          });
          if (value.content) {
            formatContentAsync(value.content);
          } else {
            setLoadedContent(true);
          }
        }
        // TODO: Remove this legacy code once all dynamic content is hosted in Firebase instead of in any School.js file
        // For now, this handles the legacy system and also lets us easily import the portal data from School.js to Firebase
        else if (School.dynamic) {
          const valueBackup = School.dynamic(portalName);
          setTitle(valueBackup.title);
          formatContentAsync(valueBackup.content);
        } else {
          setLoadedContent(true);
        }
      });
      const privileges = Rex.getSessionMemory("adminPrivileges");
      Database.fetchPortalEditors(portalName).then((editors) => {
        const userCanEditThisSpecificPortal =
          editors && editors[Firebase.getUserID()] === true;
        setCanEdit(
          privileges?.includes("EditAllPortals") ||
            userCanEditThisSpecificPortal
        );
      });
    }
  }, []);

  useEffect(() => {
    if (isAddingNewItem) calculateIndexToAddItem();
  }, [scrollY, content]);

  const onStartCheckoutSession = (url) => {
    if (Platform.OS === "web") Util.openURL(url);
    else setCommerceCheckoutSessionURL(url);
  };

  const convertCommerceProductsToDropdownItems = (products) => {
    return products
      .map((p) => ({
        value: p.id,
        text: `${p.name} • $${(p.default_price?.unit_amount / 100 || 0).toFixed(
          2
        )}${
          p.default_price?.recurring?.interval
            ? `/${p.default_price.recurring.interval}`
            : ""
        }`
      }))
      .sort((a, b) => {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
        return 0;
      });
  };

  const fetchAllCommerceProducts = async () => {
    return new Promise(async (resolve) => {
      const stripeProducts = await Database.fetchStripeConnectedAccountProducts();
      if (
        stripeProducts?.data &&
        Array.isArray(stripeProducts.data) &&
        stripeProducts.data.length > 0
      ) {
        return resolve(stripeProducts.data);
      }
      return resolve([]);
    });
  };

  const setItemIndexAndHeight = (key, itemIndex, itemHeight) => {
    setKeyToIndexAndHeight((prev) => ({
      ...prev,
      [key]: { itemIndex, itemHeight }
    }));
  };

  const calculateDataSourceID = (googleSheetID, googleSheetName) =>
    `${googleSheetID}-${googleSheetName}`;

  const reformatContent = () => {
    formatContentAsync(content);
  };

  const formatContentAsync = async (rawContent) => {
    const fetchedDataSourceData = {};
    let dataSourceID = null;
    const formattedContent = await rawContent.asyncMap(async (item, idx) => {
      if (item?.type === "data_source") {
        if (item?.isStart && item?.googleSheetID && item?.googleSheetName) {
          dataSourceID = calculateDataSourceID(
            item.googleSheetID,
            item.googleSheetName
          );
          const data = await Util.fetchGoogleSheetData(
            item.googleSheetID,
            item.googleSheetName
          );
          // We need to recalculate this because the map function doesn't wait for the sheet to return data
          const dataSourceIDToSet = calculateDataSourceID(
            item.googleSheetID,
            item.googleSheetName
          );
          fetchedDataSourceData[dataSourceIDToSet] = data;
        } else {
          // End of data source section
          dataSourceID = null;
        }
      }
      const formattedItem = { ...item, key: `id-${idx}` };
      if (dataSourceID) formattedItem.dataSourceID = dataSourceID;
      return formattedItem;
    });
    setContent(formattedContent);
    setOriginalContent(formattedContent);
    setLoadedContent(true);
    setDataSourceData(fetchedDataSourceData);
    setNewItemKeyNumber(formattedContent.length);
  };

  const generateContentComponentsList = (allContent, dataSourceRowOverride) => {
    const componentsList = [];
    let data = null; // if this is set, then we're building the list of components to repeat
    let dataSourceItemsToRepeat = [];
    let dataSourceID = null;
    allContent.forEach((item, i) => {
      if (item?.type === "data_source") {
        if (item?.isStart && item?.googleSheetID && item?.googleSheetName) {
          dataSourceID = calculateDataSourceID(
            item.googleSheetID,
            item.googleSheetName
          );
          const fixedDataSourceID = `${dataSourceID}`;
          data = dataSourceData[fixedDataSourceID];
          if (item.filters && Object.values(item.filters).some((v) => v)) {
            if (showDataSourceFilters[fixedDataSourceID]) {
              componentsList.push(
                <Button
                  text="Clear Filters"
                  icon="8f20f056-c494-4f3b-925e-1b950a8434d5"
                  onPress={() => {
                    setShowDataSourceFilters((prevFilters) => ({
                      ...prevFilters,
                      [fixedDataSourceID]: false
                    }));
                    setDataSourceFilters((prevFilters) => ({
                      ...prevFilters,
                      [fixedDataSourceID]: null
                    }));
                  }}
                  color="gray"
                  align="left"
                  small
                  outline
                />
              );
              const columnNames =
                data && data.length > 0 ? Object.keys(data[0]) : [];
              const filters = columnNames.filter((c) => item.filters[c]);
              const valuesByColumn = {};
              if (data)
                data.forEach((row) => {
                  filters.forEach((columnName) => {
                    if (columnName in valuesByColumn)
                      valuesByColumn[columnName].push(row[columnName]);
                    else valuesByColumn[columnName] = [row[columnName]];
                  });
                });
              filters.forEach((columnName) => {
                componentsList.push(
                  <View
                    style={{
                      paddingLeft: 0.05 * width,
                      paddingRight: 0.05 * width
                    }}
                  >
                    <Dropdown
                      header={`Filter by ${columnName}`}
                      enableAutocomplete
                      enableSearchIfTextIsLong
                      placeholder="Search..."
                      items={[...new Set(valuesByColumn[columnName])]
                        .map((val) => ({
                          text: val,
                          value: val
                        }))
                        .sort((a, b) => {
                          // sort alphabetically
                          if (a.text < b.text) return -1;
                          if (a.text > b.text) return 1;
                          return 0;
                        })}
                      onChangeText={(value) => {
                        const newFilters =
                          dataSourceFilters[fixedDataSourceID] || {};
                        newFilters[columnName] = { type: "search", value };
                        setDataSourceFilters((prevFilters) => ({
                          ...prevFilters,
                          [fixedDataSourceID]: newFilters
                        }));
                      }}
                      onSelect={(value) => {
                        const newFilters =
                          dataSourceFilters[fixedDataSourceID] || {};
                        newFilters[columnName] = { type: "exact", value };
                        setDataSourceFilters((prevFilters) => ({
                          ...prevFilters,
                          [fixedDataSourceID]: newFilters
                        }));
                      }}
                    />
                  </View>
                );
              });
            } else {
              componentsList.push(
                <Button
                  text="Show Filters"
                  icon="2824b907-c52b-42d5-a834-1b9d8cacbdc8"
                  onPress={() =>
                    setShowDataSourceFilters((prevFilters) => {
                      return {
                        ...prevFilters,
                        [fixedDataSourceID]: true
                      };
                    })
                  }
                  color="gray"
                  align="left"
                  small
                  outline
                />
              );
            }
          }
        } else if (data) {
          // Otherwise, this is the end of the data source section
          const fixedDataSourceID = `${dataSourceID}`;
          data.forEach((row, j) => {
            let shouldShowRow = true;
            if (dataSourceFilters[fixedDataSourceID]) {
              Object.keys(dataSourceFilters[fixedDataSourceID]).forEach(
                (columnName) => {
                  const { type, value } = dataSourceFilters[fixedDataSourceID][
                    columnName
                  ];
                  if (
                    type === "exact" &&
                    (!row[columnName] || row[columnName] !== value)
                  )
                    shouldShowRow = false;
                  else if (
                    type === "search" &&
                    (!row[columnName] ||
                      !row[columnName]
                        .toLowerCase()
                        .includes(value.toLowerCase()))
                  )
                    shouldShowRow = false;
                }
              );
            }
            if (shouldShowRow)
              dataSourceItemsToRepeat.forEach((itemToRepeat, k) => {
                componentsList.push(
                  renderComponent({
                    key: `${i}-${j}-${k}`,
                    index: i,
                    item: itemToRepeat,
                    dataSourceRow: row
                  })
                );
              });
          });
          data = null;
          dataSourceItemsToRepeat = [];
        }
      } else if (data) {
        // If we're currently building the list of components to repeat
        dataSourceItemsToRepeat.push(item);
      } else if (dataSourceRowOverride) {
        // If this is within an ExpandableList which is within a data source
        componentsList.push(
          renderComponent({
            key: i,
            index: i,
            item,
            dataSourceRow: dataSourceRowOverride
          })
        );
      } else {
        // Otherwise, this component is unrelated to any data source
        componentsList.push(renderComponent({ key: i, index: i, item }));
      }
    });
    return componentsList;
  };

  const RenderEditorModalContent = () => {
    const [isAddingNewItemButton, setIsAddingNewItemButton] = useState(false);
    const [isAddingNewItemImage, setIsAddingNewItemImage] = useState(false);
    const [isAddingNewItemVideo, setIsAddingNewItemVideo] = useState(false);
    const [isAddingNewItemAdvanced, setIsAddingNewItemAdvanced] = useState(
      false
    );
    let Buttons = null;
    if (isAddingNewItemButton)
      Buttons = (
        <>
          <Button
            text="Web Link"
            icon="web"
            onPress={() => addNewItem("button_web")}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="To Screen"
            icon="switchScreens"
            onPress={() => addNewItem("button_nav")}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="Call"
            icon="call"
            onPress={() => addNewItem("button_call")}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="Email"
            icon="email"
            onPress={() => addNewItem("button_email")}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="SMS"
            icon="sms"
            onPress={() => addNewItem("button_sms")}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="Instant Chat"
            icon="5e901870-aba7-43c7-b502-b65e65b93ea3" // message icon
            onPress={() =>
              addNewItem("button_chat", { recipient: Firebase.getUserID() })
            }
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          {Rex.getConfig()?.advancedComponentsEnabled && (
            <Button
              text="App Switcher"
              icon="bc9ba957-d232-4a5d-b7c3-1d578924d14a" // switching icon
              onPress={() =>
                addNewItem("button_app_switcher", { appID: "Testing" })
              }
              style={{ width: "30%", marginVertical: 4 }}
              small
              outline
            />
          )}
          <Button
            text="Share App"
            icon="8c9327df-9e13-4870-82e8-fff9f3564547"
            onPress={() => addNewItem("button_share_app")}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="PDF"
            icon="52ad79ad-58b4-4469-a676-3e00c9e02c3b" // PDF icon
            onPress={() => addNewItem("button_pdf")}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
        </>
      );
    else if (isAddingNewItemImage)
      Buttons = (
        <>
          {Platform.OS !== "web" && (
            <Button
              text="Take Photo"
              icon="camera"
              onPress={() => pickMedia("image", true)}
              style={{ width: "45%", marginVertical: 4 }}
              small
              outline
            />
          )}
          <Button
            text="Upload"
            icon="upload"
            onPress={() => pickMedia("image", false)}
            style={{ width: "45%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="From URL"
            icon="link"
            onPress={() => addNewItem("image")}
            style={{ width: "45%", marginVertical: 4 }}
            small
            outline
          />
        </>
      );
    else if (isAddingNewItemVideo)
      Buttons = (
        <>
          <Button
            text="YouTube"
            icon="c417ec72-56b1-41a3-a1c0-18c78a2f1118" // youtube
            onPress={() => addNewItem("video_youtube")}
            style={{ width: "45%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="Vimeo"
            icon="48d71590-509d-49d7-828e-ab8e70e9f795" // vimeo
            onPress={() => addNewItem("video_vimeo")}
            style={{ width: "45%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="Upload"
            icon="upload"
            onPress={() => pickMedia("video", false)}
            style={{ width: "45%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="File URL"
            icon="link"
            onPress={() => addNewItem("video")}
            style={{ width: "45%", marginVertical: 4 }}
            small
            outline
          />
        </>
      );
    else if (isAddingNewItemAdvanced)
      Buttons = (
        <>
          <Button
            text="Accordion List"
            icon="8e2b24d0-f975-4763-a25b-a0cc328d7192"
            onPress={() => addNewItem("expandable_list")}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="Link List"
            icon="link"
            onPress={() => addNewItem("link_list")}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="Data Feed"
            icon="table"
            onPress={() => {
              if (Platform.OS !== "web" || isMobile)
                setAlert({
                  title: "Heads up!",
                  message:
                    "Adding a data feed is way easier on your computer. Consider using the web version of Onespot for this."
                });
              addNewItems([
                "data_source_start",
                "data_source_example_text",
                "data_source_end"
              ]);
            }}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="Embed HTML"
            icon="code"
            onPress={() => {
              if (Platform.OS !== "web" || isMobile)
                setAlert({
                  title: "Heads up!",
                  message:
                    "Embedding HTML is way easier on your computer. Consider using the web version of Onespot for this."
                });
              addNewItem("html");
            }}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="Help me!"
            onPress={() => {
              navigation.push("help");
            }}
            style={{
              width: "95%"
            }}
          />
        </>
      );
    else
      Buttons = (
        <>
          {isForm && (
            <Button
              text="Question"
              icon="4ea8097f-2260-4795-b561-b926708037c0" // comment-question-outline
              onPress={() => addNewItem("form_question")}
              style={{ width: "90%", marginVertical: 4 }}
              small
              outline
            />
          )}
          <Button
            text="Text"
            icon="98ef907f-8c0c-4bde-8d5a-79af707c2e2c"
            onPress={() => addNewItem("text")}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="Button"
            icon="tapButton"
            onPress={() => setIsAddingNewItemButton(true)}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="Image"
            icon="photos"
            onPress={() => setIsAddingNewItemImage(true)}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="Video"
            icon="786cc159-b66e-4520-9914-3ca9330945ac" // video
            onPress={() => setIsAddingNewItemVideo(true)}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="Line"
            icon="horizontalLine"
            onPress={() => addNewItem("horizontal_line")}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="Space"
            icon="b6fa6b98-f216-4827-99fa-acfa595c9c2a"
            onPress={() => addNewItem("blank_space")}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          <Button
            text="PDF"
            icon="52ad79ad-58b4-4469-a676-3e00c9e02c3b" // PDF icon
            onPress={() => addNewItem("button_pdf")}
            style={{ width: "30%", marginVertical: 4 }}
            small
            outline
          />
          {productDropdownItems?.length > 0 && (
            <Button
              text="Product"
              icon="money"
              onPress={() => addNewItem("commerce_product")}
              style={{ width: "30%", marginVertical: 4 }}
              small
              outline
            />
          )}
          {Rex.getConfig()?.advancedComponentsEnabled && (
            <Button
              text="Advanced"
              icon="lightbulb"
              onPress={() => setIsAddingNewItemAdvanced(true)}
              style={{ width: "30%", marginVertical: 4 }}
              small
              outline
            />
          )}
          {Database.userIsSuperAdmin() && (
            <View
              style={{ alignItems: "center", marginTop: 30, width: "100%" }}
            >
              <Text>Only visible to super admins:</Text>
              <Button
                text="😎 Hacker Mode"
                small
                onPress={() => {
                  setIsInHackerMode(true);
                  setHackerModeCode(JSON.stringify(content));
                }}
                textStyle={{ color: "#03fc0f" }}
                style={{ backgroundColor: "black" }}
              />
            </View>
          )}
        </>
      );
    // const containerStyle = {
    //   marginVertical: 20,
    //   backgroundColor: "white",
    //   paddingTop: 20,
    //   shadowOpacity: 0.15,
    //   shadowOffset: { width: 0, height: -5 },
    //   shadowRadius: 3,
    //   width: "100%"
    // };
    // if (Platform.OS === "web")
    //   containerStyle.boxShadow = "0px -5px 3px rgba(0,0,0,0.15)";
    let headerText = "What would you like to add?";
    if (isAddingNewItemButton) headerText = "What type of button?";
    else if (isAddingNewItemImage) headerText = "Add an image:";
    else if (isAddingNewItemVideo) headerText = "Add a video:";
    else if (isAddingNewItemAdvanced)
      headerText =
        "Heads up! These features are somewhat complicated. Please reach out if you want help.";
    const allContent = (
      <>
        <Text
          style={{
            width: fullWidth,
            textAlign: "center",
            color: "#1A1A1A"
          }}
        >
          {headerText}
        </Text>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            paddingTop: 20
          }}
        >
          {Buttons}
        </View>
      </>
    );
    if (Platform.OS === "android")
      return (
        <ScrollView scrollIndicatorInsets={{ right: 1 }}>
          {allContent}
        </ScrollView>
      );
    return <View>{allContent}</View>;
  };

  const renderComponent = ({
    key,
    index,
    item,
    editable = false,
    drag,
    isActive,
    dataSourceRow
  }) => {
    const { onUpdatePortal } = route?.params || {};
    let component = null;
    if (["text", "text_header"].includes(item.type)) {
      component = (
        <TextItem
          key={key}
          item={item}
          editable={editable}
          isEditingAnItem={isEditingAnItem || isAddingNewItem}
          setIsEditingAnItem={setIsEditingAnItem}
          setKeyboardToolbarComponents={setKeyboardToolbarComponents}
          navigate={(navName, newProps = {}) =>
            navigation.push(navName, { ...props, ...newProps })
          }
          onChangeItem={(newItem) => {
            const newContent = content.map((item2) =>
              item2.key === key ? newItem : item2
            );
            setContent(newContent);
          }}
          dataSourceRow={dataSourceRow}
          backgroundColor={backgroundColor}
          user={user}
        />
      );
    } else if (
      [
        "button_call",
        "button_email",
        "button_nav",
        "button_sms",
        "button_web",
        "button_pdf",
        "button_chat",
        "button_app_switcher",
        "button_share_app"
      ].includes(item.type)
    ) {
      component = (
        <ButtonItem
          key={key}
          item={item}
          editable={editable}
          navigation={navigation}
          isEditingAnItem={isEditingAnItem || isAddingNewItem}
          setIsEditingAnItem={setIsEditingAnItem}
          // onUpdatePortal is only used if an admin navigates to another portal (button_nav) and then edits that portal
          navigate={(navName, newProps = {}) =>
            navigation.push(navName, { ...props, ...newProps, onUpdatePortal })
          }
          onChangeItem={(newItem) => {
            const newContent = content.map((item2) =>
              item2.key === key ? newItem : item2
            );
            setContent(newContent);
          }}
          dataSourceRow={dataSourceRow}
          userName={`${user?.firstName} ${user?.lastName}`}
          user={user}
        />
      );
    } else if (item.type === "image") {
      component = (
        <MediaItem
          key={key}
          item={item}
          editable={editable}
          navigation={navigation}
          isEditingAnItem={isEditingAnItem || isAddingNewItem}
          setIsEditingAnItem={setIsEditingAnItem}
          onChangeItem={(newItem) => {
            const newContent = content.map((item2) =>
              item2.key === key ? newItem : item2
            );
            setContent(newContent);
          }}
          dataSourceRow={dataSourceRow}
          user={user}
        />
      );
    } else if (item.type === "video") {
      component = (
        <MediaItem
          key={key}
          item={item}
          editable={editable}
          navigation={navigation}
          isEditingAnItem={isEditingAnItem || isAddingNewItem}
          setIsEditingAnItem={setIsEditingAnItem}
          onChangeItem={(newItem) => {
            const newContent = content.map((item2) =>
              item2.key === key ? newItem : item2
            );
            setContent(newContent);
          }}
          dataSourceRow={dataSourceRow}
          user={user}
        />
      );
    } else if (item.type === "commerce_product") {
      component = (
        <ProductItem
          key={key}
          item={item}
          editable={editable}
          navigation={navigation}
          isEditingAnItem={isEditingAnItem || isAddingNewItem}
          setIsEditingAnItem={setIsEditingAnItem}
          onChangeItem={(newItem) => {
            const newContent = content.map((item2) =>
              item2.key === key ? newItem : item2
            );
            setContent(newContent);
          }}
          dataSourceRow={dataSourceRow}
          onStartCheckoutSession={onStartCheckoutSession}
          productDropdownItems={productDropdownItems}
          allProducts={commerceProducts}
        />
      );
    } else if (item.type === "horizontal_line") {
      component = (
        <View
          style={{
            height: 60,
            width: "90%",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center"
          }}
        >
          <View
            style={{
              height: 2,
              width: "100%",
              backgroundColor: "lightgray"
            }}
          />
        </View>
      );
    } else if (item.type === "blank_space") {
      component = (
        <View
          style={{
            height: 100,
            width: "90%",
            alignSelf: "center"
          }}
        />
      );
    } else if (item.type === "link_list") {
      component = (
        <CustomList
          key={key}
          item={item}
          editable={editable}
          navigation={navigation}
          isEditingAnItem={isEditingAnItem || isAddingNewItem}
          setIsEditingAnItem={setIsEditingAnItem}
          navigate={(navName, newProps = {}) =>
            navigation.push(navName, { ...props, ...newProps })
          }
          onChangeItem={(newItem) => {
            const newContent = content.map((item2) =>
              item2.key === key ? newItem : item2
            );
            setContent(newContent);
          }}
          dataSourceRow={dataSourceRow}
          user={user}
        />
      );
    } else if (item.type === "expandable_list") {
      component = (
        <ExpandableList
          key={key}
          item={item}
          editable={editable}
          isEditingAnItem={isEditingAnItem || isAddingNewItem}
          setIsEditingAnItem={setIsEditingAnItem}
          generateContentComponentsList={generateContentComponentsList}
          onChangeItem={(newItem) => {
            const newContent = content.map((item2) =>
              item2.key === key ? newItem : item2
            );
            setContent(newContent);
          }}
          setKeyboardToolbarComponents={setKeyboardToolbarComponents}
          dataSourceRow={dataSourceRow}
        />
      );
    } else if (item.type === "data_source" && isEditing) {
      let dataForThisItem = null;
      if (item?.isStart && item?.googleSheetID && item?.googleSheetName) {
        const dataSourceID = calculateDataSourceID(
          item.googleSheetID,
          item.googleSheetName
        );
        dataForThisItem = dataSourceData[dataSourceID];
      }
      component = (
        <DataSourceItem
          key={key}
          item={item}
          isEditingAnItem={isEditingAnItem || isAddingNewItem}
          setIsEditingAnItem={setIsEditingAnItem}
          onChangeItem={(newItem) => {
            const newContent = content.map((item2) =>
              item2.key === key ? newItem : item2
            );
            setContent(newContent);
          }}
          data={dataForThisItem}
          navigation={navigation}
          reformatContent={reformatContent}
        />
      );
    } else if (item.type === "html") {
      component = (
        <HTMLItem
          key={key}
          item={item}
          editable={editable}
          isEditingAnItem={isEditingAnItem || isAddingNewItem}
          setIsEditingAnItem={setIsEditingAnItem}
          onChangeItem={(newItem) => {
            const newContent = content.map((item2) =>
              item2.key === key ? newItem : item2
            );
            setContent(newContent);
          }}
          navigation={navigation}
          dataSourceRow={dataSourceRow}
          user={user}
        />
      );
    } else if (item.type === "form_question") {
      component = (
        <View style={{ marginHorizontal: 20 }}>
          <Question
            key={key}
            question={item}
            index={index}
            value={formAnswers[item.id] || ""}
            onChangeValue={(newValue) => {
              setFormAnswers({
                ...formAnswers,
                [item.id]: newValue
              });
            }}
            user={user}
            formPortalID={portalName}
            questions={content}
            onChangeQuestions={setContent}
            isEditing={editable}
          />
        </View>
      );
    }
    if (editable)
      return (
        <EditableItemWrapper
          index={index}
          component={component}
          onSetHeight={(i, h) => setItemIndexAndHeight(key, i, h)}
          isIndexToAddItem={isAddingNewItem && index === indexToAddItem}
          drag={drag}
          add={() => addItem(key)}
          duplicate={() => duplicateItem(key)}
          remove={() => deleteItem(key)}
          isActive={isActive}
          isEditingAnItem={isEditingAnItem || isAddingNewItem}
        />
      );
    return component;
  };

  const addNewItem = (type, extraContent = {}) => {
    Analytics.logEvent("touch_dynamic_addNewItem", { type });
    if (type in CONTENT_ITEM_TEMPLATES) {
      const newItem = {
        ...CONTENT_ITEM_TEMPLATES[type],
        ...extraContent,
        justCreated: true,
        key: `id-${newItemKeyNumber}`,
        ...(type === "form_question" && {
          id: Util.generateRandomCode(10)
        })
      };
      setContent(content.insert(indexToAddItem + 1, newItem));
      setIsAddingNewItem(false);
      setNewItemKeyNumber(newItemKeyNumber + 1);
    }
  };

  const addNewItems = (listOfTypes) => {
    const newItems = [];
    listOfTypes.forEach((type, idx) => {
      if (type in CONTENT_ITEM_TEMPLATES) {
        const newItem = {
          ...CONTENT_ITEM_TEMPLATES[type],
          justCreated: true,
          key: `id-${newItemKeyNumber + idx}`
        };
        newItems.push(newItem);
      }
    });
    setContent([...content, ...newItems]);
    setIsAddingNewItem(false);
    setNewItemKeyNumber(newItemKeyNumber + listOfTypes.length);
  };

  const deleteItem = (key) => {
    Analytics.logEvent("touch_dynamic_deleteItem");
    if (Platform.OS !== "web") Haptics.selectionAsync();
    const newContent = content.filter((item) => item.key !== key);
    setContent(newContent);
  };

  const duplicateItem = (key) => {
    Analytics.logEvent("touch_dynamic_duplicateItem");
    if (Platform.OS !== "web") Haptics.selectionAsync();
    const indexOfItem = content.findIndex((item) => item.key === key);
    const newKey = `${content[indexOfItem]?.key}-${Math.random()}`;
    const copyOfItem = { ...content[indexOfItem], key: newKey };
    const newContent = content.insert(indexOfItem + 1, copyOfItem);
    setContent(newContent);
  };

  const addItem = (key) => {
    Analytics.logEvent("touch_dynamic_addItem");
    if (Platform.OS !== "web") Haptics.selectionAsync();
    const indexOfItem = content.findIndex((item) => item.key === key);
    setIndexToAddItem(indexOfItem);
    setIsAddingNewItem(true);
  };

  const pickMedia = (type = "image", takeNew = false) => {
    Analytics.logEvent("touch_dynamic_uploadMedia", { type, takeNew });
    Util.pickMedia({ type, takeNew })
      .then((uri) => {
        if (uri) {
          Analytics.logEvent("action_dynamic_uploadedMedia", {
            type,
            takeNew,
            uri
          });
          addNewItem(type, { [type]: uri, mediaToUpload: uri });
        }
      })
      .catch(() => {
        setAlert({ title: "Enable camera permissions to continue" });
      });
  };

  const openSettings = () => {
    Analytics.logEvent("touch_dynamic_settingsButton", {
      key: portalName
    });
    const {
      navName,
      icon,
      portalType,
      txtName,
      allowedAccountTypes,
      onUpdatePortal
    } = route?.params || {};
    navigation.goBack();
    navigation.push("editPortal", {
      portalMetadata: {
        navName,
        icon,
        portalType,
        txtName,
        allowedAccountTypes
      },
      onUpdatePortal
    });
  };

  const doneEditing = (saveChanges, editSettingsAfterSaving = false) => {
    if (saveChanges) {
      saveContentToDatabase().then(() => {
        if (Platform.OS !== "web")
          Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
        setOriginalContent(content);
        if (editSettingsAfterSaving) {
          const {
            navName,
            icon,
            portalType,
            txtName,
            allowedAccountTypes,
            onUpdatePortal
          } = route?.params || {};
          navigation.goBack();
          navigation.push("editPortal", {
            portalMetadata: {
              navName,
              icon,
              portalType,
              txtName,
              allowedAccountTypes
            },
            onUpdatePortal
          });
        }
      });
    } else {
      setContent(originalContent);
    }
    Database.addTask("editPortal", "done");
    setIsEditing(false);
    setIsAddingNewItem(false);
    setIsEditingAnItem(false);
    setIsInHackerMode(false);
    setIsPreviewing(false);
    setHackerModeCode(null);
  };

  const saveContentToDatabase = () => {
    const { confirmationMessage, googleFormID, promptToSubmitAnotherResponse } =
      formContent || {};
    const newPortal = Util.cleanPortalContent({
      content,
      title,
      backgroundColor: backgroundColor || "#ffffff",
      ...(confirmationMessage ? { confirmationMessage } : {}),
      ...(googleFormID ? { googleFormID } : {}),
      ...(promptToSubmitAnotherResponse
        ? { promptToSubmitAnotherResponse }
        : {})
    });
    return Database.updatePortal(null, newPortal, portalName); // todo: remove the "key" key-val
  };

  const pressSettingsButton = () => {
    setAlert({
      title: "Edit Full Settings?",
      message:
        "This will save & publish any changes you made so far and will let you edit the screen's overall settings.",
      confirm: {
        text: "💾 Save & Edit",
        onPress: () => {
          Analytics.logEvent("touch_dynamic_editPortal", {
            key: portalName,
            action: "save"
          });
          doneEditing(true, true);
        }
      },
      cancel: {
        text: "✋ Nevermind",
        onPress: () => {
          Analytics.logEvent("touch_dynamic_editPortal", {
            key: portalName,
            action: "cancel"
          });
        }
      }
    });
  };

  const pressEditContentButton = () => {
    if (portalOverride) {
      portalOverride.updateContent({ content, title, backgroundColor });
      navigation.goBack();
    } else if (!isEditing) {
      setAlert({
        title: "Edit Content?",
        message: `Because you're an app manager, you have permission to edit this portal's content.

Once you're done editing, press the check mark.`,
        confirm: {
          text: "✍️ Edit",
          onPress: () => {
            Analytics.logEvent("touch_dynamic_editContent", {
              key: portalName,
              action: "edit"
            });
            setIsEditing(true);
          }
        },
        cancel: {
          text: "✋ Cancel",
          color: "gray",
          onPress: () => {
            Analytics.logEvent("touch_dynamic_editContent", {
              key: portalName,
              action: "cancel"
            });
          }
        }
      });
    } else {
      setAlert({
        title: "Save Changes?",
        message: "Want to publish the changes you made in this portal?",
        confirm: {
          text: "💾 Save",
          onPress: () => {
            Analytics.logEvent("touch_dynamic_saveContent", {
              key: portalName,
              action: "save"
            });
            doneEditing(true);
          }
        },
        cancel: {
          text: "💥 Discard",
          onPress: () => {
            Analytics.logEvent("touch_dynamic_saveContent", {
              key: portalName,
              action: "discard"
            });
            doneEditing(false);
          }
        }
      });
    }
  };

  const undoLastAIAction = async () => {
    Analytics.logEvent("touch_dynamic_undoLastAIAction");
    setLoadingAIGeneration(true);
    if (lastBotMessageIndex < 0) {
      setLoadingAIGeneration(false);
      return;
    }
    const lastBotMessage = openAIMessages[lastBotMessageIndex];
    const newMessages = openAIMessages.slice(0, lastBotMessageIndex);
    const parsedJSON = JSON.parse(lastBotMessage.content || "[]");
    const formattedJSON = (parsedJSON || [])
      .filter((item) => item.type in CONTENT_ITEM_TEMPLATES)
      .map((item) => ({
        ...item,
        key: `id-${Util.randomInt(9999999999)}`
      }));
    setContent(formattedJSON);
    setOpenAIMessages(newMessages);
    setLoadingAIGeneration(false);
  };

  const generateScreenFromAIPrompt = async () => {
    Analytics.logEvent("touch_dynamic_generateScreenFromAIPrompt", {
      prompt: openAIPrompt
    });
    setLoadingAIGeneration(true);
    let newMessages = [];
    if (content.length > 0) {
      newMessages = [
        ...openAIMessages,
        // todo: remove the key from here first
        { role: "assistant", content: JSON.stringify(content) },
        { role: "user", content: openAIPrompt }
      ];
    } else {
      newMessages = [
        ...openAIMessages,
        {
          role: "user",
          content: `Here’s the screen I would like you to create:${"\n"}${openAIPrompt}`
        }
      ];
    }
    setOpenAIMessages(newMessages);
    console.log("messages:");
    console.log(newMessages);
    const output = await Database.generateScreenWithAI(newMessages);
    console.log("output:");
    console.log(output);
    Analytics.logEvent("action_dynamic_generateScreenWithAI", {
      prompt: openAIPrompt,
      messages: newMessages,
      response: output
    });

    // Note: Please replace the URL in the \`button_web\` item with the actual website address for your bird watching startup.`
    setLoadingAIGeneration(false);

    const openingBracketIndex = output.indexOf("[");
    const closingBracketIndex = output.lastIndexOf("]");
    if (openingBracketIndex < 0 || closingBracketIndex < 0) {
      Analytics.logEvent("error_dynamic_generateScreenWithAI", {
        error:
          "Response was badly formatted; missing an opening/closing bracket."
      });
      console.log(
        "Error: Response was badly formatted; missing an opening/closing bracket."
      );
      setLastAIGenerationFailed(true);
      return;
    }
    const outputParts = [
      output.substring(0, openingBracketIndex),
      output.substring(openingBracketIndex, closingBracketIndex + 1),
      output.substring(closingBracketIndex + 1)
    ];
    const structuredMessage = outputParts[1];

    try {
      const parsedJSON = JSON.parse(structuredMessage);
      try {
        // todo: maybe increment the key instead of making it random
        const formattedJSON = (parsedJSON || [])
          .filter((item) => item.type in CONTENT_ITEM_TEMPLATES)
          .map((item) => ({
            ...item,
            key: `id-${Util.randomInt(9999999999)}`
          }));
        setContent(formattedJSON);
        Analytics.logEvent("action_dynamic_generateScreenWithAISucceeded", {
          content: formattedJSON
        });
      } catch (error) {
        Analytics.logEvent("error_dynamic_generateScreenWithAI", {
          error: "Error setting content."
        });
        console.log("Error setting content");
        console.log(error);
        setLastAIGenerationFailed(true);
      }
    } catch (error) {
      Analytics.logEvent("error_dynamic_generateScreenWithAI", {
        error: "Error parsing JSON."
      });
      console.log("Error parsing JSON:");
      console.log(error);
      setLastAIGenerationFailed(true);
    }
  };

  // Calculate the index of whatever item is nearest to the current scrolled y position
  const calculateIndexToAddItem = () => {
    const formattedItems = content
      .filter((item) => item.key in keyToIndexAndHeight)
      .map(({ key }) => {
        const { itemHeight, itemIndex } = keyToIndexAndHeight[key];
        return { key, itemHeight, itemIndex };
      })
      .sort((a, b) => a.itemIndex - b.itemIndex);

    const y = scrollY + POSITION_TO_ADD_ITEM;
    const lastIndex = Math.max(content.length - 1, 0);
    let lastKey = null;
    let nearestIndex = lastIndex;
    let totalHeight = 0;
    let smallestDistanceToY = Infinity;

    formattedItems.forEach((itemDetails) => {
      const { key, itemHeight, itemIndex } = itemDetails;
      if (itemIndex === lastIndex) lastKey = key;
      const distanceToY = Math.abs(y - totalHeight);
      if (distanceToY < smallestDistanceToY) {
        smallestDistanceToY = distanceToY;
        nearestIndex = Math.max(itemIndex - 1, 0);
      }
      totalHeight += itemHeight;
    });

    // If we're pointing at the last item
    if (nearestIndex === lastIndex - 1) {
      // And we're pointing at below the vertical midpoint of that item
      const midpoint =
        totalHeight - keyToIndexAndHeight[lastKey]?.itemHeight / 2;
      if (y > midpoint) {
        nearestIndex = lastIndex;
      }
    }
    if (Platform.OS !== "web" && nearestIndex !== indexToAddItem)
      Haptics.selectionAsync();
    setIndexToAddItem(nearestIndex);
  };

  const handleScroll = (event) => {
    setScrollY(event.nativeEvent.contentOffset.y);
    setIsScrolling(false);
  };

  let LeftButton = null;
  let RightButton = null;
  let RightButtonSecondary = null;
  if (canEdit) {
    if (!portalOverride && isEditing)
      LeftButton = (
        <TouchableOpacity
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "flex-start",
            paddingLeft: 20
          }}
          activeOpacity={0.6}
          onPress={pressSettingsButton}
        >
          <MaterialIcons
            name="settings"
            size={Glob.deviceHasTabletDimensions() ? 42 : 26}
            color="white"
          />
        </TouchableOpacity>
      );
    RightButton = (
      <TouchableOpacity
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "flex-end",
          paddingRight: 20
        }}
        activeOpacity={0.6}
        onPress={pressEditContentButton}
      >
        {isEditing ? (
          <Entypo
            name="check"
            size={Glob.deviceHasTabletDimensions() ? 42 : 26}
            color="white"
          />
        ) : (
          <MaterialCommunityIcons
            name="lightning-bolt"
            size={Glob.deviceHasTabletDimensions() ? 42 : 26}
            color="white"
          />
        )}
      </TouchableOpacity>
    );
    if (!portalOverride && !isEditing)
      RightButtonSecondary = (
        <TouchableOpacity
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "flex-end"
          }}
          activeOpacity={0.6}
          onPress={openSettings}
        >
          <MaterialIcons
            name="settings"
            size={Glob.deviceHasTabletDimensions() ? 42 : 26}
            color="white"
          />
        </TouchableOpacity>
      );
    if (isEditing && !isEditingAnItem)
      RightButtonSecondary = (
        <TouchableOpacity
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "flex-end"
          }}
          activeOpacity={0.6}
          onPress={() => {
            Analytics.logEvent(
              `touch_dynamic_preview${isPreviewing ? "Stop" : "Start"}`
            );
            setIsPreviewing(!isPreviewing);
          }}
        >
          <Feather
            name={`eye${isPreviewing ? "-off" : ""}`}
            size={Glob.deviceHasTabletDimensions() ? 42 : 26}
            color="white"
          />
        </TouchableOpacity>
      );
  }

  if (isInHackerMode) {
    return (
      <KeyboardAvoidingView
        style={[styles.pageContent, { backgroundColor: "black" }]}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <NavBar
          navigation={navigation}
          text={portalOverride?.title || title}
          cannotNavigateBack={isEditing}
          RightButton={RightButton}
        />
        <ScrollView
          contentContainerStyle={{
            width: fullWidth,
            alignItems: "center",
            height: "100%"
          }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          <View style={{ width, padding: 10, height: "100%" }}>
            <Text style={{ color: "#03fc0f" }}>
              You've entered hacker mode.
            </Text>
            <Text style={{ color: "#03fc0f", marginBottom: 10 }}>
              With great power comes great responsibility 🦸
            </Text>
            <Button
              text="🧹 Format Code"
              onPress={() => {
                setContent(JSON.parse(hackerModeCode));
              }}
              textStyle={{ color: "#03fc0f" }}
              style={{ backgroundColor: "#222" }}
            />
            <Button
              text="🚀 Deploy Code"
              onPress={pressEditContentButton}
              textStyle={{ color: "#03fc0f" }}
              style={{ backgroundColor: "#222", marginBottom: 30 }}
            />
            <TextInput
              style={{
                color: "white",
                fontFamily: "Courier New",
                height: "100%",
                padding: 10,
                backgroundColor: "#111"
              }}
              value={hackerModeCode}
              onChangeText={(text) => setHackerModeCode(text)}
              multiline
            />
          </View>
        </ScrollView>
        <AlertModal alert={alert} setAlert={setAlert} />
      </KeyboardAvoidingView>
    );
  }

  return (
    <KeyboardAvoidingView
      style={[
        styles.pageContent,
        {
          backgroundColor:
            portalOverride?.backgroundColor || backgroundColor || "white"
        }
      ]}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <WebPageMetaTitle title={portalOverride?.title || title} />
      <NavBar
        navigation={navigation}
        text={portalOverride?.title || title}
        cannotNavigateBack={isEditing}
        LeftButton={LeftButton}
        RightButton={RightButton}
        RightButtonSecondary={RightButtonSecondary}
      />
      {/* todo: move the outer stuff to Dynamic and just keep the inner stuff */}
      {!loadedContent && (
        <ActivityIndicator size="large" style={{ marginTop: 20 }} />
      )}
      {isEditing && !isPreviewing ? (
        <>
          {isShowingAIEditor && !Rex.getConfig()?.aiDisabled && (
            <View
              style={[
                {
                  width,
                  paddingHorizontal: 20,
                  paddingBottom: 20,
                  backgroundColor: "#e1e1fc"
                },
                Platform.OS === "web"
                  ? { boxShadow: "0px 5px 3px rgba(0,0,0,0.15)" }
                  : {
                      shadowOpacity: 0.15,
                      shadowOffset: { width: 0, height: 5 },
                      shadowRadius: 3
                    }
              ]}
            >
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "center"
                  }}
                >
                  <Text
                    style={{
                      marginTop: 10,
                      fontSize: 26,
                      fontWeight: "bold",
                      color: Glob.get("onespotColor")
                    }}
                  >
                    Onespot AI
                  </Text>
                  <Text
                    style={{
                      marginLeft: 2,
                      marginTop: 2,
                      fontSize: 20,
                      color: Glob.get("onespotColor")
                    }}
                  >
                    Beta
                  </Text>
                </View>
                <Text style={{ color: "#666", fontSize: 11 }}>
                  Note: This is a new, experimental feature, so it can make
                  mistakes. Consider checking important information.
                </Text>
              </View>
              <InputBox
                placeholder={
                  content.length > 0
                    ? "Describe the changes to make"
                    : "Describe your screen"
                }
                value={openAIPrompt}
                multiline
                onChangeText={(newPrompt) => {
                  setLastAIGenerationFailed(false);
                  setOpenAIPrompt(newPrompt);
                }}
              />
              {loadingAIGeneration ? (
                <ActivityIndicator size="large" style={{ marginTop: 20 }} />
              ) : (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  <Button
                    text={content.length > 0 ? "Make changes" : "Make screen"}
                    icon="robot-happy-outline" // other robot: "3be93bd2-2dca-473a-8cd9-fe85e7414c5e"
                    onPress={generateScreenFromAIPrompt}
                    color={Glob.get("onespotColor")}
                    flat
                    small
                    disabled={!openAIPrompt}
                    style={{ opacity: openAIPrompt ? 1 : 0.3 }}
                  />
                  {lastBotMessageIndex > -1 && (
                    <Button
                      text="Undo"
                      icon="41a527a9-db4d-4bfa-bd4d-b918f8fdb800"
                      onPress={undoLastAIAction}
                      color="white"
                      flat
                      small
                    />
                  )}
                </View>
              )}
              {lastAIGenerationFailed && (
                <Text style={{ color: "#666" }}>
                  Oops! Your updates couldn't be made. 😕{"\n"}Try rephrasing
                  your prompt.
                </Text>
              )}
            </View>
          )}
          <DraggableList
            ref={draggableListRef}
            onMomentumScrollBegin={() => setIsScrolling(true)}
            onScrollBeginDrag={() => setIsScrolling(true)}
            onScrollEndDrag={handleScroll}
            onMomentumScrollEnd={handleScroll}
            scrollEventThrottle={1000}
            contentContainerStyle={{
              width,
              paddingBottom: height * 0.5,
              flexGrow: 1
            }}
            removeClippedSubviews={false}
            keyboardDismissMode="on-drag"
            keyboardShouldPersistTaps="always"
            showsVerticalScrollIndicator={false}
            data={content}
            onDragBegin={() => {
              if (Platform.OS !== "web") Haptics.selectionAsync();
            }}
            onDragEnd={({ data }) => {
              if (Platform.OS !== "web") Haptics.selectionAsync();
              setContent(data);
            }}
            keyExtractor={(item) => `draggable-item-${item.key}`}
            autoscrollSpeed={120}
            renderItem={({
              item,
              drag,
              isActive,
              index,
              getIndex = () => {}
            }) => {
              const cleanIndex = index ?? getIndex();
              return renderComponent({
                key: item.key,
                index: cleanIndex,
                item,
                editable: true,
                drag,
                isActive
              });
            }}
          />
          <KeyboardToolbar components={keyboardToolbarComponents} />
          {isAddingNewItem && (
            <>
              <View
                style={{
                  position: Platform.OS === "web" ? "fixed" : "absolute",
                  left: 3 + screenMarginWidth,
                  opacity: Platform.OS === "web" || isScrolling ? 1 : 0,
                  top: POSITION_TO_ADD_ITEM - 12 + Glob.get("navBarHeight")
                }}
              >
                <AntDesign name="caretright" size={24} color="#4338ca" />
              </View>
              <View
                style={{
                  position: Platform.OS === "web" ? "fixed" : "absolute",
                  right: 3 + screenMarginWidth,
                  opacity: Platform.OS === "web" || isScrolling ? 1 : 0,
                  top: POSITION_TO_ADD_ITEM - 12 + Glob.get("navBarHeight")
                }}
              >
                <AntDesign name="caretleft" size={24} color="#4338ca" />
              </View>
              <BottomSheetModal
                onClose={() => {
                  setIsAddingNewItem(false);
                }}
                content={<RenderEditorModalContent />}
              />
            </>
          )}
          {!isAddingNewItem && !isEditingAnItem && !loadingAIGeneration && (
            <FloatingActionButton
              icon={
                <Entypo
                  name="plus"
                  size={40}
                  color={Glob.get("onespotColor")}
                />
              }
              onPress={() => {
                Analytics.logEvent("touch_dynamic_addItemFloatingActionButton");
                setIsAddingNewItem(true);
                calculateIndexToAddItem();
              }}
              style={{ backgroundColor: "white", left: 30 + screenMarginWidth }}
            />
          )}
          {!isAddingNewItem &&
            !isEditingAnItem &&
            !loadingAIGeneration &&
            !isForm &&
            !Rex.getConfig()?.aiDisabled && (
              <FloatingActionButton
                icon={
                  <MaterialCommunityIcons
                    name={`robot-${
                      isShowingAIEditor ? "off" : "happy"
                    }-outline`}
                    size={26}
                    color={Glob.get("onespotColor")}
                  />
                }
                onPress={() => {
                  Analytics.logEvent(
                    "touch_dynamic_onespotAIFloatingActionButton"
                  );
                  setIsShowingAIEditor(!isShowingAIEditor);
                }}
                style={{
                  backgroundColor: "white",
                  width: 50,
                  height: 50,
                  left: 30 + screenMarginWidth + 90
                }}
              />
            )}
          {!isAddingNewItem && !isEditingAnItem && (
            <FloatingActionButton
              icon={
                <FontAwesome5 name="question-circle" size={26} color="white" />
              }
              onPress={() => {
                Analytics.logEvent("touch_dynamic_helpFloatingActionButton");
                setIsShowingHelpPage(true);
              }}
              style={{
                width: 50,
                height: 50,
                backgroundColor: Glob.get("onespotColor"),
                right: 30 + screenMarginWidth
              }}
            />
          )}
        </>
      ) : (
        <>
          {isPreviewing && (
            <StatusMessage
              type="info"
              message="This is a preview of your changes. Press the checkmark above to save or discard."
            />
          )}
          <ScrollView
            contentContainerStyle={{
              width: fullWidth,
              alignItems: "center",
              paddingBottom: 20
            }}
            scrollIndicatorInsets={{ right: 1 }}
          >
            <View style={{ width }}>
              {generateContentComponentsList(content)}
              {!!loadedContent && (FormSubmitButton || null)}
            </View>
          </ScrollView>
        </>
      )}
      {isShowingHelpPage && (
        <BottomSheetModal
          onClose={() => setIsShowingHelpPage(false)}
          url={HELP_PAGE_URL}
        />
      )}
      {!!commerceCheckoutSessionURL && (
        <BottomSheetModal
          onClose={() => {
            setCommerceCheckoutSessionURL(null);
          }}
          url={commerceCheckoutSessionURL}
        />
      )}
      <AlertModal alert={alert} setAlert={setAlert} />
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  /* Style for the enter page */
  pageContent: {
    height: "100%",
    backgroundColor: "white",
    alignItems: "center",
    width: "100%"
  }
});
