import React from "react";
import { Image } from "react-native";
import {
  AntDesign,
  Entypo,
  EvilIcons,
  Feather,
  FontAwesome,
  FontAwesome5,
  Fontisto,
  Foundation,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
  SimpleLineIcons,
  Zocial
} from "@expo/vector-icons";
import IconCollection from "resources/icons/Icons";

const allIcons = {};
IconCollection.forEach((icon) => {
  allIcons[icon.uuid] = icon;
});

const getIconFromCollection = (iconSet, name, props) => {
  switch (iconSet) {
    case "AntDesign":
      return <AntDesign name={name} {...props} />;
    case "Entypo":
      return <Entypo name={name} {...props} />;
    case "EvilIcons":
      return <EvilIcons name={name} {...props} />;
    case "Feather":
      return <Feather name={name} {...props} />;
    case "FontAwesome":
      return <FontAwesome name={name} {...props} />;
    case "FontAwesome5":
      return <FontAwesome5 name={name} {...props} />;
    case "Fontisto":
      return <Fontisto name={name} {...props} />;
    case "Foundation":
      return <Foundation name={name} {...props} />;
    case "Ionicons":
      return <Ionicons name={name} {...props} />;
    case "MaterialCommunityIcons":
      return <MaterialCommunityIcons name={name} {...props} />;
    case "MaterialIcons":
      return <MaterialIcons name={name} {...props} />;
    case "Octicons":
      return <Octicons name={name} {...props} />;
    case "SimpleLineIcons":
      return <SimpleLineIcons name={name} {...props} />;
    case "Zocial":
      return <Zocial name={name} {...props} />;
    default:
      return null;
  }
};

export default function Icon({
  icon,
  iconURL,
  set,
  name,
  color = "white",
  size = 54,
  style = {}
}) {
  let safeSize = size;
  // In case we accidentally pass a string, this saves it from crashing on Android
  if (typeof size === "string") safeSize = parseInt(size, 10);
  if (iconURL) {
    return (
      <Image
        source={{ uri: iconURL }}
        style={[{ width: 1.4 * safeSize, height: 1.4 * safeSize }, style]}
        resizeMode="contain"
      />
    );
  }
  if (icon && allIcons[icon]) {
    const iconObject = allIcons[icon];
    if (iconObject.image)
      return (
        <Image
          source={iconObject.image}
          style={[
            {
              width: 1.4 * safeSize,
              height: 1.4 * safeSize,
              tintColor: iconObject.doNotTint ? undefined : color
            },
            style
          ]}
        />
      );
    return getIconFromCollection(iconObject.iconSet, iconObject.iconName, {
      color,
      size: safeSize,
      style
    });
  }
  if (set && name) {
    return getIconFromCollection(set, name, { color, size });
  }
  // If the icon is not found, return a placeholder icon
  return getIconFromCollection("SimpleLineIcons", "notebook", {
    color,
    size: safeSize,
    style
  });
}
