/* global window */

import React, { useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  Platform
} from "react-native";
import tinycolor from "tinycolor2";
import Rex from "src/globalState";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import ColorPicker from "src/components/ColorPicker";
import Icon from "src/components/Icon";

const { height, width } = Glob.get("dimensions");

export default function InputBox({
  inputRef,
  key,
  header,
  description,
  onChangeText = () => {},
  value,
  multiline = false,
  secure = false,
  isCode = false,
  navigation,
  keyboardType,
  autoCapitalize,
  browseForLinkOptions,
  placeholder,
  colorPicker = false,
  headerColorOverride,
  maxLength,
  icon,
  openStyle,
  onBlur,
  onSubmitEditing,
  returnKeyType,
  minHeight,
  isInvalid,
  isWarning,
  isValid,
  editable = true,
  style = {},
  textInputStyle = {},
  showAsOptional = false,
  flexibleWidth = false
}) {
  const [textInputHeight, setTextInputHeight] = useState(0);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [hideSecureText, setHideSecureText] = useState(true);
  const [color, setColor] = useState(value);
  const {
    show = false,
    originalURL = null,
    onPickURL = () => {},
    disabled = false
  } = browseForLinkOptions || {};
  const showBrowseForLinkButton = !!navigation && show;

  const onUpdateColor = (newColor) => {
    setColor(newColor);
    onChangeText(newColor);
  };

  let textContentType = null;
  if (secure && hideSecureText) textContentType = "password";
  else if (keyboardType === "email-address") textContentType = "username";
  else if (keyboardType === "phone-pad") textContentType = "telephoneNumber";

  let autoCapitalizeFinal = autoCapitalize || "sentences";
  if (["password", "email-address", "url"].includes(keyboardType))
    autoCapitalizeFinal = "none";

  let borderColor = "#E4E4E4";
  if (isInvalid) borderColor = "#F03738";
  else if (isWarning) borderColor = "#ebcd34";
  else if (isValid) borderColor = "#2DD881";

  return (
    <View
      style={[{ marginTop: header ? 20 : 3, width: "100%" }, style]}
      key={key}
    >
      {!!header && (
        <Text
          style={[
            Style.get("headerText"),
            headerColorOverride ? { color: headerColorOverride } : {}
          ]}
        >
          {header}
          {showAsOptional ? (
            <Text style={{ fontWeight: "normal", color: "#aaa" }}>
              {" "}
              (optional)
            </Text>
          ) : (
            ""
          )}
        </Text>
      )}
      {!!description && (
        <Text style={styles.questionDescription}>{description}</Text>
      )}
      {(!colorPicker || !!showColorPicker) && (
        <View
          style={{
            borderWidth: 0,
            borderBottomWidth: icon || openStyle ? 1 : 0,
            borderColor,
            flexDirection: "row"
          }}
        >
          {!!icon && (
            <View
              style={{
                paddingLeft: 7,
                paddingBottom: 7,
                justifyContent: "flex-end",
                opacity: editable ? 1 : 0.3
              }}
            >
              <Icon icon={icon} color="#868686" size={24} />
            </View>
          )}
          <TextInput
            ref={inputRef}
            placeholder={placeholder}
            placeholderTextColor="#C7C7CD"
            secureTextEntry={secure && hideSecureText}
            textContentType={textContentType}
            keyboardType={keyboardType}
            autoCapitalize={autoCapitalizeFinal}
            onSubmitEditing={onSubmitEditing}
            returnKeyType={returnKeyType}
            onContentSizeChange={(event) => {
              // Note: Not including this conditional causes a bug on web
              if (event?.nativeEvent?.contentSize?.height > textInputHeight)
                setTextInputHeight(event.nativeEvent.contentSize.height);
            }}
            editable={editable}
            style={[
              styles.textInput,
              flexibleWidth ? { width: "100%" } : {},
              !editable ? { opacity: 0.3 } : {},
              icon || openStyle ? { borderWidth: 0 } : {},
              { borderColor },
              Platform.OS === "web" && multiline
                ? { height: textInputHeight }
                : {},
              isCode
                ? {
                    backgroundColor: "#fafafa",
                    fontFamily: Platform.OS === "ios" ? "Courier" : "monospace"
                  }
                : {},
              minHeight ? { minHeight } : {},
              secure ? { width: "80%" } : {},
              textInputStyle
            ]}
            value={value}
            onChangeText={onChangeText}
            selectionColor={Rex.getConfig()?.colors?.primary}
            multiline={multiline}
            maxLength={maxLength}
            onBlur={onBlur}
          />
          {secure && (
            <TouchableOpacity
              activeOpacity={0.6}
              style={{
                paddingHorizontal: 7,
                paddingBottom: 7,
                justifyContent: "flex-end",
                opacity: editable ? 1 : 0.3
              }}
              onPress={() => setHideSecureText(!hideSecureText)}
            >
              <Icon
                icon={
                  hideSecureText
                    ? "49ebc20a-9a52-468a-884d-84d2b291b8f5" // eye-slash
                    : "ae26cf27-16ef-4385-81cf-a5d335885c13" // eye
                }
                color="black"
                size={20}
              />
            </TouchableOpacity>
          )}
        </View>
      )}
      {!!showBrowseForLinkButton && (
        <TouchableOpacity
          activeOpacity={0.6}
          style={[Style.get("button"), styles.button]}
          onPress={() => {
            if (Platform.OS !== "web")
              navigation.push("webNav", {
                title: "Browse for Link",
                url: originalURL,
                onPickURL
              });
            else window.open("https://www.google.com", "_blank");
          }}
          disabled={disabled}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              adjustsFontSizeToFit
              numberOfLines={1}
              style={[Style.get("normalButtonText"), { fontSize: 16 }]}
            >
              Browse for link
            </Text>
          </View>
        </TouchableOpacity>
      )}
      {!!colorPicker && (!showColorPicker || Platform.OS === "web") && (
        <TouchableOpacity
          activeOpacity={0.6}
          style={[
            Style.get("button"),
            styles.button,
            { backgroundColor: color }
          ]}
          onPress={() => setShowColorPicker(!showColorPicker)}
          disabled={disabled}
        >
          <Text
            adjustsFontSizeToFit
            numberOfLines={1}
            style={[
              Style.get("normalButtonText"),
              { fontSize: 16, fontWeight: "bold" },
              tinycolor(color).isLight() ? { color: "black" } : {}
            ]}
          >
            {showColorPicker ? "Done picking color" : value}
          </Text>
        </TouchableOpacity>
      )}
      {!!showColorPicker && (
        <ColorPicker
          initialColor={value}
          onUpdateColor={onUpdateColor}
          setShowColorPicker={setShowColorPicker}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  textInput: {
    padding: 7,
    fontSize: 16,
    width: 0.82 * width,
    borderWidth: 1,
    borderColor: "#E4E4E4",
    borderRadius: 6,
    paddingLeft: 8,
    marginTop: 8,
    maxHeight: 0.5 * height,
    backgroundColor: "white"
  },
  button: {
    minHeight: 16,
    alignSelf: "flex-start",
    width: "50%",
    borderRadius: 30
  }
});
