import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  Image,
  Platform
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Icon from "src/components/Icon";
import Rex from "src/globalState";
import TCLogoIcon from "src/components/TCLogoIcon";
import AlertModal from "src/components/AlertModal";
import * as Haptics from "expo-haptics";

const { width } = Glob.get("dimensions");

export default function CommerceBillSomeone({ navigation, route }) {
  const { onInvoiceCreated = () => {} } = route.params || {};
  const [activeTab, setActiveTab] = useState("users"); // users or children
  const [tcIntegrationConnected, setTCIntegrationConnected] = useState(false);

  // Users state
  const [users, setUsers] = useState([]);
  const [userIDToUser, setUserIDToUser] = useState({});
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  // Children state
  const [children, setChildren] = useState([]);
  const [filteredChildren, setFilteredChildren] = useState([]);
  const [childSearchQuery, setChildSearchQuery] = useState("");
  const [childrenLoading, setChildrenLoading] = useState(true);
  const [childrenPayers, setChildrenPayers] = useState({});
  const [classrooms, setClassrooms] = useState({});

  // Selection state
  const [selectedUsers, setSelectedUsers] = useState({});
  const [selectedChildren, setSelectedChildren] = useState({});

  // Alert state
  const [alert, setAlert] = useState(null);

  // Add new state for selected classroom filter
  const [selectedClassroom, setSelectedClassroom] = useState("all");

  useEffect(() => {
    Analytics.logEvent("view_commerceBillSomeone");
    fetchUsers();
  }, []);

  useEffect(() => {
    if (activeTab === "children" && (children || []).length < 1) {
      fetchChildren();
    }
  }, [activeTab]);

  const fetchUsers = async () => {
    try {
      if (Rex.getConfig()?.transparentClassroomIntegrationEnabled) {
        const response = await Database.tcCheckIntegrationStatus();
        const { status } = response || {};
        const connected =
          status === Glob.get("tcIntegrationStatuses").CONNECTED;
        setTCIntegrationConnected(connected);
        if (connected) {
          setActiveTab("children");
          await Database.fetchAllTCChildrenData().then((data) => {
            setChildrenPayers(data);
          });
        }
      }
      setLoading(true);
      const allUsers = await Database.fetchAllUsers();
      // Sort alphabetically by name
      const sortedUsers = allUsers.sort((a, b) =>
        `${a.firstName} ${a.lastName}`.localeCompare(
          `${b.firstName} ${b.lastName}`
        )
      );
      setUserIDToUser(
        sortedUsers.reduce((acc, user) => {
          acc[user.uid] = user;
          return acc;
        }, {})
      );
      setUsers(sortedUsers);
      setFilteredUsers(sortedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchChildren = async () => {
    try {
      setChildrenLoading(true);

      // Get all children from Transparent Classroom
      const childrenResponse = await Database.tcFetchChildren();
      const childrenData = childrenResponse.data || [];

      // Sort alphabetically
      const sortedChildren = childrenData.sort((a, b) =>
        `${a.first_name} ${a.last_name}`.localeCompare(
          `${b.first_name} ${b.last_name}`
        )
      );

      setChildren(sortedChildren);
      setFilteredChildren(sortedChildren);

      // Get classrooms for display purposes
      const classroomsResponse = await Database.tcFetchClassrooms();
      const classroomsMap = {};
      (classroomsResponse.data || []).forEach((classroom) => {
        classroomsMap[classroom.id] = classroom;
      });
      setClassrooms(classroomsMap);

      // Reset classroom selection
      setSelectedClassroom("all");
    } catch (error) {
      console.error("Error fetching children data:", error);
    } finally {
      setChildrenLoading(false);
    }
  };

  const handleUserSearch = (text) => {
    setSearchQuery(text);

    if (text.trim() === "") {
      setFilteredUsers(users);
      return;
    }

    const filtered = users.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      const email = user.email.toLowerCase();
      const query = text.toLowerCase();

      return fullName.includes(query) || email.includes(query);
    });

    setFilteredUsers(filtered);
  };

  // Add this function to handle classroom filter selection
  const onPressFilter = (classroomID) => {
    Analytics.logEvent("touch_commerceBillSomeone_filterClassroom", {
      classroomID
    });
    if (Platform.OS !== "web" && Haptics) {
      Haptics.selectionAsync();
    }
    setSelectedClassroom(classroomID);

    // Apply filtering immediately
    filterChildrenByClassroomAndSearch(classroomID, childSearchQuery);
  };

  // Update handleChildSearch to include classroom filtering
  const handleChildSearch = (text) => {
    setChildSearchQuery(text);
    filterChildrenByClassroomAndSearch(selectedClassroom, text);
  };

  // Add a new function to filter children by both classroom and search
  const filterChildrenByClassroomAndSearch = (classroomId, searchText) => {
    let filtered = children;

    // First filter by classroom if specific one is selected
    if (classroomId !== "all") {
      filtered = filtered.filter(
        (child) => (child.classroom_ids || []).indexOf(classroomId) !== -1
      );
    }

    // Then filter by search text if provided
    if (searchText.trim() !== "") {
      filtered = filtered.filter((child) => {
        const fullName = `${child.first_name} ${child.last_name}`.toLowerCase();
        const query = searchText.toLowerCase();
        return fullName.includes(query);
      });
    }

    setFilteredChildren(filtered);
  };

  const toggleUserSelection = (user) => {
    Analytics.logEvent("touch_commerceBillSomeone_toggleUserSelection", {
      userID: user.uid
    });
    const newSelectedUsers = { ...selectedUsers };
    if (newSelectedUsers[user.uid]) {
      delete newSelectedUsers[user.uid];
    } else {
      newSelectedUsers[user.uid] = user;
    }
    setSelectedUsers(newSelectedUsers);
  };

  const toggleChildSelection = (child) => {
    Analytics.logEvent("touch_commerceBillSomeone_toggleChildSelection", {
      childID: child.id
    });

    const billingUserID = childrenPayers?.[child.id]?.billingUserID;

    // Check if the child has a billing parent assigned
    if (!billingUserID) {
      setAlert({
        title: "No Payment Profile",
        message:
          "This child does not have a parent payment profile assigned. Please set up a payment profile for this child in the 'All Children' screen before billing their account."
      });
      return;
    }

    const newSelectedChildren = { ...selectedChildren };
    if (newSelectedChildren[child.id]) {
      delete newSelectedChildren[child.id];
    } else {
      newSelectedChildren[child.id] = {
        child,
        billingUserID
      };
    }
    setSelectedChildren(newSelectedChildren);
  };

  const handleContinue = () => {
    // Only process selections from the active tab
    const selectedBillingAccounts = [];

    if (activeTab === "users") {
      const selectedUsersList = Object.values(selectedUsers);

      // Add directly selected users
      selectedUsersList.forEach((user) => {
        selectedBillingAccounts.push({
          userID: user.uid,
          name: `${user.firstName} ${user.lastName}`,
          type: "user"
        });
      });

      Analytics.logEvent("touch_commerceBillSomeone_continue", {
        userCount: selectedUsersList.length,
        childrenCount: 0
      });
    } else {
      const selectedChildrenList = Object.values(selectedChildren);

      // Add children with their billing parent IDs
      selectedChildrenList.forEach((item) => {
        selectedBillingAccounts.push({
          userID: item.billingUserID,
          child: item.child,
          name: `${item.child.first_name} ${item.child.last_name}`,
          type: "child"
        });
      });

      Analytics.logEvent("touch_commerceBillSomeone_continue", {
        userCount: 0,
        childrenCount: selectedChildrenList.length
      });
    }

    if (selectedBillingAccounts.length === 0) {
      setAlert({
        title: "No Accounts Selected",
        message: "Please select at least one account to bill."
      });
      return;
    }

    navigation.push("commerceCreateInvoice", {
      selectedAccounts: selectedBillingAccounts,
      onInvoiceCreated: () => {
        onInvoiceCreated();
        // This callback will be executed when an invoice is created
        navigation.goBack(); // Go back to commerceBilling screen
      }
    });
  };

  const getSelectionCount = () => {
    // Only count selections from the active tab
    return activeTab === "users"
      ? Object.keys(selectedUsers).length
      : Object.keys(selectedChildren).length;
  };

  const renderUserItem = ({ item }) => {
    const isSelected = selectedUsers[item.uid];

    // Determine which icon to render
    let selectionIcon = null;
    if (isSelected) {
      selectionIcon = (
        <MaterialIcons name="check-circle" size={28} color="#2DD881" />
      );
    } else {
      selectionIcon = (
        <MaterialIcons
          name="radio-button-unchecked"
          size={28}
          color="#CBD5E0"
        />
      );
    }

    return (
      <TouchableOpacity
        style={[styles.userCard, isSelected && styles.selectedUserCard]}
        onPress={() => toggleUserSelection(item)}
        activeOpacity={0.7}
      >
        <View
          style={[
            styles.avatarContainer,
            {
              backgroundColor:
                Rex.getConfig()?.colors?.background ||
                Rex.getConfig()?.colors?.button
            },
            isSelected && styles.selectedAvatar
          ]}
        >
          <Text style={styles.avatarText}>
            {item.firstName[0]}
            {item.lastName[0]}
          </Text>
        </View>
        <View style={styles.userInfo}>
          <Text style={styles.userName}>
            {item.firstName} {item.lastName}
          </Text>
          <Text style={styles.userEmail}>{item.email}</Text>
        </View>
        <View style={styles.selectionIndicator}>{selectionIcon}</View>
      </TouchableOpacity>
    );
  };

  const renderChildItem = ({ item: child }) => {
    const classroomNames = (child.classroom_ids || [])
      .map((id) => classrooms[id]?.name || "")
      .filter((name) => name !== "")
      .join(", ");

    // Check if the child has a valid profile photo
    const hasPhoto =
      !!child.profile_photo && child.profile_photo.includes("http");

    const billingUserID = childrenPayers?.[child.id]?.billingUserID;
    const billingUser = billingUserID ? userIDToUser?.[billingUserID] : null;
    const billingUserFullName = billingUser
      ? `${billingUser.firstName} ${billingUser.lastName}`
      : null;

    const hasBillingProfile = !!billingUserID && !!billingUserFullName;
    const isSelected = !!selectedChildren[child.id];

    // Determine which icon to render
    let selectionIcon = null;
    if (isSelected) {
      selectionIcon = (
        <MaterialIcons name="check-circle" size={28} color="#2DD881" />
      );
    } else if (hasBillingProfile) {
      selectionIcon = (
        <MaterialIcons
          name="radio-button-unchecked"
          size={28}
          color="#CBD5E0"
        />
      );
    }

    return (
      <TouchableOpacity
        style={[
          styles.userCard,
          isSelected && styles.selectedUserCard,
          !hasBillingProfile && styles.disabledUserCard
        ]}
        onPress={() => toggleChildSelection(child)}
        activeOpacity={0.7}
      >
        {hasPhoto ? (
          <Image
            source={{ uri: child.profile_photo }}
            style={[styles.childPhoto, isSelected && styles.selectedAvatar]}
          />
        ) : (
          <View
            style={[
              styles.avatarContainer,
              { backgroundColor: "#3182CE" },
              isSelected && styles.selectedAvatar
            ]}
          >
            <Text style={styles.avatarText}>
              {child.first_name[0]}
              {child.last_name[0]}
            </Text>
          </View>
        )}
        <View style={styles.userInfo}>
          <Text style={styles.userName}>
            {child.first_name} {child.last_name}
          </Text>
          <View style={styles.infoRow}>
            <Icon
              icon="01f1d136-1b73-4f8f-98d6-e6c65f8433e0"
              size={10}
              color="#6c757d"
              style={styles.infoIcon}
            />

            <Text style={styles.userEmail}>
              {classroomNames || "No classroom"}
            </Text>
          </View>

          {/* Payment Profile Section */}
          {billingUserFullName ? (
            <View style={styles.paymentProfileRow}>
              <Icon
                icon="money"
                color="#2DD881"
                size={11}
                style={styles.infoIcon}
              />
              <Text style={styles.paymentProfileText}>
                {billingUserFullName}
              </Text>
            </View>
          ) : (
            <View style={styles.paymentProfileRow}>
              <MaterialIcons
                name="warning"
                size={11}
                color="#F59E0B"
                style={styles.infoIcon}
              />
              <Text style={styles.noPaymentProfileText}>
                No payment profile assigned
              </Text>
            </View>
          )}
        </View>
        {selectionIcon && (
          <View style={styles.selectionIndicator}>{selectionIcon}</View>
        )}
      </TouchableOpacity>
    );
  };

  // Update tab selection behavior to clear previous selections
  const handleTabChange = (tab) => {
    if (tab === activeTab) return;

    // Log analytics event
    if (tab === "users") {
      Analytics.logEvent("touch_commerceBillSomeone_viewUsers");
    } else {
      Analytics.logEvent("touch_commerceBillSomeone_viewChildren");
    }

    setActiveTab(tab);
  };

  const renderTabBar = () => (
    <View style={styles.tabContainer}>
      <TouchableOpacity
        style={[
          styles.tabButton,
          activeTab === "children" && {
            backgroundColor: Rex.getConfig()?.colors?.button
          }
        ]}
        onPress={() => handleTabChange("children")}
      >
        <Icon
          icon="people"
          color={activeTab === "children" ? "#fff" : "#4A5568"}
          size={18}
        />
        <Text
          style={[
            styles.tabText,
            activeTab === "children" && styles.activeTabText
          ]}
        >
          Child Accounts
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={[
          styles.tabButton,
          activeTab === "users" && {
            backgroundColor: Rex.getConfig()?.colors?.button
          }
        ]}
        onPress={() => handleTabChange("users")}
      >
        <Icon
          icon="05475c18-1a66-4e08-9183-8b10b5b6de55"
          color={activeTab === "users" ? "#fff" : "#4A5568"}
          size={20}
        />
        <Text
          style={[
            styles.tabText,
            activeTab === "users" && styles.activeTabText
          ]}
        >
          App Members
        </Text>
      </TouchableOpacity>
    </View>
  );

  const renderUserContent = () => {
    if (loading) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator
            size="large"
            color={Rex.getConfig()?.colors?.button}
          />
          <Text style={styles.loadingText}>Loading users...</Text>
        </View>
      );
    }

    return (
      <View style={styles.listContainer}>
        {filteredUsers.length > 0 ? (
          <FlatList
            data={filteredUsers}
            renderItem={renderUserItem}
            keyExtractor={(item) => item.uid}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={styles.userList}
          />
        ) : (
          <View style={styles.emptyContainer}>
            <MaterialIcons name="search-off" size={48} color="#A0AEC0" />
            <Text style={styles.emptyText}>No users found</Text>
            <Text style={styles.emptySubtext}>Try a different search term</Text>
          </View>
        )}
      </View>
    );
  };

  const renderChildrenContent = () => {
    if (childrenLoading) {
      return (
        <View style={styles.loadingContainer}>
          <TCLogoIcon spinning size={40} />
          <Text style={styles.loadingText}>Loading children...</Text>
        </View>
      );
    }

    return (
      <View style={styles.listContainer}>
        {filteredChildren.length > 0 ? (
          <FlatList
            data={filteredChildren}
            renderItem={renderChildItem}
            keyExtractor={(item) => item.id.toString()}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={styles.userList}
          />
        ) : (
          <View style={styles.emptyContainer}>
            <MaterialIcons name="search-off" size={48} color="#A0AEC0" />
            <Text style={styles.emptyText}>No children found</Text>
            <Text style={styles.emptySubtext}>Try a different search term</Text>
          </View>
        )}
      </View>
    );
  };

  const selectionCount = getSelectionCount();

  // Add this function to render classroom filters
  const renderClassroomFilters = () => {
    const classroomList = Object.values(classrooms || {});
    const selectedColor = Rex.getConfig()?.colors?.button || "black";
    return (
      <View style={styles.filterContainer}>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.filterContent}
        >
          <TouchableOpacity
            style={[
              styles.filterPill,
              selectedClassroom === "all" && {
                backgroundColor: selectedColor
              }
            ]}
            activeOpacity={0.7}
            onPress={() => onPressFilter("all")}
          >
            <Text
              style={[
                styles.filterText,
                selectedClassroom === "all" && styles.filterTextActive
              ]}
            >
              All Classrooms
            </Text>
          </TouchableOpacity>

          {classroomList.map((classroom) => (
            <TouchableOpacity
              key={classroom.id}
              style={[
                styles.filterPill,
                selectedClassroom === classroom.id && {
                  backgroundColor: selectedColor
                }
              ]}
              activeOpacity={0.7}
              onPress={() => onPressFilter(classroom.id)}
            >
              <Text
                style={[
                  styles.filterText,
                  selectedClassroom === classroom.id && styles.filterTextActive
                ]}
              >
                {classroom.name}
              </Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NavBar navigation={navigation} text="Bill Someone" />

      <View style={{ width }}>
        <Text style={styles.headerText}>Who do you want to bill?</Text>
        <Text style={styles.subHeaderText}>
          Select multiple accounts to bill them all together
        </Text>

        {tcIntegrationConnected && renderTabBar()}

        {/* Add classroom filters for children tab */}
        {activeTab === "children" && renderClassroomFilters()}

        <View style={styles.searchContainer}>
          <MaterialIcons
            name="search"
            size={22}
            color="#718096"
            style={styles.searchIcon}
          />
          <TextInput
            style={styles.searchInput}
            placeholder={
              activeTab === "users"
                ? "Search by name or email"
                : "Search by name"
            }
            value={activeTab === "users" ? searchQuery : childSearchQuery}
            onChangeText={
              activeTab === "users" ? handleUserSearch : handleChildSearch
            }
            placeholderTextColor="#A0AEC0"
            autoCapitalize="none"
            autoCorrect={false}
          />
          {(activeTab === "users" && searchQuery.length > 0) ||
          (activeTab === "children" && childSearchQuery.length > 0) ? (
            <TouchableOpacity
              onPress={() => {
                if (activeTab === "users") {
                  handleUserSearch("");
                } else {
                  handleChildSearch("");
                }
              }}
            >
              <MaterialIcons name="cancel" size={18} color="#A0AEC0" />
            </TouchableOpacity>
          ) : null}
        </View>
      </View>

      <ScrollView style={{ width }}>
        {activeTab === "users" ? renderUserContent() : renderChildrenContent()}
      </ScrollView>

      {/* Selection banner and continue button */}
      {selectionCount > 0 && (
        <View style={styles.selectionBanner}>
          <View style={styles.selectionInfo}>
            <Text style={styles.selectionText}>
              {selectionCount}{" "}
              {activeTab === "users" ? "app member" : "child account"}
              {selectionCount !== 1 ? "s" : ""} selected
            </Text>
          </View>
          <TouchableOpacity
            style={[
              styles.continueButton,
              { backgroundColor: Rex.getConfig()?.colors?.button }
            ]}
            onPress={handleContinue}
          >
            <Text style={styles.continueButtonText}>Continue</Text>
            <MaterialIcons name="arrow-forward" size={20} color="#fff" />
          </TouchableOpacity>
        </View>
      )}

      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F5F7FA"
  },
  tabContainer: {
    flexDirection: "row",
    marginHorizontal: 20,
    marginTop: 8,
    marginBottom: 8,
    borderRadius: 12,
    overflow: "hidden",
    backgroundColor: "#E2E8F0"
  },
  tabButton: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12,
    backgroundColor: "transparent"
  },
  tabText: {
    fontSize: 15,
    fontWeight: "500",
    color: "#4A5568",
    marginLeft: 6
  },
  activeTabText: {
    color: "#fff"
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    marginHorizontal: 20,
    marginTop: 8,
    marginBottom: 16,
    paddingHorizontal: 16,
    borderRadius: 12,
    height: 50,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 5,
    elevation: 2
  },
  searchIcon: {
    marginRight: 10
  },
  searchInput: {
    flex: 1,
    fontSize: 16,
    color: "#2D3748"
  },
  listContainer: {
    flex: 1,
    marginHorizontal: 20
  },
  userList: {
    paddingBottom: 90 // Extra padding for the selection banner
  },
  userCard: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    padding: 16,
    borderRadius: 16,
    marginBottom: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.05,
    shadowRadius: 3,
    elevation: 1,
    overflow: "hidden"
  },
  selectedUserCard: {
    backgroundColor: "#E6FFEE", // Brighter green background
    borderWidth: 2,
    borderColor: "#2DD881" // Bright green border matching the checkmark
  },
  disabledUserCard: {
    opacity: 0.8
  },
  avatarContainer: {
    width: 44,
    height: 44,
    borderRadius: 22,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
    borderWidth: 0 // Default no border
  },
  avatarText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600"
  },
  userInfo: {
    flex: 1,
    marginRight: 12
  },
  userName: {
    fontSize: 16,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 4
  },
  userEmail: {
    fontSize: 12,
    color: "#718096"
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  loadingText: {
    marginTop: 12,
    fontSize: 16,
    color: "#718096"
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 50
  },
  emptyText: {
    fontSize: 18,
    fontWeight: "600",
    color: "#4A5568",
    marginTop: 16
  },
  emptySubtext: {
    fontSize: 14,
    color: "#718096",
    marginTop: 8
  },
  headerText: {
    fontSize: 20,
    fontWeight: "600",
    color: "#2D3748",
    marginTop: 20,
    marginHorizontal: 20
  },
  subHeaderText: {
    fontSize: 14,
    color: "#718096",
    marginHorizontal: 20,
    marginTop: 4
  },
  paymentProfileRow: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 2
  },
  infoIcon: {
    marginRight: 4
  },
  paymentProfileText: {
    fontSize: 12,
    color: "#2DD881"
  },
  noPaymentProfileText: {
    fontSize: 12,
    color: "#F59E0B"
  },
  selectionBanner: {
    position: Platform.OS === "web" ? "fixed" : "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "white",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    borderTopWidth: 1,
    borderTopColor: "#E2E8F0",
    elevation: 4,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: -2 },
    shadowOpacity: 0.1,
    shadowRadius: 4
  },
  selectionInfo: {
    flexDirection: "row",
    alignItems: "center"
  },
  selectionText: {
    fontSize: 16,
    fontWeight: "600",
    color: "#2D3748"
  },
  continueButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderRadius: 8
  },
  continueButtonText: {
    color: "white",
    fontWeight: "600",
    marginRight: 8
  },
  selectionIndicator: {
    alignItems: "center",
    justifyContent: "center",
    width: 28,
    height: 28
  },
  childPhoto: {
    width: 44,
    height: 44,
    borderRadius: 22,
    marginRight: 12,
    backgroundColor: "#f0f0f0" // Light background while loading
  },
  infoRow: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 2
  },
  selectedAvatar: {
    borderWidth: 2,
    borderColor: "#2DD881"
  },
  filterContainer: {
    width: "100%",
    marginBottom: 8
  },
  filterContent: {
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center"
  },
  filterPill: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 20,
    backgroundColor: "#f0f0f0",
    marginRight: 8
  },
  filterText: {
    fontSize: 13,
    color: "#4A5568"
  },
  filterTextActive: {
    color: "white",
    fontWeight: "500"
  }
});
