import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
  Image
} from "react-native";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import moment from "moment";
import School from "school/school";
import BottomSheetModal from "src/components/BottomSheetModal";
import Util from "src/utility";
import Rex from "src/globalState";
import { Skeleton } from "native-base";

const { width } = Glob.get("dimensions");

const DashboardCard = ({ title, children, icon, isLoading }) => (
  <View style={styles.card}>
    <View style={styles.cardHeader}>
      {icon}
      <Text style={styles.cardTitle}>{title}</Text>
    </View>
    {isLoading ? (
      <View style={styles.sectionLoadingContainer}>
        <ActivityIndicator size="small" color="#4A5568" />
      </View>
    ) : (
      children
    )}
  </View>
);

export default function PaymentsCustomerDashboard({ navigation, route }) {
  const { stripeCustomer: stripeCustomerParam } = route.params || {};
  const [customerProfile, setCustomerProfile] = useState({});
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [
    isLoadingEditCustomerPortalURL,
    setIsLoadingEditCustomerPortalURL
  ] = useState(false);
  const [portalURL, setPortalURL] = useState(null);
  const [unpaidInvoices, setUnpaidInvoices] = useState([]);
  const [isLoadingUnpaidInvoices, setIsLoadingUnpaidInvoices] = useState(true);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(true);
  const [pastPayments, setPastPayments] = useState([]);
  const [isLoadingPayments, setIsLoadingPayments] = useState(true);
  const [isShowingAllPayments, setIsShowingAllPayments] = useState(false);
  const [children, setChildren] = useState({});
  const [isLoadingChildren, setIsLoadingChildren] = useState(false);

  useEffect(() => {
    Analytics.logEvent("view_paymentsCustomerDashboard");
    fetchData();
    if (Rex.getConfig()?.transparentClassroomIntegrationEnabled) {
      fetchChildren();
    }
  }, []);

  const fetchChildren = async () => {
    try {
      setIsLoadingChildren(true);
      const response = await Database.tcCheckIntegrationStatus();
      const { status } = response || {};
      const connected = status === Glob.get("tcIntegrationStatuses").CONNECTED;

      if (connected) {
        const childrenResponse = await Database.tcFetchChildren();
        const childrenByID = {};
        (childrenResponse?.data || []).forEach((child) => {
          childrenByID[child.id] = child;
        });
        setChildren(childrenByID);
      }
    } catch (error) {
      console.error("Error fetching child data:", error);
    } finally {
      setIsLoadingChildren(false);
    }
  };

  const fetchData = async () => {
    try {
      // Fetch user information
      const user = await Database.fetchGlobalUser();

      // Fetch Stripe customer details
      let stripeCustomer = stripeCustomerParam;
      if (!stripeCustomer) {
        stripeCustomer = await Database.stripeConnectedAccountFetchCustomer({
          createCustomerIfDoesntExist: true
        });
      }

      if (stripeCustomer) {
        setCustomerProfile((prev) => ({
          ...prev,
          ...stripeCustomer
        }));

        // Start all data fetching in parallel
        fetchUnpaidInvoices(stripeCustomer.id);
        fetchCustomerSubscriptions();
        fetchPastPayments(stripeCustomer.id);
      }

      // Fetch and filter commerce orders
      if (user.commerce?.orders) {
        const filteredOrders = Object.values(user.commerce.orders).filter(
          (order) => order.appID === School.getDatabaseAppID()
        );
        if (filteredOrders.length > 0) {
          setCustomerProfile((prev) => ({
            ...prev,
            orders: filteredOrders
          }));
        }
      }

      setIsLoadingProfile(false);
    } catch (error) {
      console.error("Error fetching customer profile:", error);
      setIsLoadingProfile(false);
    }
  };

  const fetchUnpaidInvoices = async (customerID) => {
    setIsLoadingUnpaidInvoices(true);
    try {
      const invoicesResponse = await Database.stripeConnectedAccountFetchAllInvoices(
        {
          unpaidOnly: true,
          stripeCustomerID: customerID
        }
      );
      setUnpaidInvoices(invoicesResponse.invoices || []);
    } catch (error) {
      console.error("Error fetching invoices:", error);
    } finally {
      setIsLoadingUnpaidInvoices(false);
    }
  };

  const fetchCustomerSubscriptions = async () => {
    setIsLoadingSubscriptions(true);
    try {
      const response = await Database.stripeConnectedAccountFetchSubscriptions();
      if (response.success && response.subscriptions) {
        setActiveSubscriptions(response.subscriptions);
      }
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    } finally {
      setIsLoadingSubscriptions(false);
    }
  };

  const fetchPastPayments = async (customerID, fetchAll = false) => {
    setIsLoadingPayments(true);
    try {
      // Fetch paid invoices as payment history
      const paidInvoicesResponse = await Database.stripeConnectedAccountFetchAllInvoices(
        {
          paidOnly: true,
          stripeCustomerID: customerID,
          limit: fetchAll ? 100 : 10 // Use a higher limit when fetching all
        }
      );

      // Filter to only include paid invoices with payment info
      const payments = (paidInvoicesResponse.invoices || []).filter(
        (invoice) => invoice.status === "paid" && invoice.payment_intent
      );

      setPastPayments(payments);
      setIsShowingAllPayments(fetchAll);
    } catch (error) {
      console.error("Error fetching payment history:", error);
    } finally {
      setIsLoadingPayments(false);
    }
  };

  const loadAllPayments = () => {
    Analytics.logEvent("touch_viewAllPaymentHistory");
    if (customerProfile.id) {
      fetchPastPayments(customerProfile.id, true);
    }
  };

  const defaultPaymentMethodID =
    customerProfile?.invoice_settings?.default_payment_method;

  // Child indicator component
  const ChildIndicator = ({ item }) => {
    if (!item?.metadata?.tcChildID) return null;

    const { tcChildID } = item.metadata;
    const child = tcChildID ? children[tcChildID] : null;
    const hasPhoto =
      child?.profile_photo && child.profile_photo.includes("http");

    // Get dynamic colors
    const buttonColor = Rex.getConfig()?.colors?.button || "#805AD5";
    const lightButtonColor = Util.getSuperLightColor(buttonColor);
    const mediumButtonColor = `${buttonColor}80`; // Using 50% opacity

    // Check if we have a tcChildID but child data isn't loaded yet
    const isLoadingChild =
      tcChildID &&
      !child &&
      !isLoadingChildren &&
      Object.keys(children).length > 0;

    if (isLoadingChild) {
      return (
        <View
          style={[styles.childContainer, { backgroundColor: lightButtonColor }]}
        >
          <Skeleton
            size="5"
            rounded="full"
            startColor={lightButtonColor}
            endColor={mediumButtonColor}
            style={{ marginRight: 6, width: 16, height: 16 }}
          />
          <Skeleton
            height="3"
            width="80"
            rounded="full"
            startColor={lightButtonColor}
            endColor={mediumButtonColor}
          />
        </View>
      );
    }

    if (child) {
      return (
        <View
          style={[styles.childContainer, { backgroundColor: lightButtonColor }]}
        >
          {hasPhoto ? (
            <Image
              source={{ uri: child.profile_photo }}
              style={styles.childPhoto}
            />
          ) : (
            <View
              style={[styles.childAvatar, { backgroundColor: buttonColor }]}
            >
              <Text style={styles.avatarText}>
                {child.first_name.charAt(0)}
                {child.last_name.charAt(0)}
              </Text>
            </View>
          )}
          <Text style={[styles.childName, { color: buttonColor }]}>
            {child.first_name} {child.last_name}
          </Text>
        </View>
      );
    }

    return null;
  };

  return (
    <>
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Billing & Payments" />
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.scrollViewContent}
        >
          <DashboardCard
            title="Payment Profile"
            icon={
              <MaterialCommunityIcons
                name={`bank${defaultPaymentMethodID ? "-check" : ""}`}
                size={24}
                color="#4A5568"
              />
            }
            isLoading={isLoadingProfile}
          >
            <View style={styles.customerInfo}>
              <Text style={styles.customerName}>{customerProfile.name}</Text>
              <Text style={styles.customerEmail}>{customerProfile.email}</Text>
              {customerProfile.phone && (
                <Text style={styles.customerDetail}>
                  {customerProfile.phone}
                </Text>
              )}
              {customerProfile.address?.postal_code && (
                <Text style={styles.customerDetail}>
                  ZIP: {customerProfile.address.postal_code}
                </Text>
              )}
            </View>

            {!defaultPaymentMethodID ? (
              <TouchableOpacity
                style={[
                  styles.actionButton,
                  isLoadingEditCustomerPortalURL && { opacity: 0.6 }
                ]}
                disabled={isLoadingEditCustomerPortalURL}
                onPress={() => {
                  setIsLoadingEditCustomerPortalURL(true);
                  Database.stripeConnectedAccountCreateCustomerPortalSession({
                    flowType: "payment_method_update"
                  })
                    .then((response) => {
                      if (response.success) {
                        if (Platform.OS === "web")
                          Util.openURL(response.customerPortalSessionURL);
                        else setPortalURL(response.customerPortalSessionURL);
                      }
                      setIsLoadingEditCustomerPortalURL(false);
                    })
                    .catch(() => {
                      setIsLoadingEditCustomerPortalURL(false);
                    });
                }}
              >
                {isLoadingEditCustomerPortalURL ? (
                  <ActivityIndicator color="white" size="small" />
                ) : (
                  <>
                    <MaterialIcons name="add" size={20} color="white" />
                    <Text style={styles.actionButtonText}>
                      Add Payment Method
                    </Text>
                  </>
                )}
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={[
                  styles.historyButton,
                  { marginVertical: 10 },
                  isLoadingEditCustomerPortalURL && { opacity: 0.6 }
                ]}
                disabled={isLoadingEditCustomerPortalURL}
                onPress={() => {
                  setIsLoadingEditCustomerPortalURL(true);
                  Database.stripeConnectedAccountCreateCustomerPortalSession()
                    .then((response) => {
                      if (response.success) {
                        if (Platform.OS === "web")
                          Util.openURL(response.customerPortalSessionURL);
                        else setPortalURL(response.customerPortalSessionURL);
                      }
                      setIsLoadingEditCustomerPortalURL(false);
                    })
                    .catch(() => {
                      setIsLoadingEditCustomerPortalURL(false);
                    });
                }}
              >
                {isLoadingEditCustomerPortalURL ? (
                  <ActivityIndicator color="#4A5568" size="small" />
                ) : (
                  <>
                    <Text style={styles.historyButtonText}>
                      Manage Payment Profile
                    </Text>
                    <MaterialIcons
                      name="arrow-forward"
                      size={20}
                      color="#4A5568"
                    />
                  </>
                )}
              </TouchableOpacity>
            )}
          </DashboardCard>

          <DashboardCard
            title="Invoices Due"
            icon={<MaterialIcons name="payment" size={24} color="#4A5568" />}
            isLoading={isLoadingUnpaidInvoices}
          >
            {unpaidInvoices.length > 0 ? (
              unpaidInvoices.map((invoice, index) => (
                <TouchableOpacity
                  key={index}
                  style={styles.subscriptionItem}
                  onPress={() => {
                    Analytics.logEvent("touch_viewInvoiceDetails");
                    navigation.push("paymentsCustomerInvoiceDetails", {
                      invoice,
                      customerProfile,
                      onPaymentSuccess: () => {
                        setUnpaidInvoices((currentInvoices) =>
                          currentInvoices.filter((inv) => inv.id !== invoice.id)
                        );
                      }
                    });
                  }}
                >
                  <View style={styles.invoiceContentWrapper}>
                    <View style={styles.invoiceDescription}>
                      <Text
                        style={styles.subscriptionName}
                        numberOfLines={1}
                        ellipsizeMode="tail"
                      >
                        {invoice.lines.data[0]?.description ||
                          "Invoice Payment"}
                      </Text>
                      {invoice?.metadata?.tcChildID && (
                        <ChildIndicator item={invoice} />
                      )}
                    </View>
                    <View style={styles.invoiceAmountWrapper}>
                      <Text style={styles.subscriptionPrice}>
                        ${(invoice.amount_due / 100).toFixed(2)}
                      </Text>
                      <Text style={styles.dueDate}>
                        {invoice.due_date ? (
                          <>
                            Due{" "}
                            {moment(invoice.due_date * 1000).format("MMM D")}
                          </>
                        ) : (
                          <Text style={styles.autoPaymentText}>
                            Auto-payment
                            {invoice.next_payment_attempt
                              ? ` on ${moment(
                                  invoice.next_payment_attempt * 1000
                                ).format("MMM D")}`
                              : ""}
                          </Text>
                        )}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              ))
            ) : (
              <Text style={styles.emptyText}>No invoices due</Text>
            )}
          </DashboardCard>

          <DashboardCard
            title="Active Subscriptions"
            icon={<MaterialIcons name="autorenew" size={24} color="#4A5568" />}
            isLoading={isLoadingSubscriptions}
          >
            {activeSubscriptions.length > 0 ? (
              activeSubscriptions.map((subscription, index) => {
                // Get the first item's price and product info
                const item = subscription.items.data[0];
                const product = item?.price?.product;
                const interval = item?.price?.recurring?.interval || "month";
                const intervalCount =
                  item?.price?.recurring?.interval_count || 1;
                const quantity = item?.quantity || 1;
                const unitAmount = item?.price?.unit_amount / 100; // Unit price in dollars
                const totalAmount = unitAmount * quantity; // Total amount accounting for quantity

                // Format the billing cycle text
                let billingCycle = "";
                if (intervalCount === 1) {
                  // Use a simple mapping for interval types instead of nested ternaries
                  const intervalMap = {
                    month: "Monthly",
                    year: "Yearly",
                    week: "Weekly",
                    day: "Daily"
                  };
                  billingCycle = intervalMap[interval] || `${interval}ly`;
                } else {
                  billingCycle = `Every ${intervalCount} ${interval +
                    (intervalCount > 1 ? "s" : "")}`;
                }

                return (
                  <TouchableOpacity
                    key={index}
                    style={styles.subscriptionItem}
                    onPress={() => {
                      Analytics.logEvent("touch_viewSubscriptionDetails");
                      navigation.push("paymentsCustomerSubscriptionDetails", {
                        subscription,
                        customerProfile
                      });
                    }}
                  >
                    <View>
                      <Text
                        style={styles.subscriptionName}
                        numberOfLines={1}
                        ellipsizeMode="tail"
                      >
                        {product?.name || "Subscription"}
                        {quantity > 1 ? ` (${quantity})` : ""}
                      </Text>
                      {subscription?.metadata?.tcChildID && (
                        <ChildIndicator item={subscription} />
                      )}
                    </View>
                    <View style={styles.subscriptionDetails}>
                      <Text style={styles.subscriptionPrice}>
                        ${totalAmount.toFixed(2)} / {billingCycle}
                      </Text>
                      <Text style={styles.renewalDate}>
                        {subscription.cancel_at ||
                        subscription.cancel_at_period_end
                          ? "Cancels "
                          : "Renews "}
                        {moment
                          .unix(subscription.current_period_end)
                          .format("MMM D")}
                      </Text>
                    </View>
                  </TouchableOpacity>
                );
              })
            ) : (
              <Text style={styles.emptyText}>No active subscriptions</Text>
            )}
          </DashboardCard>

          <DashboardCard
            title="Payment History"
            icon={
              <MaterialIcons name="receipt-long" size={24} color="#4A5568" />
            }
            isLoading={isLoadingPayments}
          >
            {pastPayments.length > 0 ? (
              <>
                {pastPayments.map((payment, index) => (
                  <TouchableOpacity
                    key={index}
                    style={styles.paymentItem}
                    onPress={() => {
                      Analytics.logEvent("touch_viewPaymentDetails");
                      navigation.push("paymentsCustomerInvoiceDetails", {
                        invoice: payment,
                        customerProfile,
                        isPaidInvoice: true
                      });
                    }}
                  >
                    <View style={styles.paymentInfo}>
                      <Text
                        style={styles.paymentDescription}
                        numberOfLines={1}
                        ellipsizeMode="tail"
                      >
                        {payment.lines.data[0]?.description || "Payment"}
                      </Text>
                      {payment?.metadata?.tcChildID && (
                        <ChildIndicator item={payment} />
                      )}
                      <Text style={styles.paymentDate}>
                        {moment.unix(payment.created).format("MMM D, YYYY")}
                      </Text>
                    </View>
                    <View style={styles.paymentAmount}>
                      <Text style={styles.paymentPrice}>
                        ${(payment.amount_paid / 100).toFixed(2)}
                      </Text>
                      <View style={styles.paymentStatusContainer}>
                        <View style={styles.paymentStatusDot} />
                        <Text style={styles.paymentStatusText}>Paid</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                ))}

                {!isShowingAllPayments && (
                  <TouchableOpacity
                    style={[styles.historyButton, { marginTop: 15 }]}
                    onPress={loadAllPayments}
                    disabled={isLoadingPayments}
                  >
                    {isLoadingPayments ? (
                      <ActivityIndicator size="small" color="#4A5568" />
                    ) : (
                      <>
                        <Text style={styles.historyButtonText}>
                          View All Payments
                        </Text>
                        <MaterialIcons
                          name="expand-more"
                          size={20}
                          color="#4A5568"
                        />
                      </>
                    )}
                  </TouchableOpacity>
                )}
              </>
            ) : (
              <>
                <Text style={styles.emptyText}>No payment history</Text>

                {customerProfile?.orders?.length > 0 && (
                  <TouchableOpacity
                    style={[styles.historyButton, { marginTop: 15 }]}
                    onPress={() => {
                      Analytics.logEvent("touch_viewOrderHistory");
                      navigation.push("paymentsCustomerOrderHistory", {
                        commerceOrders: customerProfile?.orders || []
                      });
                    }}
                  >
                    <Text style={styles.historyButtonText}>
                      View Order History
                    </Text>
                    <MaterialIcons
                      name="arrow-forward"
                      size={20}
                      color="#4A5568"
                    />
                  </TouchableOpacity>
                )}
              </>
            )}
          </DashboardCard>
        </ScrollView>
      </View>
      {!!portalURL && (
        <BottomSheetModal
          onClose={() => {
            setPortalURL(null);
            fetchData();
          }}
          url={portalURL}
          fullestStyleNative
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F5F7FA"
  },
  scrollView: {
    width
  },
  scrollViewContent: {
    paddingHorizontal: 20,
    paddingVertical: 20,
    paddingBottom: 50
  },
  card: {
    backgroundColor: "white",
    borderRadius: 12,
    padding: 20,
    marginBottom: 20,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3
  },
  cardHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#2D3748",
    marginLeft: 12
  },
  actionButton: {
    backgroundColor: "#2DD881",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
    borderRadius: 8,
    marginTop: 8
  },
  actionButtonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "500",
    marginLeft: 8
  },
  historyButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 12,
    backgroundColor: "#F7FAFC",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#E2E8F0"
  },
  historyButtonText: {
    fontSize: 16,
    color: "#4A5568",
    fontWeight: "500"
  },
  subscriptionItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0"
  },
  invoiceContentWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  },
  invoiceDescription: {
    flex: 1,
    paddingRight: 10
  },
  invoiceAmountWrapper: {
    alignItems: "flex-end"
  },
  subscriptionName: {
    fontSize: 16,
    color: "#2D3748",
    fontWeight: "500",
    flex: 1,
    paddingRight: 10
  },
  subscriptionPrice: {
    fontSize: 16,
    color: "#718096",
    fontWeight: "600"
  },
  emptyText: {
    fontSize: 16,
    color: "#718096",
    textAlign: "center",
    paddingVertical: 12
  },
  dueDate: {
    fontSize: 12,
    color: "#A0AEC0",
    marginTop: 2
  },
  customerInfo: {
    marginBottom: 16,
    padding: 12,
    backgroundColor: "#F7FAFC",
    borderRadius: 8
  },
  customerName: {
    fontSize: 18,
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: 4
  },
  customerEmail: {
    fontSize: 14,
    color: "#4A5568",
    marginBottom: 2
  },
  customerDetail: {
    fontSize: 14,
    color: "#718096"
  },
  sectionLoadingContainer: {
    padding: 30,
    alignItems: "center",
    justifyContent: "center"
  },
  subscriptionDetails: {
    alignItems: "flex-end"
  },
  renewalDate: {
    fontSize: 12,
    color: "#A0AEC0",
    marginTop: 2
  },
  autoPaymentText: {
    fontSize: 12,
    color: "#4299E1",
    marginTop: 2
  },
  paymentItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0"
  },
  paymentInfo: {
    flex: 1
  },
  paymentDescription: {
    fontSize: 16,
    color: "#2D3748",
    fontWeight: "500",
    paddingRight: 10
  },
  paymentDate: {
    fontSize: 12,
    color: "#A0AEC0",
    marginTop: 2
  },
  paymentAmount: {
    alignItems: "flex-end"
  },
  paymentPrice: {
    fontSize: 16,
    color: "#2D3748",
    fontWeight: "600"
  },
  paymentStatusContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 2
  },
  paymentStatusDot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: "#2DD881",
    marginRight: 4
  },
  paymentStatusText: {
    fontSize: 12,
    color: "#2DD881",
    fontWeight: "500"
  },
  childContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 4,
    paddingHorizontal: 8,
    paddingVertical: 3,
    borderRadius: 4,
    alignSelf: "flex-start"
  },
  childPhoto: {
    width: 16,
    height: 16,
    borderRadius: 8,
    marginRight: 6
  },
  childAvatar: {
    width: 16,
    height: 16,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 6
  },
  avatarText: {
    fontSize: 8,
    fontWeight: "bold",
    color: "white"
  },
  childName: {
    fontSize: 12,
    fontWeight: "500"
  }
});
