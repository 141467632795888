import React from "react";
import { StyleSheet, View, Text, Image, TouchableOpacity } from "react-native";
import Icon from "src/components/Icon";
import Rex from "src/globalState";

// Component for displaying a child's information
const ChildCard = ({ child, classrooms = {}, onPress = null }) => {
  // Check if the child has a valid profile photo
  const hasPhoto =
    !!child.profile_photo && child.profile_photo.includes("http");

  // Calculate child's age in years and months
  const birthDate = new Date(child.birth_date);
  const today = new Date();

  // Calculate total months between dates
  let monthsTotal = (today.getFullYear() - birthDate.getFullYear()) * 12;
  monthsTotal += today.getMonth() - birthDate.getMonth();

  // Adjust if the day of month hasn't been reached yet
  if (today.getDate() < birthDate.getDate()) {
    monthsTotal -= 1;
  }

  // Calculate years and remaining months
  const years = Math.floor(monthsTotal / 12);
  const months = monthsTotal % 12;

  // Format age string
  let ageString = "";
  if (years > 0) {
    ageString += `${years}yr`;
    if (months > 0) ageString += ` ${months}mo`;
  } else {
    ageString = `${months}mo`;
  }

  // Create the touchable container based on whether onPress is provided
  const Container = onPress ? TouchableOpacity : View;
  const containerProps = onPress ? { onPress } : {};

  return (
    <Container style={styles.childCard} {...containerProps}>
      {hasPhoto ? (
        <Image
          source={{ uri: child.profile_photo }}
          style={styles.childPhoto}
          onError={() => {
            // If we wanted to handle image load errors, we'd use state here
          }}
        />
      ) : (
        <View
          style={[
            styles.childAvatar,
            { backgroundColor: Rex.getConfig()?.colors?.background }
          ]}
        >
          <Text style={styles.avatarText}>
            {child.first_name.charAt(0)}
            {child.last_name.charAt(0)}
          </Text>
        </View>
      )}
      <View style={styles.childInfo}>
        <Text style={styles.childName}>
          {child.first_name} {child.last_name}
        </Text>
        <View style={styles.childDetails}>
          <View style={styles.childDetail}>
            <Icon
              icon="0e52f4e2-68e5-40f5-bd8e-2aa22e80d868"
              size={12}
              color="#6c757d"
            />
            <Text style={styles.detailText}>{ageString}</Text>
          </View>

          {child.classroom_ids && child.classroom_ids.length > 0 && (
            <View style={styles.childDetail}>
              <Icon
                icon="01f1d136-1b73-4f8f-98d6-e6c65f8433e0"
                size={12}
                color="#6c757d"
              />
              <Text style={styles.detailText}>
                {child.classroom_ids
                  .map((id) => classrooms[id]?.name || id)
                  .filter(Boolean)
                  .join(", ")}
              </Text>
            </View>
          )}
        </View>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  childCard: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 12,
    paddingHorizontal: 5,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#f0f0f0"
  },
  childAvatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: "#333",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 12
  },
  childPhoto: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 12,
    backgroundColor: "#f0f0f0" // Light background until image loads
  },
  avatarText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "white"
  },
  childInfo: {
    flex: 1
  },
  childName: {
    fontSize: 16,
    color: "#212529",
    fontWeight: "600",
    marginBottom: 4
  },
  childDetails: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 2
  },
  childDetail: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 12,
    marginBottom: 2
  },
  detailText: {
    marginLeft: 4,
    fontSize: 12,
    color: "#6c757d"
  }
});

export default ChildCard;
