import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  ScrollView,
  TextInput,
  Platform
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Util from "src/utility";
import Style from "src/globalStyles";

const { width, height } = Glob.get("dimensions");
const DROPDOWN_CONTAINER_HEIGHT = height * 0.33;
const OTHER_KEY = "~OTHER~";
const LONG_TEXT_LENGTH_AVG = 40;
const LONG_TEXT_LENGTH_MAX = 120;

// items parameter should be: [{value: 1, text: "Example"}, ...]
export default function Dropdown({
  value,
  onSelect,
  items = [],
  allowOther,
  small,
  header,
  placeholder,
  center,
  startOpen = false,
  // If enabled, let user type to search the dropdown
  enableAutocomplete = false,
  // If enabled and if the avg/max text value is really long, don't show the dropdown, just show a search bar
  enableSearchIfTextIsLong = false,
  onChangeText = () => {},
  onExpand = () => {},
  onCollapse = () => {},
  disabled = false,
  showAsOptional = false,
  flexibleWidth = false,
  headerStyle = {}
}) {
  const allItems = allowOther
    ? [...items, { text: "Other", value: OTHER_KEY }]
    : items;
  let textIsLong = false;
  if (enableSearchIfTextIsLong) {
    let maxTextLength = 0;
    const averageTextLength =
      allItems
        .map(({ text }) => {
          if (text.length > maxTextLength) maxTextLength = text.length;
          return text.length;
        })
        .reduce((a, b) => a + b) / allItems.length;
    if (
      maxTextLength > LONG_TEXT_LENGTH_MAX ||
      averageTextLength > LONG_TEXT_LENGTH_AVG
    )
      textIsLong = true;
  }
  const [visible, setVisible] = useState(startOpen);
  const [filterItems, setFilterItems] = useState(false);
  const [otherText, setOtherText] = useState("");
  const [freeFormText, setFreeFormText] = useState("");
  const selectedItem = allItems.find((item) => item.value === value);
  const valueIsUnknown = !selectedItem && !!value;
  const selectedItemIsOther =
    !!allowOther &&
    (valueIsUnknown || (selectedItem && selectedItem.value === OTHER_KEY));
  const selectedItemText =
    !!selectedItem && !selectedItemIsOther && selectedItem?.text;
  const placeholderValue =
    selectedItemText || placeholder || "Write your response here...";

  useEffect(() => {
    if (value) setOtherText(value === OTHER_KEY ? "" : value);
  }, [value]);

  useEffect(() => {
    if (visible) onExpand();
    else onCollapse();
  }, [visible]);

  const filteredItems =
    enableAutocomplete && filterItems
      ? Util.searchItems(allItems, freeFormText, "text")
      : allItems;

  let textToDisplay = selectedItem && selectedItem.text;
  if (selectedItemIsOther) textToDisplay = "Other";
  else if (valueIsUnknown) textToDisplay = "(other)";

  return (
    <View style={[styles.container, { opacity: disabled ? 0.3 : 1 }]}>
      {!!header && (
        <Text
          style={[
            Style.get("headerText"),
            { width: "100%", marginTop: 20 },
            headerStyle
          ]}
        >
          {header}
          {showAsOptional ? (
            <Text style={{ fontWeight: "normal", color: "#aaa" }}>
              {" "}
              (optional)
            </Text>
          ) : (
            ""
          )}
        </Text>
      )}
      <TouchableOpacity
        style={{ width: "100%" }}
        onPress={() => {
          if (!enableAutocomplete || Platform.OS !== "web")
            setVisible(!visible);
        }}
        disabled={disabled || (enableAutocomplete && Platform.OS !== "web")}
        activeOpacity={enableAutocomplete ? 1 : 0.6}
      >
        <View
          style={[
            styles.textContainer,
            enableAutocomplete ? { padding: 0 } : { padding: 7 },
            center ? { alignSelf: "center" } : {},
            flexibleWidth ? { width: "100%" } : {}
          ]}
        >
          <View style={{ flex: 5 }}>
            {enableAutocomplete ? (
              <TextInput
                style={enableAutocomplete ? { padding: 7 } : { padding: 0 }}
                placeholder={placeholderValue}
                value={freeFormText}
                onChangeText={(text) => {
                  setFreeFormText(text);
                  if (onChangeText) onChangeText(text);
                }}
                onFocus={() => {
                  // Don't show autocomplete if this is just a search bar
                  if (!textIsLong) {
                    setVisible(true);
                    setFilterItems(true);
                  }
                }}
                selectionColor={Rex.getConfig()?.colors?.primary}
              />
            ) : (
              <Text style={styles.text}>{textToDisplay}</Text>
            )}
          </View>
          <TouchableOpacity
            disabled={disabled || textIsLong}
            style={{
              flex: 1,
              alignItems: "flex-end",
              paddingRight: 7,
              maxWidth: 40
            }}
            onPress={() => {
              setVisible(!visible);
              setFilterItems(false);
            }}
          >
            {textIsLong ? (
              <Ionicons name="search" size={18} color="gray" />
            ) : (
              <Ionicons
                name={`chevron-${visible ? "up" : "down"}`}
                size={18}
                color="gray"
              />
            )}
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
      {visible && (
        <View
          style={[
            styles.dropdownContainer,
            center ? { alignSelf: "center" } : {},
            flexibleWidth ? { width: "100%" } : {}
          ]}
        >
          <ScrollView
            style={styles.dropdown}
            keyboardShouldPersistTaps="handled"
            nestedScrollEnabled
            scrollIndicatorInsets={{ right: 1 }}
          >
            {filteredItems.map((item) => {
              return (
                <TouchableOpacity
                  key={item.value}
                  activeOpacity={0.6}
                  style={[
                    styles.dropdownItem,
                    small ? styles.dropdownItemSmall : {},
                    item.value === value ? { backgroundColor: "#f8f8f8" } : {}
                  ]}
                  onPress={() => {
                    onSelect(item.value);
                    setFreeFormText(item.text);
                    setVisible(false);
                  }}
                >
                  <Text style={{ fontSize: 16 }}>{item.text}</Text>
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </View>
      )}
      {!visible && selectedItemIsOther && (
        <View style={{ width: "100%" }}>
          <TextInput
            style={[
              styles.textContainer,
              center ? { alignSelf: "center" } : {}
            ]}
            placeholder="Write your response here..."
            value={otherText}
            onChangeText={setOtherText}
            onBlur={() => {
              if (otherText) onSelect(otherText);
            }}
            selectionColor={Rex.getConfig()?.colors?.primary}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    width: "100%"
  },
  textContainer: {
    padding: 7,
    width: 0.82 * width,
    borderWidth: 1,
    borderColor: Glob.get("light gray"),
    borderRadius: 6,
    paddingLeft: 8,
    marginTop: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  text: {
    fontSize: 16,
    color: "black"
  },
  dropdownContainer: {
    width: 0.82 * width,
    marginTop: 8,
    alignSelf: "flex-start",
    maxHeight: DROPDOWN_CONTAINER_HEIGHT,
    borderColor: "#e4e4e4",
    borderRadius: 8,
    borderWidth: 1,
    marginBottom: 24
  },
  dropdown: {
    overflow: "hidden"
  },
  dropdownItem: {
    padding: 12,
    borderColor: "#F8F8F8",
    borderRadius: 8,
    borderBottomWidth: 1,
    backgroundColor: "white"
  },
  dropdownItemSmall: {
    paddingLeft: 10
  }
});
