import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  ScrollView,
  RefreshControl
} from "react-native";
import NavBar from "src/components/navBar";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Icon from "src/components/Icon";
import Glob from "src/globalConstants";
import TCLogoIcon from "src/components/TCLogoIcon";

const { width } = Glob.get("dimensions");

// Parse HTML content for lesson details using regex (more compatible with React Native)
const parseLessonDetails = (htmlContent) => {
  try {
    if (!htmlContent) return null;

    // Extract the lesson title - try multiple patterns that might be in the HTML
    let title = "";
    const titlePatterns = [
      /<span class="page-title">(.*?)<\/span>/s,
      /<h1[^>]*>(.*?)<\/h1>/s,
      /<title>(.*?)<\/title>/s
    ];

    titlePatterns.some((pattern) => {
      const match = htmlContent.match(pattern);
      if (match && match[1]) {
        title = match[1].trim();
        return true; // stop iteration
      }
      return false;
    });

    // Extract lesson type
    const typeMatch = htmlContent.match(/<dt>Type<\/dt>\s*<dd>(.*?)<\/dd>/s);
    const type = typeMatch ? typeMatch[1].trim() : "";

    // Extract image URL if present
    let imageUrl = null;
    // First try to find an img tag with style="width: 100%;"
    const imgMatch = htmlContent.match(
      /<img\s+style="width:\s*100%;\s*"\s+src="([^"]+)"/
    );
    if (imgMatch && imgMatch[1]) {
      const [, extractedUrl] = imgMatch;
      imageUrl = extractedUrl;

      // Remove AWS query parameters if needed
      const questionMarkIndex = imageUrl.indexOf("?");
      if (questionMarkIndex > 0) {
        imageUrl = imageUrl.substring(0, questionMarkIndex);
      }
    } else {
      // Try alternative patterns
      const altImgMatch = htmlContent.match(
        /lesson_pictures[^"]+\.(?:jpg|jpeg|png|gif)"/i
      );
      if (altImgMatch) {
        const matchedUrl = altImgMatch[0].replace(/"$/, "");
        imageUrl = `https://s3.amazonaws.com/transparentclassroom.com/schools/${matchedUrl}`;
      }
    }

    // Function to extract content from sections
    const extractSection = (sectionTitle) => {
      // Try to match different possible HTML structures
      const patterns = [
        // Standard pattern with h3 and ul
        new RegExp(`<h3>${sectionTitle}</h3>\\s*<ul>(.*?)</ul>`, "s"),
        // Alternative pattern with different heading level
        new RegExp(`<h[2-4]>${sectionTitle}</h[2-4]>\\s*<ul>(.*?)</ul>`, "s"),
        // Pattern for content that might not be in a list
        new RegExp(
          `<h3>${sectionTitle}</h3>(.*?)(?:<h|<div class="activity)`,
          "s"
        )
      ];

      // Use reduce instead of for...of loop to find the first matching pattern
      return patterns.reduce((result, pattern) => {
        // If we already have a result, return it
        if (result.length) return result;

        const sectionMatch = htmlContent.match(pattern);
        if (sectionMatch && sectionMatch[1]) {
          const listContent = sectionMatch[1];

          // Try to extract list items
          if (listContent.includes("<li>")) {
            const listItemsMatches = listContent.matchAll(/<li>(.*?)<\/li>/gs);
            return Array.from(listItemsMatches, (match) => {
              // Clean up the text by removing HTML tags
              return match[1].trim().replace(/<[^>]*>/g, "");
            });
          }
          // If there are no list items, try to extract paragraphs
          const paragraphs = listContent
            .split(/<p>|<br\s*\/?>/)
            .filter(Boolean)
            .map((p) => p.replace(/<[^>]*>/g, "").trim())
            .filter((p) => p.length > 0);

          if (paragraphs.length > 0) {
            return paragraphs;
          }
        }
        return result;
      }, []);
    };

    // TODO: Lesson descriptions are fully editable, so we need to use the full description (e.g. look for “h3” as the title)
    // Extract sections - also look for alternate headings like "How to help at home?"
    const whatIsIt = extractSection("What is it\\?");
    const whyDoWe = extractSection("Why do we do it\\?");
    const supporting =
      extractSection("Supporting interest at home:") ||
      extractSection("How to help at home\\?");

    return {
      title,
      type,
      imageUrl,
      whatIsIt,
      whyDoWe,
      supporting
    };
  } catch (error) {
    console.error("Error parsing HTML:", error);
    return {
      title: "",
      type: "",
      imageUrl: null,
      whatIsIt: [],
      whyDoWe: [],
      supporting: []
    };
  }
};

export default function TCLessonDetails({ route, navigation }) {
  const { lessonSetID, lessonID } = route.params;
  const [lessonData, setLessonData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    Analytics.logEvent("view_tcLessonDetails");
    fetchLessonDetails();
  }, []);

  const fetchLessonDetails = async (isRefreshing = false) => {
    try {
      if (!isRefreshing) {
        setLoading(true);
      }

      const response = await Database.tcFetchLessonDetailsHTML(
        lessonSetID,
        lessonID
      );
      const { success, data } = response || {};

      if (success && data) {
        const parsedData = parseLessonDetails(data);
        console.log("Image URL found:", parsedData.imageUrl);
        setLessonData(parsedData);
        setError(null);
        setImageError(false);
      } else {
        setError("Failed to load lesson details");
      }
    } catch (err) {
      setError("An error occurred while loading lesson details");
      console.error(err);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const onRefresh = () => {
    setRefreshing(true);
    fetchLessonDetails(true);
  };

  // For testing - you can set this to a specific URL to test image loading
  const testImageUrl = null; // "https://s3.amazonaws.com/transparentclassroom.com/schools/2232/2019/lesson_pictures/925367bff31fa4e682c969a13445e28ed1eed1d7.original.jpeg";

  // Section component for consistency
  const SectionComponent = ({ title, items }) => (
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>{title}</Text>
      {items && items.length > 0 ? (
        items.map((item, index) => (
          <View key={index} style={styles.listItemContainer}>
            <View
              style={[
                styles.bullet,
                { backgroundColor: Rex.getConfig()?.colors?.button }
              ]}
            />
            <Text style={styles.listItemText}>{item}</Text>
          </View>
        ))
      ) : (
        <Text style={styles.emptyListText}>No information available</Text>
      )}
    </View>
  );

  const renderContent = () => {
    if (loading && !refreshing)
      return (
        <View style={styles.loadingContainer}>
          <TCLogoIcon size={40} spinning />
          <Text style={styles.loadingText}>Loading lesson details...</Text>
        </View>
      );
    if (error)
      return (
        <View style={styles.errorContainer}>
          <Icon name="error-outline" size={48} color="#e74c3c" />
          <Text style={styles.errorText}>{error}</Text>
        </View>
      );
    if (lessonData)
      return (
        <View style={styles.contentContainer}>
          <Text style={styles.title}>
            {lessonData.title || "Lesson Details"}
          </Text>

          {/* Display image if available or use test image for debugging */}
          {(lessonData.imageUrl || testImageUrl) && !imageError ? (
            <View style={styles.imageContainer}>
              <Image
                source={{ uri: testImageUrl || lessonData.imageUrl }}
                style={styles.lessonImage}
                resizeMode="contain"
                onError={(e) => {
                  console.error("Image failed to load:", e.nativeEvent.error);
                  setImageError(true);
                }}
              />
            </View>
          ) : null}

          {lessonData.type ? (
            <View style={styles.typeContainer}>
              <Text style={styles.typeLabel}>Type:</Text>
              <Text
                style={[
                  styles.typeValue,
                  { color: Rex.getConfig()?.colors?.button }
                ]}
              >
                {lessonData.type}
              </Text>
            </View>
          ) : null}

          <SectionComponent title="What is it?" items={lessonData.whatIsIt} />
          <SectionComponent
            title="Why do we do it?"
            items={lessonData.whyDoWe}
          />
          <SectionComponent
            title="Supporting interest at home:"
            items={lessonData.supporting}
          />
        </View>
      );
    return null;
  };

  return (
    <View style={styles.container}>
      <NavBar navigation={navigation} text="Lesson Details" />

      <ScrollView
        style={styles.scrollView}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        {renderContent()}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#fff"
  },
  scrollView: {
    flex: 1,
    width
  },
  loadingContainer: {
    flex: 1,
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
    minHeight: 300
  },
  loadingText: {
    marginTop: 10,
    fontSize: 16,
    color: "#555"
  },
  errorContainer: {
    flex: 1,
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
    minHeight: 300
  },
  errorText: {
    marginTop: 10,
    fontSize: 16,
    color: "#e74c3c",
    textAlign: "center"
  },
  contentContainer: {
    padding: 20
  },
  title: {
    fontSize: 24,
    fontWeight: "700",
    color: "#333",
    marginBottom: 16
  },
  imageContainer: {
    marginBottom: 20,
    borderRadius: 8,
    overflow: "hidden",
    backgroundColor: "#f8f9fa"
  },
  lessonImage: {
    width: "100%",
    height: 220,
    backgroundColor: "#f8f9fa"
  },
  typeContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
    backgroundColor: "#f8f9fa",
    padding: 12,
    borderRadius: 8
  },
  typeLabel: {
    fontSize: 15,
    fontWeight: "600",
    color: "#555",
    marginRight: 8
  },
  typeValue: {
    fontSize: 15,
    color: "#333",
    fontWeight: "500"
  },
  section: {
    marginBottom: 20
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#333",
    marginBottom: 10,
    paddingBottom: 6,
    borderBottomWidth: 1,
    borderBottomColor: "#f0f0f0"
  },
  listItemContainer: {
    flexDirection: "row",
    marginBottom: 8,
    paddingRight: 10
  },
  bullet: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: "#333",
    marginTop: 8,
    marginRight: 10
  },
  listItemText: {
    flex: 1,
    fontSize: 15,
    lineHeight: 22,
    color: "#444"
  },
  emptyListText: {
    fontSize: 15,
    color: "#888",
    fontStyle: "italic",
    marginLeft: 10
  }
});
