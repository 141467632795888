import React, { useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, Platform } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import UserList from "src/components/UserList";
import Button from "src/components/Button";
import TCLogoIcon from "src/components/TCLogoIcon";
import Glob from "src/globalConstants";
import AlertModal from "src/components/AlertModal";

const { width } = Glob.get("dimensions");
const ICON_SIZE = Math.min(
  0.08 * width,
  Glob.deviceHasTabletDimensions() ? 42 : 34
);

export default function ManageUsers({ navigation }) {
  const [showShareOptions, setShowShareOptions] = useState(true);
  const [syncingTCUsers, setSyncingTCUsers] = useState(false);
  const [toggleToRefresh, setToggleToRefresh] = useState(false);
  const [tcIntegrationConnected, setTCIntegrationConnected] = useState(false);
  const [alert, setAlert] = useState(null);

  const syncTCUsers = (showAlert = false) => {
    setSyncingTCUsers(true);
    Database.tcLinkUserAccounts().then((response) => {
      const { success, data } = response;
      if (success) {
        const { newlyLinkedTCUsers } = data;
        const numberOfNewlyLinkedUsers = newlyLinkedTCUsers.length;
        if (numberOfNewlyLinkedUsers > 0) {
          setToggleToRefresh((prev) => !prev);
          const plural = numberOfNewlyLinkedUsers > 1;
          setAlert({
            title: "Transparent Classroom user accounts linked 👍",
            message: `We just automatically linked ${numberOfNewlyLinkedUsers} additional Transparent Classroom user account${
              plural ? "s" : ""
            } with ${plural ? "your" : "an"} existing Onespot member${
              plural ? "s" : ""
            }.`
          });
        } else if (showAlert) {
          setAlert({
            title: "All Transparent Classroom user accounts are linked 👍",
            message:
              "All relevant Transparent Classroom user accounts are linked with your Onespot member accounts."
          });
        }
      }
      setSyncingTCUsers(false);
    });
  };

  useEffect(() => {
    Analytics.logEvent("view_manageUsers");
    Database.addTask("viewAllMembers", "done");
    Database.tcCheckIntegrationStatus().then((response) => {
      const { status } = response || {};
      const connected = status === Glob.get("tcIntegrationStatuses").CONNECTED;
      setTCIntegrationConnected(connected);
      if (connected) syncTCUsers();
    });
  }, []);

  const bottomContainerStyle = {
    position: Platform.OS === "web" ? "fixed" : "absolute",
    bottom: 0,
    paddingTop: 24,
    paddingBottom: 32,
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: "white",
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: -5 },
    shadowRadius: 3,
    elevation: 20,
    width: "100%"
  };
  if (Platform.OS === "web")
    bottomContainerStyle.boxShadow = "0px -5px 3px rgba(0,0,0,0.15)";

  return (
    <View style={styles.pageContent}>
      <UserList
        key={`manageUsers-${toggleToRefresh}`}
        navBarTitle="All Members"
        navigation={navigation}
        navBarRightButton={
          tcIntegrationConnected && (
            <TouchableOpacity
              style={{
                width: "100%",
                height: "100%",
                alignItems: "flex-end",
                justifyContent: "center",
                paddingRight: 10
              }}
              activeOpacity={0.6}
              onPress={() => syncTCUsers(true)}
            >
              <View
                style={{
                  width: ICON_SIZE,
                  height: ICON_SIZE,
                  backgroundColor: "#f0f0f0",
                  borderRadius: ICON_SIZE / 2,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <TCLogoIcon size={ICON_SIZE - 10} spinning={syncingTCUsers} />
              </View>
            </TouchableOpacity>
          )
        }
      />
      {showShareOptions && (
        <View style={bottomContainerStyle}>
          <Button
            text="Quick share"
            icon="8a4a8b06-5206-4c53-9d17-5925616a0ec0" // share
            small
            textStyle={{ fontWeight: "bold" }}
            outline
            onPress={() => navigation.push("shareApp")}
          />
          <Button
            text="Send invites"
            icon="send"
            small
            textStyle={{ fontWeight: "bold" }}
            onPress={() => navigation.push("inviteUsers")}
          />
          <TouchableOpacity
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: 7,
              justifyContent: "center",
              alignItems: "center"
            }}
            activeOpacity={0.6}
            onPress={() => {
              setShowShareOptions(false);
              Analytics.logEvent("touch_manageUsers_hideShareOptions");
            }}
          >
            <MaterialCommunityIcons
              name="window-close"
              size={24}
              color="gray"
            />
          </TouchableOpacity>
        </View>
      )}
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  }
});
