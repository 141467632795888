import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  Image,
  Animated,
  ActivityIndicator
} from "react-native";
import * as Haptics from "expo-haptics";
import { Box } from "native-base";
import LottieView from "src/components/Lottie";
import Rex from "src/globalState";
import Firebase from "src/backend/firebase";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Util from "src/utility";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import ButtonSwitches from "src/components/buttonSwitches";
import InputBox from "src/components/InputBox";
import HelpButton from "src/components/HelpButton";
import Button from "src/components/Button";
import AlertModal from "src/components/AlertModal";
import Checkbox from "src/components/Checkbox";
import HelpText from "src/components/HelpText";
import MiniScreen from "src/screens/admin/miniScreen";
import TCLogoIcon from "src/components/TCLogoIcon";

const APP_MAKING_PEOPLE_ANIMATION = require("resources/animations/appMakingPeople.json");
const SENDING_ANIMATION = require("resources/animations/sendingImage.json");

const { height, width } = Glob.get("dimensions");

export default function EditGlobalConfig({ navigation }) {
  // const [isSubmitted, setIsSubmitted] = useState(false);
  const [config, setConfig] = useState(null);
  const [alert, setAlert] = useState(null);
  const [logoURL, setLogoURL] = useState(null);
  const [logoURLNotFound, setLogoURLNotFound] = useState(null);
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [backgroundURL, setBackgroundURL] = useState(null);
  const [backgroundURLNotFound, setBackgroundURLNotFound] = useState(null);
  const [isUploadingBackground, setIsUploadingBackground] = useState(false);
  const [navbarURL, setNavbarURL] = useState(null);
  const [navbarURLNotFound, setNavbarURLNotFound] = useState(null);
  const [isUploadingNavbar, setIsUploadingNavbar] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [appStoreLinks, setAppStoreLinks] = useState(
    Glob.get("onespotAppStoreLinks")
  );
  const [phoneNumberFieldStatus, setPhoneNumberFieldStatus] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_editGlobalConfig");
    Database.addTask("viewAppDesign", "done");
    setConfig(Rex.getConfig());
    Firebase.getMediaURLAsync("logo.png")
      .then(setLogoURL)
      .catch(() => setLogoURLNotFound(true));
    Firebase.getMediaURLAsync("background.png")
      .then(setBackgroundURL)
      .catch(() => setBackgroundURLNotFound(true));
    Firebase.getMediaURLAsync("navbar.png")
      .then(setNavbarURL)
      .catch(() => setNavbarURLNotFound(true));
    Database.fetchAppStoreInfo().then((info) => {
      if (info?.appLinks) setAppStoreLinks(info?.appLinks);
    });
    Database.fetchUserAccountFields().then(({ fields }) => {
      if (!fields?.phoneNumber) setPhoneNumberFieldStatus("off");
      else if (!fields.phoneNumber.required)
        setPhoneNumberFieldStatus("optional");
      else setPhoneNumberFieldStatus("required");
    });
  }, []);

  if (!config)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="App Settings & Design" />
        <View style={styles.errorContainer}>
          <ActivityIndicator size="large" />
        </View>
      </View>
    );

  // This should only happen if the /content/globalConfig node is incorrectly formatted in the database
  if (
    !(
      "colors" in config &&
      "navbar" in config.colors &&
      "primary" in config.colors
    )
  )
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="App Settings & Design" />
        <View style={styles.errorContainer}>
          <Text style={{ fontSize: 100, marginTop: 50 }}>🤔</Text>
          <Text style={styles.errorTextLarge}>Hmmm...</Text>
          <Text style={{ color: "gray" }}>
            It looks like you don't yet have the ability to update the overall
            app design & settings. Reach out to someone at Seabird Apps for
            help! You can find contact info in the "Help Me" superpower.
          </Text>
        </View>
      </View>
    );

  const onSubmitResponse = () => {
    if (Platform.OS !== "web")
      Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    Analytics.logEvent("touch_editGlobalConfig_submit", config);
    Database.setGlobalConfig(config);
    // Update the phone number user account field
    Database.fetchUserAccountFields().then(({ fields }) => {
      const { phoneNumber, ...phonelessFields } = fields;
      if (phoneNumberFieldStatus === "off") {
        Database.updateUserAccountFields(phonelessFields);
      } else if (phoneNumberFieldStatus === "optional") {
        Database.updateUserAccountFields({
          ...fields,
          phoneNumber: { required: false, title: "Phone Number" }
        });
      } else if (phoneNumberFieldStatus === "required") {
        Database.updateUserAccountFields({
          ...fields,
          phoneNumber: { required: true, title: "Phone Number" }
        });
      }
    });
    if (config.names.full) Database.setAppMetadata({ name: config.names.full });
    if (Database.userIsSuperAdmin()) Database.setAppStoreLinks(appStoreLinks);
    setAlert({
      title: "App settings updated 👍",
      confirm: { text: "Reload App", onPress: () => Util.reloadApp() },
      disableOutsideTouch: true
    });
    // todo: ideally, update the local content rather than making them reload the app
    // Rex.setConfig(config);
    // setIsSubmitted(true);
    // checkRefresh();
  };

  // type = "logo", "background", or "navbar"
  const uploadImage = (type) => {
    Util.pickMedia({ type: "image" }).then((uri) => {
      if (type === "logo") {
        setLogoURLNotFound(false);
        setIsUploadingLogo(true);
      } else if (type === "background") {
        setBackgroundURLNotFound(false);
        setIsUploadingBackground(true);
      } else if (type === "navbar") {
        setNavbarURLNotFound(false);
        setIsUploadingNavbar(true);
      }
      Firebase.uploadMedia(uri, {
        locationName: `${type}.png`,
        onProgressUpdate: setUploadProgress
      }).then((image) => {
        if (type === "logo") {
          setLogoURL(image);
          setIsUploadingLogo(false);
          setUploadProgress(0);
        } else if (type === "background") {
          setBackgroundURL(image);
          setIsUploadingBackground(false);
        } else if (type === "navbar") {
          setNavbarURL(image);
          setIsUploadingNavbar(false);
        }
      });
    });
  };

  const deleteImage = (type) => {
    Firebase.deleteImage(`${type}.png`).then(() => {
      if (type === "logo") {
        setLogoURLNotFound(true);
        setLogoURL(null);
      } else if (type === "background") {
        setBackgroundURLNotFound(true);
        setBackgroundURL(null);
      } else if (type === "navbar") {
        setNavbarURLNotFound(true);
        setNavbarURL(null);
      }
    });
  };

  const onPressDeleteImage = (type) => {
    setAlert({
      title: "Heads up!",
      message: `Are you sure you want to delete the ${type}?`,
      confirm: { text: "💥 Remove", onPress: () => deleteImage(type) },
      cancel: { text: "✋ Cancel" }
    });
  };

  let helpTextSuffix = "your app won't ask them for their phone number.";
  if (phoneNumberFieldStatus === "optional")
    helpTextSuffix =
      "they will be asked for their phone number, but it will be optional.";
  else if (phoneNumberFieldStatus === "required")
    helpTextSuffix = "they will be required to provide their phone number.";
  const helpText = `When a new member creates an account, ${helpTextSuffix}`;

  const headerStyle = {
    ...Style.get("headerText"),
    color: config.colors.text
  };

  // if (isSubmitted)
  //   return (
  //     <View style={styles.pageContent}>
  //       <NavBar navigation={navigation} text="App Design" />
  //       <View style={{ flex: 1, alignItems: "center", margin: 30 }}>
  //         <Text style={[headerStyle, { fontSize: 22 }]}>
  //           Your app's design has been updated!
  //         </Text>
  //       </View>
  //     </View>
  //   );

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar
        navigation={navigation}
        text="App Settings & Design"
        backgroundColor={config.colors.navbar}
        RightButton={
          <View style={{ alignItems: "flex-end" }}>
            <HelpButton
              title="App Settings & Design"
              message="On this screen, you can make high-level changes that will affect your entire app."
              navigation={navigation}
              videoUrl="https://youtu.be/ie-S8Fyw0L8"
            />
          </View>
        }
      />

      <ScrollView
        style={{ paddingHorizontal: 15, width }}
        scrollIndicatorInsets={{ right: 1 }}
        keyboardDismissMode="on-drag"
      >
        <View style={{ height: height / 2, alignItems: "center" }}>
          <LottieView
            autoPlay
            loop={false}
            speed={0.2}
            style={{ height: "100%", width: "100%" }}
            source={APP_MAKING_PEOPLE_ANIMATION}
          />
        </View>
        <Text style={Style.get("headerText")}>⚠️ Heads up!</Text>
        <Text style={styles.description}>
          Changing these values will affect your entire app. If you're confused
          about any of this, please don't hesitate to reach out for help:
        </Text>

        <Button text="Help Me" onPress={() => navigation.push("help")} small />

        <View style={{ marginTop: 20 }}>
          <HelpText text="Make sure to save your changes by pressing the button at the very bottom of the screen." />
        </View>

        <InputBox
          header="App Name"
          onChangeText={(full) =>
            setConfig({ ...config, names: { ...config.names, full } })
          }
          value={config.names.full}
          headerColorOverride={config.colors.text}
        />

        <InputBox
          header="App Nickname"
          description="This is sometimes used instead of the full name"
          onChangeText={(nickname) =>
            setConfig({ ...config, names: { ...config.names, nickname } })
          }
          value={config.names.nickname}
          headerColorOverride={config.colors.text}
        />

        <InputBox
          header="Short Description"
          description="Describe your app/organization in under 300 characters"
          onChangeText={(descriptionLong) =>
            setConfig({ ...config, descriptionLong })
          }
          multiline
          maxLength={300}
          value={config.descriptionLong}
          headerColorOverride={config.colors.text}
        />

        <View style={{ marginTop: 0.03 * height }} key="text">
          <Text style={headerStyle}>Logo</Text>
          {logoURLNotFound ? (
            <View>
              <Button text="Upload Logo" onPress={() => uploadImage("logo")} />
            </View>
          ) : (
            <View style={{ alignItems: "center" }}>
              {isUploadingLogo ? (
                <View style={{ paddingBottom: 10, alignItems: "center" }}>
                  <LottieView
                    autoPlay
                    loop
                    style={styles.logo}
                    source={SENDING_ANIMATION}
                  />
                  <Text style={Style.get("headerText")}>
                    {uploadProgress}% uploaded...
                  </Text>
                </View>
              ) : (
                <Image source={{ uri: logoURL }} style={styles.logo} />
              )}
              <Button
                text="Change Logo"
                small
                onPress={() => uploadImage("logo")}
              />
              <Button
                text="Remove Logo"
                small
                style={{ backgroundColor: Glob.get("dangerRed") }}
                onPress={() => onPressDeleteImage("logo")}
              />
            </View>
          )}
        </View>

        <InputBox
          header="Primary Color"
          onChangeText={(primary) =>
            setConfig({ ...config, colors: { ...config.colors, primary } })
          }
          value={config.colors.primary}
          colorPicker
          headerColorOverride={config.colors.text}
        />

        <InputBox
          header="App Background Color"
          onChangeText={(background) =>
            setConfig({ ...config, colors: { ...config.colors, background } })
          }
          value={config.colors.background}
          colorPicker
          headerColorOverride={config.colors.text}
        />

        <View style={{ marginTop: 0.03 * height }} key="text">
          <Text style={headerStyle}>App Background Image</Text>
          {backgroundURLNotFound ? (
            <View>
              <Button
                text="Upload Background Image"
                onPress={() => uploadImage("background")}
              />
            </View>
          ) : (
            <View style={{ alignItems: "center" }}>
              {isUploadingBackground ? (
                <View style={{ paddingBottom: 10, alignItems: "center" }}>
                  <LottieView
                    autoPlay
                    loop
                    style={styles.logo}
                    source={SENDING_ANIMATION}
                  />
                  <Text style={Style.get("headerText")}>
                    {uploadProgress}% uploaded...
                  </Text>
                </View>
              ) : (
                <Image source={{ uri: backgroundURL }} style={styles.logo} />
              )}
              <Button
                text="Change Background"
                small
                onPress={() => uploadImage("background")}
              />
              <Button
                text="Remove Background"
                small
                style={{ backgroundColor: Glob.get("dangerRed") }}
                onPress={() => onPressDeleteImage("background")}
              />
            </View>
          )}
        </View>

        <InputBox
          header="Navigation Bar Color"
          onChangeText={(navbar) =>
            setConfig({ ...config, colors: { ...config.colors, navbar } })
          }
          value={config.colors.navbar}
          colorPicker
          headerColorOverride={config.colors.text}
        />

        <View style={{ marginTop: 0.03 * height }} key="text">
          <Text style={headerStyle}>Navigation Bar Image</Text>
          <Text>
            If you set this, this image will be shown at the top of your home
            screen (instead of your app's name)
          </Text>
          {navbarURLNotFound ? (
            <View>
              <Button
                text="Upload Nav Bar Image"
                onPress={() => uploadImage("navbar")}
              />
            </View>
          ) : (
            <View style={{ alignItems: "center" }}>
              {isUploadingNavbar ? (
                <View style={{ paddingBottom: 10, alignItems: "center" }}>
                  <LottieView
                    autoPlay
                    loop
                    style={styles.logo}
                    source={SENDING_ANIMATION}
                  />
                  <Text style={Style.get("headerText")}>
                    {uploadProgress}% uploaded...
                  </Text>
                </View>
              ) : (
                <Image source={{ uri: navbarURL }} style={styles.logo} />
              )}
              <Button
                text="Change Navigation Bar"
                small
                onPress={() => uploadImage("navbar")}
              />
              <Button
                text="Remove Navigation Bar"
                small
                style={{ backgroundColor: Glob.get("dangerRed") }}
                onPress={() => onPressDeleteImage("navbar")}
              />
            </View>
          )}
        </View>

        <InputBox
          header="Text Header Color"
          onChangeText={(text) =>
            setConfig({ ...config, colors: { ...config.colors, text } })
          }
          value={config.colors.text}
          colorPicker
          headerColorOverride={config.colors.text}
        />

        <InputBox
          header="Button Color"
          onChangeText={(button) =>
            setConfig({ ...config, colors: { ...config.colors, button } })
          }
          value={config.colors.button}
          colorPicker
          headerColorOverride={config.colors.text}
        />

        <InputBox
          header="Portal Color"
          onChangeText={(portal) =>
            setConfig({ ...config, colors: { ...config.colors, portal } })
          }
          value={config.colors.portal}
          colorPicker
          headerColorOverride={config.colors.text}
        />

        <View style={{ marginTop: 0.03 * height }} key="text">
          <Text style={headerStyle}>Portal Design</Text>
          <ButtonSwitches
            color={config?.colors?.button}
            initialButtonState={
              config.portalShape === "square" ? "right" : "left"
            }
            leftOption="Circles"
            rightOption="Blocks"
            leftAction={() => setConfig({ ...config, portalShape: "circle" })}
            rightAction={() => setConfig({ ...config, portalShape: "square" })}
          />
        </View>

        <View style={{ marginTop: 0.03 * height }} key="text">
          <Text style={headerStyle}>Show Search Bar?</Text>
          <ButtonSwitches
            color={config?.colors?.button}
            initialButtonState={config.searchBarEnabled ? "right" : "left"}
            leftOption="Hide"
            rightOption="Show"
            leftAction={() => setConfig({ ...config, searchBarEnabled: false })}
            rightAction={() => setConfig({ ...config, searchBarEnabled: true })}
          />
        </View>

        <Text style={[headerStyle, { marginTop: 0.03 * height }]}>Preview</Text>
        <Box p="4" bg="white" shadow={8} borderRadius="md">
          <View
            style={{
              height: 0.6 * height,
              width: "100%"
            }}
          >
            <Animated.View
              style={{
                height,
                width,
                alignSelf: "center",
                borderColor: "#CCC",
                borderWidth: 2,
                elevation: 7,
                transform: [{ scale: 0.6 }, { translateY: -0.33 * height }]
              }}
              pointerEvents="none"
            >
              <MiniScreen
                key="miniscreen"
                navigation={navigation}
                portalType="root"
                navName="root"
                contentTitle="Test Title"
                globalConfigOverride={config}
              />
            </Animated.View>
          </View>
        </Box>

        <View style={{ marginTop: 0.03 * height }} key="text">
          <Text style={headerStyle}>Ask Users for Phone Numbers?</Text>
          <Checkbox
            checked={phoneNumberFieldStatus === "required"}
            radio
            text={
              <Text>
                Phone numbers are{" "}
                <Text style={{ fontWeight: "bold" }}>required</Text>
              </Text>
            }
            onChange={() => setPhoneNumberFieldStatus("required")}
          />
          <Checkbox
            checked={phoneNumberFieldStatus === "optional"}
            radio
            text={
              <Text>
                Phone numbers are{" "}
                <Text style={{ fontWeight: "bold" }}>optional</Text>
              </Text>
            }
            onChange={() => setPhoneNumberFieldStatus("optional")}
          />
          <Checkbox
            checked={phoneNumberFieldStatus === "off"}
            radio
            text={
              <Text>
                <Text style={{ fontWeight: "bold" }}>Don't ask</Text> users for
                phone numbers
              </Text>
            }
            onChange={() => setPhoneNumberFieldStatus("off")}
          />
          <HelpText text={helpText} />
        </View>

        {!!config.transparentClassroomIntegrationEnabled && (
          <View style={{ marginTop: 0.03 * height }} key="tcSSO">
            <Text style={[headerStyle, { marginTop: 10 }]}>
              Transparent Classroom SSO <TCLogoIcon size={11} />
            </Text>
            <Text>
              Enable a "Sign in with Transparent Classroom" option for new
              members joining your app.
            </Text>
            <ButtonSwitches
              color={config?.colors?.button}
              initialButtonState={
                !config?.transparentClassroomConfig?.ssoDisabled
                  ? "right"
                  : "left"
              }
              leftOption="Off"
              rightOption="Enabled"
              leftAction={() =>
                setConfig({
                  ...config,
                  transparentClassroomConfig: {
                    ...(config.transparentClassroomConfig || {}),
                    ssoDisabled: true
                  }
                })
              }
              rightAction={() =>
                setConfig({
                  ...config,
                  transparentClassroomConfig: {
                    ...(config.transparentClassroomConfig || {}),
                    ssoDisabled: false
                  }
                })
              }
            />
          </View>
        )}

        <View style={{ marginTop: 0.03 * height }} key="text">
          <Text style={headerStyle}>Advanced: Enable Public Web Pages?</Text>
          <Text>
            When viewing your app on web (www.1spot.app), you'll be able to
            share a link to any screen as a public web page.
          </Text>
          <ButtonSwitches
            color={config?.colors?.button}
            initialButtonState={config.publicPagesEnabled ? "right" : "left"}
            leftOption="Off"
            rightOption="Enabled"
            leftAction={() =>
              setConfig({ ...config, publicPagesEnabled: false })
            }
            rightAction={() =>
              setConfig({ ...config, publicPagesEnabled: true })
            }
          />
        </View>

        <InputBox
          header="Advanced: Terms of Service URL"
          description={`If you have a specific "Terms of Service" page you want users to agree to when they join your app (in addition to Onespot's Terms of Service), you can link it here.`}
          onChangeText={(termsOfServiceLink) =>
            setConfig({ ...config, termsOfServiceLink })
          }
          value={config.termsOfServiceLink}
          headerColorOverride={config.colors.text}
        />

        <View style={{ marginTop: 0.03 * height, marginBottom: 0.03 * height }}>
          <Button text="Save Changes" onPress={onSubmitResponse} />
        </View>

        {Database.userIsSuperAdmin() && (
          <>
            <View
              style={{
                height: 100,
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
              }}
            >
              <View
                style={{
                  height: 8,
                  width: "100%",
                  backgroundColor: Glob.get("dangerRed")
                }}
              />
            </View>
            <Text
              style={[
                headerStyle,
                {
                  textAlign: "center",
                  fontSize: 24,
                  color: Glob.get("dangerRed")
                }
              ]}
            >
              ↓ 🕶 Super Admins Only ↓
            </Text>
            <Text
              style={[
                Style.get("subheaderText"),
                { textAlign: "center", color: Glob.get("dangerRed") }
              ]}
            >
              Everything below is only visible to super admins.{"\n"}(Don't
              forget to save changes)
            </Text>

            <View style={{ marginTop: 0.03 * height }} key="text">
              <Text style={[headerStyle, { marginTop: 10, marginBottom: -10 }]}>
                💬 Chat
              </Text>
              <ButtonSwitches
                color={config?.colors?.button}
                initialButtonState={config.chatEnabled ? "right" : "left"}
                leftOption="Off"
                rightOption="Enabled"
                leftAction={() => setConfig({ ...config, chatEnabled: false })}
                rightAction={() => setConfig({ ...config, chatEnabled: true })}
              />
            </View>

            <View style={{ marginTop: 0.03 * height }} key="text">
              <Text style={[headerStyle, { marginTop: 10 }]}>
                💡 Advanced Components
              </Text>
              <Text>
                Data Feeds (Google Sheets), Embedded HTML, App Switcher buttons,
                Accordion Lists, and Link Lists.
              </Text>
              <ButtonSwitches
                color={config?.colors?.button}
                initialButtonState={
                  config.advancedComponentsEnabled ? "right" : "left"
                }
                leftOption="Off"
                rightOption="Enabled"
                leftAction={() =>
                  setConfig({ ...config, advancedComponentsEnabled: false })
                }
                rightAction={() =>
                  setConfig({ ...config, advancedComponentsEnabled: true })
                }
              />
            </View>

            <View style={{ marginTop: 0.03 * height }} key="text">
              <Text style={[headerStyle, { marginTop: 10 }]}>
                🔐 Granular Security & Permissions
              </Text>
              <Text>
                If enabled, admins can lock account types, restrict portals by
                account type, limit the notification permissions for specific
                users, and restrict which account types can post/comment in
                Community portals.
              </Text>
              <ButtonSwitches
                color={config?.colors?.button}
                initialButtonState={
                  config.granularSecurityEnabled ? "right" : "left"
                }
                leftOption="Off"
                rightOption="Enabled"
                leftAction={() =>
                  setConfig({ ...config, granularSecurityEnabled: false })
                }
                rightAction={() =>
                  setConfig({ ...config, granularSecurityEnabled: true })
                }
              />
            </View>

            <View style={{ marginTop: 0.03 * height }} key="text">
              <Text style={[headerStyle, { marginTop: 10, marginBottom: -10 }]}>
                📞 Phone Communication
              </Text>
              <ButtonSwitches
                color={config?.colors?.button}
                initialButtonState={
                  config.textMessagingEnabled ? "right" : "left"
                }
                leftOption="Off"
                rightOption="Texting"
                leftAction={() =>
                  setConfig({ ...config, textMessagingEnabled: false })
                }
                rightAction={() =>
                  setConfig({ ...config, textMessagingEnabled: true })
                }
              />
              <ButtonSwitches
                color={config?.colors?.button}
                initialButtonState={config.phoneCallsEnabled ? "right" : "left"}
                leftOption="Off"
                rightOption="Calling"
                leftAction={() =>
                  setConfig({ ...config, phoneCallsEnabled: false })
                }
                rightAction={() =>
                  setConfig({ ...config, phoneCallsEnabled: true })
                }
              />
            </View>

            <View style={{ marginTop: 0.03 * height }} key="text">
              <Text style={[headerStyle, { marginTop: 10, marginBottom: -10 }]}>
                📈 Analytics
              </Text>
              <ButtonSwitches
                color={config?.colors?.button}
                initialButtonState={config.analyticsEnabled ? "right" : "left"}
                leftOption="Off"
                rightOption="Enabled"
                leftAction={() =>
                  setConfig({ ...config, analyticsEnabled: false })
                }
                rightAction={() =>
                  setConfig({ ...config, analyticsEnabled: true })
                }
              />
            </View>

            <View style={{ marginTop: 0.03 * height }} key="text">
              <Text style={[headerStyle, { marginTop: 10, marginBottom: -10 }]}>
                🕓 Scheduled Notifications
              </Text>
              <ButtonSwitches
                color={config?.colors?.button}
                initialButtonState={
                  config.scheduledNotificationsEnabled ? "right" : "left"
                }
                leftOption="Off"
                rightOption="Enabled"
                leftAction={() =>
                  setConfig({ ...config, scheduledNotificationsEnabled: false })
                }
                rightAction={() =>
                  setConfig({ ...config, scheduledNotificationsEnabled: true })
                }
              />
            </View>

            <View style={{ marginTop: 0.03 * height }} key="text">
              <Text style={[headerStyle, { marginTop: 10, marginBottom: -10 }]}>
                🛒 Onespot Payments
              </Text>
              <ButtonSwitches
                color={config?.colors?.button}
                initialButtonState={config.commerceEnabled ? "right" : "left"}
                leftOption="Off"
                rightOption="Enabled"
                leftAction={() =>
                  setConfig({ ...config, commerceEnabled: false })
                }
                rightAction={() =>
                  setConfig({ ...config, commerceEnabled: true })
                }
              />
            </View>

            <View style={{ marginTop: 0.03 * height }} key="text">
              <Text
                style={[
                  headerStyle,
                  {
                    marginTop: 10,
                    marginBottom: Glob.get("appIsDevelopmentMode") ? -10 : 0
                  }
                ]}
              >
                💵 Onespot Billing
              </Text>
              {Glob.get("appIsDevelopmentMode") ? (
                <ButtonSwitches
                  color={config?.colors?.button}
                  initialButtonState={
                    config.commerceBillingEnabled ? "right" : "left"
                  }
                  leftOption="Off"
                  rightOption="Enabled"
                  leftAction={() =>
                    setConfig({ ...config, commerceBillingEnabled: false })
                  }
                  rightAction={() =>
                    setConfig({ ...config, commerceBillingEnabled: true })
                  }
                />
              ) : (
                <Text>
                  This currently can only be enabled in development apps.
                </Text>
              )}
            </View>

            <View style={{ marginTop: 0.03 * height }} key="text">
              <Text style={[headerStyle, { marginTop: 10, marginBottom: -10 }]}>
                🤝 Transparent Classroom Integration
              </Text>
              <ButtonSwitches
                color={config?.colors?.button}
                initialButtonState={
                  config.transparentClassroomIntegrationEnabled
                    ? "right"
                    : "left"
                }
                leftOption="Off"
                rightOption="Enabled"
                leftAction={() =>
                  setConfig({
                    ...config,
                    transparentClassroomIntegrationEnabled: false
                  })
                }
                rightAction={() =>
                  setConfig({
                    ...config,
                    transparentClassroomIntegrationEnabled: true
                  })
                }
              />
            </View>

            <View style={{ marginTop: 0.03 * height }} key="text">
              <Text style={[headerStyle, { marginTop: 10 }]}>
                🤖 Onespot AI (Beta)
              </Text>
              <Text>
                As of Update #409 (September 9, 2024), this is now enabled by
                default for all apps, INCLUDING schools.
              </Text>
              <ButtonSwitches
                color={config?.colors?.button}
                initialButtonState={config.aiDisabled ? "left" : "right"}
                leftOption="Disabled"
                rightOption="On"
                leftAction={() => setConfig({ ...config, aiDisabled: true })}
                rightAction={() => setConfig({ ...config, aiDisabled: false })}
              />
            </View>

            <InputBox
              header="Advanced Analytics Page"
              description={`This should be a public Amplitude Dashboard link in this format:\nhttps://analytics.amplitude.com/share/[id]/tv`}
              onChangeText={(advancedAnalyticsLink) =>
                setConfig({ ...config, advancedAnalyticsLink })
              }
              multiline
              value={config.advancedAnalyticsLink}
              headerColorOverride={config.colors.text}
            />

            <InputBox
              header="Welcome Screen Button: Text"
              description="This shows up underneath 'Create account' and 'Log in'. The main purpose is to comply with Apple's Guideline 5.1.1(v) - Account Sign-In"
              onChangeText={(text) =>
                setConfig({
                  ...config,
                  welcomeScreenButton: {
                    ...(config.welcomeScreenButton || {}),
                    text
                  }
                })
              }
              value={config.welcomeScreenButton?.text || ""}
              headerColorOverride={config.colors.text}
            />

            <InputBox
              header="Welcome Screen Button: URL"
              description="This shows up underneath 'Create account' and 'Log in'. The main purpose is to comply with Apple's Guideline 5.1.1(v) - Account Sign-In"
              onChangeText={(url) =>
                setConfig({
                  ...config,
                  welcomeScreenButton: {
                    ...(config.welcomeScreenButton || {}),
                    url
                  }
                })
              }
              value={config.welcomeScreenButton?.url || ""}
              headerColorOverride={config.colors.text}
            />

            <InputBox
              header="App Designer's Name"
              description="Only add this if an external person built this app (e.g. illoumina)."
              onChangeText={(name) =>
                setConfig({
                  ...config,
                  appDesigner: { ...(config.appDesigner || {}), name }
                })
              }
              value={config.appDesigner?.name || ""}
              headerColorOverride={config.colors.text}
            />

            <InputBox
              header="App Designer's Link"
              description="Only add this if an external person built this app (e.g. illoumina)."
              onChangeText={(link) =>
                setConfig({
                  ...config,
                  appDesigner: { ...(config.appDesigner || {}), link }
                })
              }
              value={config.appDesigner?.link || ""}
              headerColorOverride={config.colors.text}
            />

            <InputBox
              header="Link to Google Play (Android)"
              onChangeText={(android) =>
                setAppStoreLinks({ ...appStoreLinks, android })
              }
              multiline
              value={appStoreLinks.android}
              headerColorOverride={config.colors.text}
            />
            {appStoreLinks.android ===
              Glob.get("onespotAppStoreLinks").android && (
              <Text style={{ fontWeight: "bold", color: "gray" }}>
                Default app store link.{" "}
                <Text style={{ fontWeight: "normal" }}>
                  If in The Montessori App, it'll link to that. Otherwise, if in
                  Onespot K12, it'll link to that. Otherwise it'll just link to
                  Onespot.
                </Text>
              </Text>
            )}

            <InputBox
              header="Link to App Store (iOS)"
              onChangeText={(ios) =>
                setAppStoreLinks({ ...appStoreLinks, ios })
              }
              multiline
              value={appStoreLinks.ios}
              headerColorOverride={config.colors.text}
            />
            {appStoreLinks.ios === Glob.get("onespotAppStoreLinks").ios && (
              <Text style={{ fontWeight: "bold", color: "gray" }}>
                Default app store link.{" "}
                <Text style={{ fontWeight: "normal" }}>
                  If in The Montessori App, it'll link to that. Otherwise, if in
                  Onespot K12, it'll link to that. Otherwise it'll just link to
                  Onespot.
                </Text>
              </Text>
            )}

            <InputBox
              header="How'd the app creator discover Onespot?"
              onChangeText={(sourceDiscoveredOnespot) =>
                setConfig({ ...config, sourceDiscoveredOnespot })
              }
              multiline
              value={config.sourceDiscoveredOnespot}
              headerColorOverride={config.colors.text}
            />
            <View style={{ height: 50 }} />
          </>
        )}
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  errorContainer: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 20,
    paddingTop: 20
  },
  errorTextLarge: {
    fontSize: 30,
    marginVertical: 20,
    color: "gray"
  },
  description: {
    fontWeight: "bold",
    fontSize: 16
  },
  formDescription: {
    marginVertical: 20
  },
  textInput: {
    fontSize: 0.03 * height,
    width: 0.82 * width,
    height: 0.056 * height,
    borderWidth: 1,
    borderColor: Glob.get("light gray"),
    borderRadius: 6,
    paddingLeft: 8,
    marginTop: 8,
    color: "black"
  },
  logo: {
    width: Glob.deviceHasTabletDimensions() ? height * 0.2 : width * 0.5,
    height: Glob.deviceHasTabletDimensions() ? height * 0.2 : width * 0.5,
    resizeMode: "contain"
  }
});
