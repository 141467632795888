import React from "react";
import { Text, View, Platform, Image } from "react-native";

export default function AuthScreenHeader({
  title = "Log in",
  subtitle = "Welcome back again!",
  topImage = null
}) {
  return (
    <View style={{ marginTop: Platform.OS === "android" ? 40 : 10 }}>
      {topImage && (
        <Image
          source={topImage}
          style={{ width: "70%", height: 60 }}
          resizeMode="contain"
        />
      )}
      <Text style={{ fontWeight: "700", fontSize: 24 }}>{title}</Text>
      <Text style={{ fontSize: 15 }}>{subtitle}</Text>
    </View>
  );
}
