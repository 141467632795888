import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ImageBackground,
  ActivityIndicator
} from "react-native";
import Constants from "expo-constants";
import School from "school/school";
import Rex from "src/globalState";
import Glob from "src/globalConstants";
import Firebase from "src/backend/firebase";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import BackButton from "src/components/backButton";
import Button from "src/components/Button";

const { height, width } = Glob.get("dimensions");
const hardcodedBackgroundImage = School.get("background image") || null;

export default function Welcome({ route, navigation }) {
  const { params: { fullName, tcIDs } = {} } = route || {};
  const showBackButton = Glob.get("appIsOnespotlike") && navigation.canGoBack();
  const [userType, setUserType] = useState("");
  const [allUserTypes, setAllUserTypes] = useState(null);
  const [logoURL, setLogoURL] = useState(null);
  const [backgroundURL, setBackgroundURL] = useState(null);
  const [pendingInvitationsExist, setPendingInvitationsExist] = useState(null);
  const [tcIntegrationConnected, setTCIntegrationConnected] = useState(false);

  useEffect(() => {
    Analytics.logEvent("view_welcome");
    Analytics.setUserProperties({
      standaloneAppSlug: Constants.expoConfig.slug,
      appVersion: Glob.get("appVersionFull")
    });
    Database.fetchAllAccountTypeDetails().then((details) => {
      if (details) {
        const allUserTypeIDs = Object.keys(details);
        if (allUserTypeIDs?.length === 1) setUserType(allUserTypeIDs[0]);
        setAllUserTypes(details);
      } else {
        // Handle legacy database structure
        Database.getAllDefaultPortals((portals) => {
          const allUserTypeIDs = Object.keys(portals);
          const newAllUserTypes = {};
          allUserTypeIDs.forEach((type) => {
            newAllUserTypes[type] = true;
          });
          if (allUserTypeIDs?.length === 1) setUserType(allUserTypeIDs[0]);
          setAllUserTypes(newAllUserTypes);
        });
      }
    });
    Database.checkIfPendingUserInvitationsExist().then(
      setPendingInvitationsExist
    );
    Firebase.getMediaURLAsync("logo.png")
      .then(setLogoURL)
      .catch(() => {});
    Firebase.getMediaURLAsync("background.png")
      .then(setBackgroundURL)
      .catch(() => {});
    Database.tcCheckIntegrationStatus().then((response) => {
      const { status } = response || {};
      setTCIntegrationConnected(
        status === Glob.get("tcIntegrationStatuses").CONNECTED
      );
    });
  }, []);

  const continueToSignup = () => {
    let accountType = null;
    let screen = "pickAccountType";
    // If there's only one user type, then that will already be selected
    if (userType && userType !== "") {
      const userTypeDetails = allUserTypes && allUserTypes[userType];
      Rex.setUserType(userType);
      accountType = { ...userTypeDetails, key: userType };
      screen = "signup"; // skip over the pickAccountType screen
    }
    if (!Rex.getLoginStatus() && pendingInvitationsExist)
      screen = "joinWithInvitationCode";
    navigation.push(screen, { fullName, accountType, tcIDs });
  };

  const title = Rex.getConfig()?.names?.nickname;
  const titleFontSize = title.length < 16 ? Math.min(60, width * 0.17) : 32;
  const hasLogoOrDescription = !!logoURL;
  const welcomeScreenButton = Rex.getConfig()?.welcomeScreenButton;
  const hasWelcomeScreenButton =
    !!welcomeScreenButton &&
    !!welcomeScreenButton.text &&
    !!welcomeScreenButton.url;

  const renderLogoOrDescription = () => {
    if (logoURL)
      return (
        <View style={{ flex: 2 }}>
          <Image
            style={{
              width: Glob.deviceHasTabletDimensions()
                ? height * 0.2
                : width * 0.5,
              height: Glob.deviceHasTabletDimensions()
                ? height * 0.2
                : width * 0.5,
              resizeMode: "contain"
            }}
            source={{ uri: logoURL }}
          />
        </View>
      );
    return null;
  };

  const backgroundImageSource = backgroundURL
    ? { uri: backgroundURL }
    : hardcodedBackgroundImage;

  return (
    <View>
      <ImageBackground
        source={backgroundImageSource}
        style={[
          styles.backgroundImage,
          { backgroundColor: Rex.getConfig()?.colors?.background }
        ]}
      >
        <View style={styles.mainView}>
          <View style={{ flex: 0.2 }} />
          {showBackButton && (
            <View style={{ flex: 0.5, width }}>
              <BackButton navigation={navigation} />
            </View>
          )}
          <View
            style={{
              justifyContent: "center",
              flex: hasLogoOrDescription ? 1 : 3
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: hasLogoOrDescription ? 16 : 24,
                textAlign: "center"
              }}
            >
              Welcome to
            </Text>
            <Text
              adjustsFontSizeToFit
              style={{
                color: "white",
                fontWeight: "500",
                marginHorizontal: 15,
                fontSize: hasLogoOrDescription ? 32 : titleFontSize,
                textAlign: "center"
              }}
            >
              {title}
            </Text>
          </View>
          {renderLogoOrDescription()}
          <View
            style={{
              flex: 1,
              justifyContent: "center"
            }}
          >
            {pendingInvitationsExist === null ? (
              <ActivityIndicator color="white" size="large" />
            ) : (
              <>
                {!!Rex.getConfig()?.transparentClassroomIntegrationEnabled &&
                  !Rex.getConfig()?.transparentClassroomConfig?.ssoDisabled &&
                  tcIntegrationConnected && (
                    <Button
                      text={`Sign in with${"\n"}Transparent Classroom`}
                      onPress={() => {
                        Analytics.logEvent("touch_welcome_signInWithTC");
                        navigation.push("login", {
                          sso: "transparentClassroom"
                        });
                      }}
                      color="white"
                      textStyle={{
                        color: Rex.getConfig()?.colors?.button,
                        fontWeight: "bold",
                        fontSize: 18
                      }}
                      style={{
                        paddingHorizontal: 0
                      }}
                      multiline
                      icon="transparent-classroom-logo-circle"
                    />
                  )}
                <Button
                  text={
                    Rex.getLoginStatus() || pendingInvitationsExist
                      ? "Join"
                      : "Create account"
                  }
                  onPress={() => {
                    if (Rex.getLoginStatus() || pendingInvitationsExist)
                      Analytics.logEvent("touch_welcome_join");
                    else Analytics.logEvent("touch_welcome_createAccount");
                    continueToSignup();
                  }}
                  style={{ backgroundColor: "white" }}
                  textStyle={{
                    color: Rex.getConfig()?.colors?.button,
                    fontWeight: "bold"
                  }}
                />
                {!Rex.getLoginStatus() && !pendingInvitationsExist && (
                  <Button
                    text="Log in"
                    onPress={() => {
                      Analytics.logEvent("touch_welcome_logIn");
                      navigation.push("login");
                    }}
                    textStyle={{ fontWeight: "bold" }}
                  />
                )}
                {hasWelcomeScreenButton && (
                  <View>
                    <Button
                      text={welcomeScreenButton.text}
                      flat
                      small
                      onPress={() =>
                        navigation.push("webNav", {
                          title: welcomeScreenButton.text,
                          url: welcomeScreenButton.url
                        })
                      }
                      style={{ backgroundColor: "white" }}
                      textStyle={{
                        color: Rex.getConfig()?.colors?.button,
                        fontWeight: "bold"
                      }}
                    />
                  </View>
                )}
              </>
            )}
          </View>
        </View>
      </ImageBackground>
    </View>
  );
}

const styles = StyleSheet.create({
  backgroundImage: {
    height,
    width: "100%",
    flexDirection: "column",
    alignItems: "center"
  },
  mainView: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    justifyContent: "space-around"
  }
});
