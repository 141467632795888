import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  ScrollView,
  ActivityIndicator,
  TouchableOpacity,
  RefreshControl,
  Alert,
  Platform
} from "react-native";
import NavBar from "src/components/navBar";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Icon from "src/components/Icon";
import DatePicker from "src/components/DatePicker";
import InputBox from "src/components/InputBox";
import Dropdown from "src/components/Dropdown";
import { Skeleton } from "native-base";
import Glob from "src/globalConstants";
import TCLogoIcon from "src/components/TCLogoIcon";

const { width } = Glob.get("dimensions");

const ROLES = [
  { value: "parent", text: "Parent" },
  { value: "teacher", text: "Teacher" },
  { value: "admin", text: "Admin" },
  { value: "family_member", text: "Family Member" },
  { value: "billing_manager", text: "Billing Manager" }
];

const EXIT_REASON = {
  graduated: "Graduated from school",
  relocated: "Family relocated",
  expense: "Expense",
  hours_offered: "Hours offered",
  location: "Location",
  eligible_for_kindergarten: "Child eligible for public kindergarten",
  asked_to_leave:
    "School requested the family find another school for their child",
  joined_sibling: "Child joined sibling at another school",
  no_lottery_spot:
    "Child did not receive a lottery spot at their current school",
  bad_fit: "Montessori was not a good fit",
  equity: "Equity reasons (e.g. lack of diversity, curriculum, etc.)",
  family_dissatisfied: "Family dissatisfied with school",
  natural_disaster: "Natural disaster",
  entered_public_system: "Child entered into public school system",
  transferred_multi_year:
    "Child transferred to secure a multi-year spot at another school",
  none_of_the_above: "None of the above"
};

const GENDER_OPTIONS = [
  { value: "M", text: "Male" },
  { value: "F", text: "Female" },
  { value: "X", text: "Nonbinary" }
];

const HOUSEHOLD_INCOME_OPTIONS = [
  { value: "very_low", text: "Very Low" },
  { value: "low", text: "Low" },
  { value: "medium", text: "Medium" },
  { value: "high", text: "High" }
];

// Move this component definition OUTSIDE of the main component
const EditableDetailItem = ({
  field,
  icon,
  label,
  value,
  type = "text",
  options = [],
  multiline = false,
  color = "#6c757d",
  allowOther = false,
  onUpdateField
}) => {
  return (
    <View style={styles.editableDetailItem}>
      {icon && (
        <Icon icon={icon} size={18} color={color} style={styles.detailIcon} />
      )}
      <View style={styles.editableDetailContent}>
        {type === "text" && (
          <InputBox
            header={label}
            value={value || ""}
            onChangeText={(newValue) => onUpdateField(field, newValue)}
            multiline={multiline}
            style={{ marginTop: 0 }}
          />
        )}
        {type === "date" && (
          <DatePicker
            header={label}
            selectedDate={
              value
                ? (() => {
                    // Parse the date parts directly from the string to avoid timezone issues
                    const [year, month, day] = value
                      .split("-")
                      .map((num) => parseInt(num, 10));

                    // Create date with local timezone interpretation
                    return new Date(year, month - 1, day);
                  })()
                : null
            }
            onDateChange={(date) => {
              // Format date as YYYY-MM-DD for API, but ensure we use local date components
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0");
              const day = String(date.getDate()).padStart(2, "0");
              const dateStr = `${year}-${month}-${day}`;
              onUpdateField(field, dateStr);
            }}
            noHeaderMargin
          />
        )}
        {type === "dropdown" && (
          <Dropdown
            header={label}
            value={value}
            onSelect={(newValue) => onUpdateField(field, newValue)}
            items={options}
            allowOther={allowOther}
            flexibleWidth
            headerStyle={{ marginTop: 0 }}
          />
        )}
      </View>
    </View>
  );
};

export default function TCChildDetails({ route, navigation }) {
  const { childID, onUpdateChild } = route.params;
  const [child, setChild] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editedChild, setEditedChild] = useState(null);
  const [parents, setParents] = useState([]);
  const [loadingParents, setLoadingParents] = useState(false);
  const [classrooms, setClassrooms] = useState([]);
  const [loadingClassrooms, setLoadingClassrooms] = useState(false);
  const [parentPhotos, setParentPhotos] = useState({});
  const [loadingPhotos, setLoadingPhotos] = useState({});
  const [users, setUsers] = useState([]);
  const [userIDToUser, setUserIDToUser] = useState({});
  const [childrenPayers, setChildrenPayers] = useState({});
  const [savingPaymentProfile, setSavingPaymentProfile] = useState(false);
  const [showPaymentProfileSelect, setShowPaymentProfileSelect] = useState(
    false
  );
  const [selectedPaymentProfileID, setSelectedPaymentProfileID] = useState(
    null
  );
  const [billingUserPhoto, setBillingUserPhoto] = useState(null);
  const [loadingBillingUserPhoto, setLoadingBillingUserPhoto] = useState(false);

  const showPaymentProfile =
    !!Rex.getConfig()?.commerceBillingEnabled &&
    Rex.getSessionMemory("adminPrivileges")?.includes("Commerce") &&
    Rex.getSessionMemory("adminPrivileges")?.includes("ManageUsers");

  useEffect(() => {
    Analytics.logEvent("view_tcChildDetails");
    fetchChildDetails();
    fetchClassrooms();
    fetchUsersAndPaymentData();
  }, [childID]);

  useEffect(() => {
    if (child) {
      setEditedChild({ ...child });
      if (child.parent_ids && child.parent_ids.length > 0) {
        fetchParentDetails();
      }
    }
  }, [child]);

  useEffect(() => {
    if (parents && parents.length > 0) {
      parents.forEach((parent) => {
        if (parent.id) {
          fetchParentProfilePhoto(parent.id);
        }
      });
    }
  }, [parents]);

  useEffect(() => {
    Database.fetchAllUsers().then((allUsers) => {
      setUsers(allUsers);
      const userMap = allUsers.reduce((acc, user) => {
        acc[user.uid] = user;
        return acc;
      }, {});
      setUserIDToUser(userMap);
    });
    Database.fetchAllTCChildrenData().then(setChildrenPayers);
  }, []);

  useEffect(() => {
    const billingUserID = childrenPayers?.[childID]?.billingUserID;
    const billingUser = billingUserID ? userIDToUser[billingUserID] : null;

    if (billingUser && billingUser?.tcIDs?.tcUserID) {
      setLoadingBillingUserPhoto(true);
      fetchParentProfilePhoto(billingUser?.tcIDs?.tcUserID);
    } else if (billingUser) {
      // If billing user exists but has no tcUserID, make sure we're not in loading state
      setLoadingBillingUserPhoto(false);
      setBillingUserPhoto(null);
    }
  }, [childrenPayers, userIDToUser, childID]);

  const fetchChildDetails = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await Database.tcFetchChildDetails(childID);
      if (response && response.data) {
        setChild(response.data);
      } else {
        setError("Could not retrieve child details");
      }
    } catch (err) {
      console.error("Error fetching child details:", err);
      setError("An error occurred while fetching child details");
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const fetchParentDetails = async () => {
    try {
      setLoadingParents(true);
      const parentPromises = child.parent_ids.map((parentId) =>
        Database.tcFetchUser(parentId)
      );

      const parentResponses = await Promise.all(parentPromises);
      const validParents = parentResponses
        .filter((response) => response && response.data)
        .map((response) => response.data);

      setParents(validParents);
    } catch (err) {
      console.error("Error fetching parent details:", err);
    } finally {
      setLoadingParents(false);
    }
  };

  const fetchClassrooms = async () => {
    try {
      setLoadingClassrooms(true);
      const response = await Database.tcFetchClassrooms();
      if (response && response.data && Array.isArray(response.data)) {
        setClassrooms(response.data);
      } else {
        console.error("Failed to fetch classrooms data");
      }
    } catch (err) {
      console.error("Error fetching classrooms:", err);
    } finally {
      setLoadingClassrooms(false);
    }
  };

  const fetchParentProfilePhoto = async (tcID) => {
    try {
      setLoadingPhotos((prev) => ({ ...prev, [tcID]: true }));
      const response = await Database.tcFetchProfilePhoto({ tcID });
      if (response && response.success && response.data) {
        setParentPhotos((prev) => ({ ...prev, [tcID]: response.data }));

        // Check if this is the billing user's photo
        const billingUserID = childrenPayers?.[childID]?.billingUserID;
        const billingUser = billingUserID ? userIDToUser[billingUserID] : null;

        if (billingUser && billingUser?.tcIDs?.tcUserID === tcID) {
          setBillingUserPhoto(response.data);
          setLoadingBillingUserPhoto(false);
        }
      }
    } catch (err) {
      console.error(`Error fetching profile photo for user ${tcID}:`, err);
    } finally {
      setLoadingPhotos((prev) => ({ ...prev, [tcID]: false }));
    }
  };

  const onRefresh = () => {
    setRefreshing(true);
    fetchChildDetails();
    fetchClassrooms();
  };

  // Format date to display in a readable format
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";

    // Parse the date parts directly from the string to avoid timezone issues
    const [year, month, day] = dateString
      .split("-")
      .map((num) => parseInt(num, 10));

    // Create a date using local timezone by specifying all parts
    const date = new Date(year, month - 1, day);

    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric"
    });
  };

  // Calculate and format age
  const calculateAge = (birthDate) => {
    if (!birthDate) return "N/A";

    // Parse the date parts directly from the string to avoid timezone issues
    const [year, month, day] = birthDate
      .split("-")
      .map((num) => parseInt(num, 10));

    // Create a date using local timezone
    const bDate = new Date(year, month - 1, day);
    const today = new Date();

    let monthsTotal = (today.getFullYear() - bDate.getFullYear()) * 12;
    monthsTotal += today.getMonth() - bDate.getMonth();

    if (today.getDate() < bDate.getDate()) {
      monthsTotal -= 1;
    }

    const years = Math.floor(monthsTotal / 12);
    const months = monthsTotal % 12;

    let ageString = "";
    if (years > 0) {
      ageString += `${years} year${years !== 1 ? "s" : ""}`;
      if (months > 0) ageString += ` ${months} month${months !== 1 ? "s" : ""}`;
    } else {
      ageString = `${months} month${months !== 1 ? "s" : ""}`;
    }

    return ageString;
  };

  // Create a detail item component for displaying information rows
  const DetailItem = ({ icon, label, value, color = "#6c757d" }) => {
    if (!value) return null;

    return (
      <View style={styles.detailItem}>
        {icon && (
          <Icon icon={icon} size={18} color={color} style={styles.detailIcon} />
        )}
        <View style={styles.detailContent}>
          <Text style={styles.detailLabel}>{label}</Text>
          <Text style={styles.detailValue}>{value}</Text>
        </View>
      </View>
    );
  };

  // Render a section header
  const SectionHeader = ({ title }) => (
    <View style={styles.sectionHeader}>
      <Text style={styles.sectionTitle}>{title}</Text>
    </View>
  );

  // Add analytics for when the user starts editing
  const handleStartEditing = () => {
    Analytics.logEvent("touch_tcChildDetails_startEditing");
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      setSaving(true);

      // Identify which fields were modified for analytics
      let modifiedFields = [];
      try {
        modifiedFields = Object.keys(editedChild || {}).filter(
          (field) =>
            editedChild[field] !== child[field] && editedChild[field] != null
        );
      } catch (err) {
        console.error("Error identifying modified fields for analytics:", err);
      }

      Analytics.logEvent("touch_tcChildDetails_save", {
        fieldsModified: modifiedFields.join(","),
        fieldsModifiedCount: modifiedFields.length
      });

      // Prepare data for update
      const updateData = {
        ...(editedChild.first_name &&
        editedChild.first_name !== child.first_name
          ? { first_name: editedChild.first_name }
          : {}),
        ...(editedChild.last_name && editedChild.last_name !== child.last_name
          ? { last_name: editedChild.last_name }
          : {}),
        ...(editedChild.birth_date &&
        editedChild.birth_date !== child.birth_date
          ? { birth_date: editedChild.birth_date }
          : {}),
        ...(editedChild.gender && editedChild.gender !== child.gender
          ? { gender: editedChild.gender }
          : {}),
        ...(editedChild.program && editedChild.program !== child.program
          ? { program: editedChild.program }
          : {}),
        ...(editedChild.ethnicity && editedChild.ethnicity !== child.ethnicity
          ? { ethnicity: editedChild.ethnicity }
          : {}),
        ...(editedChild.household_income &&
        editedChild.household_income !== child.household_income
          ? { household_income: editedChild.household_income }
          : {}),
        ...(editedChild.dominant_language &&
        editedChild.dominant_language !== child.dominant_language
          ? { dominant_language: editedChild.dominant_language }
          : {}),
        ...(editedChild.grade && editedChild.grade !== child.grade
          ? { grade: editedChild.grade }
          : {}),
        ...(editedChild.student_id &&
        editedChild.student_id !== child.student_id
          ? { student_id: editedChild.student_id }
          : {}),
        ...(editedChild.hours_string &&
        editedChild.hours_string !== child.hours_string
          ? { hours_string: editedChild.hours_string }
          : {}),
        ...(editedChild.allergies && editedChild.allergies !== child.allergies
          ? { allergies: editedChild.allergies }
          : {}),
        ...(editedChild.notes && editedChild.notes !== child.notes
          ? { notes: editedChild.notes }
          : {})
      };

      const response = await Database.tcUpdateChildDetails(childID, updateData);

      if (response && response.success) {
        setChild(editedChild);
        setIsEditing(false);

        if (onUpdateChild) onUpdateChild();
      } else {
        throw new Error("Failed to update child details");
      }
    } catch (err) {
      console.error("Error updating child details:", err);
      Alert.alert(
        "Error",
        "Failed to update child details. Please try again.",
        [{ text: "OK" }]
      );
    } finally {
      setSaving(false);
    }
  };

  const cancelEditing = () => {
    Analytics.logEvent("touch_tcChildDetails_cancelEditing");
    // Reset edited data
    setEditedChild({ ...child });
    setIsEditing(false);
  };

  // Add this function to update the fields
  const updateField = (field, newValue) => {
    setEditedChild((prev) => ({
      ...prev,
      [field]: newValue
    }));
  };

  // Helper function to render parent content based on loading state
  const renderParentContent = () => {
    if (loadingParents) {
      return (
        <View style={styles.parentSkeletonContainer}>
          {/* Parent 1 skeleton */}
          <View style={styles.parentCard}>
            <View style={styles.parentCardContent}>
              <Skeleton rounded="full" size={10} startColor="#E1E1E1" />
              <View style={{ marginLeft: 12, flex: 1 }}>
                <Skeleton
                  h="5"
                  w="60%"
                  rounded="md"
                  mb="1"
                  startColor="#E1E1E1"
                />
                <Skeleton h="4" w="40%" rounded="md" startColor="#E1E1E1" />
              </View>
            </View>
          </View>

          {/* Parent 2 skeleton */}
          <View style={styles.parentCard}>
            <View style={styles.parentCardContent}>
              <Skeleton rounded="full" size={10} startColor="#E1E1E1" />
              <View style={{ marginLeft: 12, flex: 1 }}>
                <Skeleton
                  h="5"
                  w="50%"
                  rounded="md"
                  mb="1"
                  startColor="#E1E1E1"
                />
                <Skeleton h="4" w="30%" rounded="md" startColor="#E1E1E1" />
              </View>
            </View>
          </View>
        </View>
      );
    }

    if (parents.length > 0) {
      return (
        <View>
          {parents.map((parent, index) => (
            <ParentCard key={parent.id || index} parent={parent} />
          ))}
        </View>
      );
    }

    return (
      <Text style={styles.emptyNotes}>No parent information available</Text>
    );
  };

  // Add a new function to fetch users and payment profile data
  const fetchUsersAndPaymentData = async () => {
    try {
      const allUsers = await Database.fetchAllUsers();
      // Sort alphabetically for dropdown display
      const sortedUsers = allUsers.sort((a, b) =>
        `${a.firstName} ${a.lastName}`.localeCompare(
          `${b.firstName} ${b.lastName}`
        )
      );

      setUsers(sortedUsers);

      const userMap = sortedUsers.reduce((acc, user) => {
        acc[user.uid] = user;
        return acc;
      }, {});
      setUserIDToUser(userMap);

      const paymentData = await Database.fetchAllTCChildrenData();
      setChildrenPayers(paymentData);
    } catch (err) {
      console.error("Error fetching users and payment data:", err);
    }
  };

  // Add a function to update the payment profile
  const updatePaymentProfile = async (userID) => {
    try {
      setSavingPaymentProfile(true);
      Analytics.logEvent("touch_tcChildDetails_updatePaymentProfile", {
        childID,
        userID
      });

      // Clear any existing billing user photo when changing users
      setBillingUserPhoto(null);

      await Database.stripeConnectedAccountFetchCustomer({
        userID,
        createCustomerIfDoesntExist: true
      });

      await Database.setTCChildBillingUserID(childID, userID);

      // Refresh payment profile data
      const paymentData = await Database.fetchAllTCChildrenData();
      setChildrenPayers(paymentData);

      // Reset state
      setShowPaymentProfileSelect(false);
      setSelectedPaymentProfileID(null);
    } catch (err) {
      console.error("Error updating payment profile:", err);
    } finally {
      setSavingPaymentProfile(false);
    }
  };

  // Add this new component for payment profile display and management
  const PaymentProfileSection = () => {
    const billingUserID = childrenPayers?.[childID]?.billingUserID;
    const billingUser = billingUserID ? userIDToUser[billingUserID] : null;

    // Helper function to render avatar based on loading state and photo availability
    const renderBillingUserAvatar = () => {
      if (loadingBillingUserPhoto) {
        return (
          <Skeleton rounded="full" size={10} style={{ marginRight: 12 }} />
        );
      }

      if (billingUserPhoto) {
        return (
          <Image
            source={{ uri: billingUserPhoto }}
            style={styles.parentPhoto}
          />
        );
      }

      return (
        <View
          style={[
            styles.parentAvatar,
            {
              backgroundColor: Rex.getConfig()?.colors?.background || "#333"
            }
          ]}
        >
          <Text style={styles.parentAvatarText}>
            {billingUser.firstName?.[0]}
            {billingUser.lastName?.[0]}
          </Text>
        </View>
      );
    };

    const renderPaymentProfileContent = () => {
      if (showPaymentProfileSelect) {
        return (
          <View style={styles.paymentProfileSelector}>
            <Text style={styles.paymentSelectorLabel}>
              Select a parent/user to assign them as the payment profile for
              bills associated with this child:
            </Text>
            {savingPaymentProfile ? (
              <View style={styles.savingContainer}>
                <TCLogoIcon size={20} spinning />
                <Text style={styles.savingText}>
                  Updating payment profile...
                </Text>
              </View>
            ) : (
              <>
                <Dropdown
                  header=""
                  value={selectedPaymentProfileID}
                  onSelect={updatePaymentProfile}
                  items={users.map((user) => ({
                    value: user.uid,
                    text: `${user.firstName} ${user.lastName} (${user.email})`
                  }))}
                  placeholder="Search for a user..."
                  enableAutocomplete
                  flexibleWidth
                  startOpen
                  onCollapse={() => {
                    setShowPaymentProfileSelect(false);
                    setSelectedPaymentProfileID(null);
                  }}
                  headerStyle={{ marginTop: 0, marginBottom: 8 }}
                />
              </>
            )}
          </View>
        );
      }

      if (billingUser) {
        const renderChangePaymentProfileButton = () => (
          <>
            {savingPaymentProfile ? (
              <View style={styles.loadingIndicator}>
                <Skeleton rounded="full" size={6} startColor="#E1E1E1" />
              </View>
            ) : (
              <TouchableOpacity
                style={styles.changePaymentProfileButton}
                onPress={() => {
                  setSelectedPaymentProfileID(billingUserID);
                  setShowPaymentProfileSelect(true);
                }}
              >
                <Text style={styles.changePaymentProfileText}>Change</Text>
              </TouchableOpacity>
            )}
          </>
        );
        return (
          <View style={styles.currentPaymentProfile}>
            <View style={styles.parentCardContent}>
              {renderBillingUserAvatar()}
              <View style={styles.parentInfo}>
                <Text style={styles.parentName}>
                  {billingUser.firstName} {billingUser.lastName}
                </Text>
                <Text style={styles.parentRoles}>{billingUser.email}</Text>
              </View>
              {Platform.OS !== "web" && renderChangePaymentProfileButton()}
            </View>
            {Platform.OS === "web" && renderChangePaymentProfileButton()}
          </View>
        );
      }

      return (
        <View style={styles.noPaymentProfile}>
          <Text style={styles.noPaymentProfileText}>
            No payment profile has been assigned to this child.
          </Text>
          <TouchableOpacity
            style={styles.addPaymentProfileButton}
            onPress={() => setShowPaymentProfileSelect(true)}
          >
            <Icon
              icon="05475c18-1a66-4e08-9183-8b10b5b6de55"
              size={16}
              color="#fff"
              style={{ marginRight: 8 }}
            />
            <Text style={styles.addPaymentProfileText}>
              Assign Payment Profile
            </Text>
          </TouchableOpacity>
        </View>
      );
    };

    return (
      <View style={styles.contentSection}>
        <SectionHeader title="Payment Profile" />
        {renderPaymentProfileContent()}
      </View>
    );
  };

  // Render loading state
  if (loading && !refreshing && !child) {
    return (
      <View style={styles.container}>
        <NavBar navigation={navigation} text="Child Details" />
        <View style={styles.loadingContainer}>
          <TCLogoIcon size={40} spinning />
          <Text style={styles.loadingText}>Loading child details...</Text>
        </View>
      </View>
    );
  }

  // Render error state
  if (error && !child) {
    return (
      <View style={styles.container}>
        <NavBar navigation={navigation} text="Child Details" />
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{error}</Text>
          <TouchableOpacity
            style={styles.retryButton}
            onPress={fetchChildDetails}
          >
            <Text style={styles.retryButtonText}>Retry</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  let genderText = editedChild?.gender || child?.gender;
  let genderIcon = "0c3e9c95-9597-476d-9c9f-7f0ce4dcac9d";
  if (genderText === "F") {
    genderText = "Female";
    genderIcon = "6084bd7a-b722-4153-a34d-1c4cc3e3c631";
  } else if (genderText === "M") {
    genderText = "Male";
    genderIcon = "0d55f237-4034-4630-82c1-1a6b7ce99f3f";
  } else if (genderText === "X") {
    genderText = "Nonbinary";
    genderIcon = "b34fdca3-fb02-4798-a2e5-990d3646df53";
  }

  // Render a parent card
  const ParentCard = ({ parent }) => {
    const photoUrl = parentPhotos[parent.id];
    const isLoading = loadingPhotos[parent.id];

    const renderParentAvatar = () => {
      if (isLoading)
        return (
          <Skeleton rounded="full" size={10} style={{ marginRight: 12 }} />
        );

      if (photoUrl)
        return <Image source={{ uri: photoUrl }} style={styles.parentPhoto} />;

      return (
        <View
          style={[
            styles.parentAvatar,
            {
              backgroundColor: Rex.getConfig()?.colors?.background || "#333"
            }
          ]}
        >
          <Text style={styles.parentAvatarText}>
            {parent.first_name?.charAt(0)}
            {parent.last_name?.charAt(0)}
          </Text>
        </View>
      );
    };

    return (
      <View style={styles.parentCard}>
        <View style={styles.parentCardContent}>
          {renderParentAvatar()}

          <View style={styles.parentInfo}>
            <Text style={styles.parentName}>
              {parent.first_name} {parent.last_name}
            </Text>
            {parent.roles && (
              <Text style={styles.parentRoles}>
                {parent.roles
                  .map((role) => ROLES.find((r) => r.value === role)?.text)
                  .join(", ")}
              </Text>
            )}
          </View>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NavBar
        navigation={navigation}
        text="Child Details"
        RightButton={
          isEditing ? null : (
            <TouchableOpacity
              style={{
                width: "100%",
                height: "100%",
                alignItems: "flex-end",
                justifyContent: "center",
                paddingRight: 10
              }}
              onPress={handleStartEditing}
            >
              <Icon
                icon="b0ec90dd-8381-423f-b3d5-a7d36b52a453"
                size={Glob.deviceHasTabletDimensions() ? 32 : 20}
              />
            </TouchableOpacity>
          )
        }
      />

      <ScrollView
        style={styles.scrollView}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        {child && editedChild && (
          <>
            {/* Header with photo and basic info */}
            <View style={styles.headerSection}>
              {child.profile_photo && child.profile_photo.includes("http") ? (
                <Image
                  source={{ uri: child.profile_photo }}
                  style={styles.profilePhoto}
                />
              ) : (
                <View
                  style={[
                    styles.avatarContainer,
                    {
                      backgroundColor:
                        Rex.getConfig()?.colors?.background || "#333"
                    }
                  ]}
                >
                  <Text style={styles.avatarText}>
                    {editedChild.first_name?.charAt(0)}
                    {editedChild.last_name?.charAt(0)}
                  </Text>
                </View>
              )}

              <View style={styles.headerInfo}>
                {isEditing ? (
                  <View style={styles.nameInputContainer}>
                    <InputBox
                      value={editedChild.first_name || ""}
                      onChangeText={(text) =>
                        setEditedChild((prev) => ({
                          ...prev,
                          first_name: text
                        }))
                      }
                      placeholder="First Name"
                      style={{ marginTop: 0 }}
                      flexibleWidth
                    />
                    <InputBox
                      value={editedChild.last_name || ""}
                      onChangeText={(text) =>
                        setEditedChild((prev) => ({ ...prev, last_name: text }))
                      }
                      placeholder="Last Name"
                      style={{ marginTop: 0 }}
                      flexibleWidth
                    />
                  </View>
                ) : (
                  <>
                    <Text style={styles.nameText}>
                      {child.first_name} {child.last_name}
                    </Text>
                    <Text style={styles.ageText}>
                      {calculateAge(child.birth_date)}
                    </Text>
                  </>
                )}
              </View>
            </View>

            {isEditing && (
              <View style={styles.editActionsContainer}>
                <TouchableOpacity
                  style={styles.cancelButton}
                  onPress={cancelEditing}
                >
                  <Text style={styles.cancelButtonText}>Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.saveButton}
                  onPress={handleSave}
                  disabled={saving}
                >
                  {saving ? (
                    <ActivityIndicator size="small" color="#fff" />
                  ) : (
                    <Text style={styles.saveButtonText}>Save</Text>
                  )}
                </TouchableOpacity>
              </View>
            )}

            {/* Parents/Guardians */}
            <View style={styles.contentSection}>
              <SectionHeader title="Parents/Guardians" />
              {renderParentContent()}
            </View>

            {/* Payment Profile Section */}
            {showPaymentProfile && <PaymentProfileSection />}

            {/* Classrooms */}
            <View style={styles.contentSection}>
              <SectionHeader title="Classrooms" />

              {loadingClassrooms ? (
                <ActivityIndicator style={{ margin: 20 }} />
              ) : (
                <View>
                  {child.classroom_ids && child.classroom_ids.length > 0 ? (
                    child.classroom_ids.map((classroomId) => {
                      const classroom = classrooms.find(
                        (c) => c.id === classroomId
                      );
                      return classroom ? (
                        <DetailItem
                          key={classroom.id}
                          icon="01f1d136-1b73-4f8f-98d6-e6c65f8433e0" // classroom/school icon
                          label={classroom.name}
                          value={`Level: ${classroom.level}`}
                          color="#6c757d"
                        />
                      ) : null;
                    })
                  ) : (
                    <Text style={styles.noDataText}>
                      No classrooms assigned
                    </Text>
                  )}
                </View>
              )}
            </View>

            {/* Basic Information */}
            <View style={styles.contentSection}>
              <SectionHeader title="Basic Information" />

              {isEditing ? (
                <>
                  <EditableDetailItem
                    field="birth_date"
                    icon="0e52f4e2-68e5-40f5-bd8e-2aa22e80d868"
                    label="Birth Date"
                    value={editedChild.birth_date}
                    type="date"
                    onUpdateField={updateField}
                  />

                  <EditableDetailItem
                    field="gender"
                    icon={genderIcon}
                    label="Gender"
                    value={editedChild.gender}
                    type="dropdown"
                    options={GENDER_OPTIONS}
                    allowOther
                    onUpdateField={updateField}
                  />

                  <EditableDetailItem
                    field="program"
                    icon="0e52f4e2-68e5-40f5-bd8e-2aa22e80d868"
                    label="Program"
                    value={editedChild.program}
                    onUpdateField={updateField}
                  />

                  <EditableDetailItem
                    field="grade"
                    icon="0e52f4e2-68e5-40f5-bd8e-2aa22e80d868"
                    label="Grade"
                    value={editedChild.grade}
                    onUpdateField={updateField}
                  />

                  <EditableDetailItem
                    field="student_id"
                    icon="b8412f54-acd9-40ea-99cb-4d58bc0e2ff2"
                    label="Student ID"
                    value={editedChild.student_id}
                    onUpdateField={updateField}
                  />

                  <DetailItem
                    icon="46e962ab-43a3-48d6-acdd-8eb7f60d9405"
                    label="Hours"
                    value={child.hours_string}
                  />
                </>
              ) : (
                <>
                  <DetailItem
                    icon="0e52f4e2-68e5-40f5-bd8e-2aa22e80d868"
                    label="Birth Date"
                    value={formatDate(child.birth_date)}
                  />

                  <DetailItem
                    icon={genderIcon}
                    label="Gender"
                    value={genderText}
                  />

                  <DetailItem
                    icon="0e52f4e2-68e5-40f5-bd8e-2aa22e80d868"
                    label="Program"
                    value={child.program}
                  />

                  <DetailItem
                    icon="0e52f4e2-68e5-40f5-bd8e-2aa22e80d868"
                    label="Grade"
                    value={child.grade}
                  />

                  <DetailItem
                    icon="b8412f54-acd9-40ea-99cb-4d58bc0e2ff2"
                    label="Student ID"
                    value={child.student_id}
                  />

                  <DetailItem
                    icon="46e962ab-43a3-48d6-acdd-8eb7f60d9405"
                    label="Hours"
                    value={child.hours_string}
                  />
                </>
              )}
            </View>

            {/* Additional Information */}
            <View style={styles.contentSection}>
              <SectionHeader title="Additional Information" />

              {isEditing ? (
                <>
                  <DetailItem
                    icon="180dbe07-6c70-4284-a3d0-a5137d1c2a68"
                    label="Race and Ethnicity"
                    value={
                      child.ethnicity
                        ? child.ethnicity.join(", ")
                        : "(None specified)"
                    }
                  />

                  <EditableDetailItem
                    field="household_income"
                    icon="money"
                    label="Household Income"
                    value={editedChild.household_income}
                    type="dropdown"
                    options={HOUSEHOLD_INCOME_OPTIONS}
                    onUpdateField={updateField}
                  />

                  <EditableDetailItem
                    field="dominant_language"
                    icon="5acbb7ce-88f9-4673-aaff-0ba24fa54bf5"
                    label="Dominant Language"
                    value={editedChild.dominant_language}
                    onUpdateField={updateField}
                  />

                  <EditableDetailItem
                    field="allergies"
                    icon="ab63e4da-7ea6-4f8e-a437-4687707517a2"
                    label="Allergies"
                    value={editedChild.allergies}
                    color="#e74c3c"
                    onUpdateField={updateField}
                  />
                </>
              ) : (
                <>
                  {child.ethnicity && child.ethnicity.length > 0 && (
                    <DetailItem
                      icon="180dbe07-6c70-4284-a3d0-a5137d1c2a68"
                      label="Race and Ethnicity"
                      value={child.ethnicity.join(", ")}
                    />
                  )}

                  <DetailItem
                    icon="money"
                    label="Household Income"
                    value={
                      HOUSEHOLD_INCOME_OPTIONS.find(
                        (option) => option.value === child.household_income
                      )?.text || child.household_income
                    }
                  />

                  <DetailItem
                    icon="5acbb7ce-88f9-4673-aaff-0ba24fa54bf5"
                    label="Dominant Language"
                    value={child.dominant_language}
                  />

                  <DetailItem
                    icon="ab63e4da-7ea6-4f8e-a437-4687707517a2"
                    label="Allergies"
                    value={child.allergies}
                    color="#e74c3c"
                  />
                </>
              )}
            </View>

            {/* Enrollment Information */}
            <View style={styles.contentSection}>
              <SectionHeader title="Enrollment Information" />

              <DetailItem
                icon="d380ff08-a482-403c-8e50-197d8d786299"
                label="First Day"
                value={formatDate(child.first_day)}
              />

              {child.last_day && (
                <>
                  <DetailItem
                    icon="d380ff08-a482-403c-8e50-197d8d786299"
                    label="Last Day"
                    value={formatDate(child.last_day)}
                  />

                  <DetailItem
                    icon="b1dfc59b-9df3-4449-8f25-f00fb59202e5"
                    label="Exit Reason"
                    value={
                      EXIT_REASON[child.exit_reason || "none_of_the_above"]
                    }
                  />

                  {child.exit_notes && (
                    <View style={styles.notesContainer}>
                      <Text style={styles.notesLabel}>Exit Notes:</Text>
                      <Text style={styles.notesText}>{child.exit_notes}</Text>
                    </View>
                  )}
                </>
              )}
            </View>

            {/* Notes */}
            <View style={styles.contentSection}>
              <SectionHeader title="Notes" />
              {(() => {
                if (isEditing) {
                  return (
                    <EditableDetailItem
                      field="notes"
                      label="Notes"
                      value={editedChild.notes}
                      multiline
                      onUpdateField={updateField}
                    />
                  );
                }
                if (child.notes) {
                  return (
                    <View style={styles.notesContainer}>
                      <Text style={styles.notesText}>{child.notes}</Text>
                    </View>
                  );
                }
                return (
                  <Text style={styles.emptyNotes}>No notes available</Text>
                );
              })()}
            </View>

            {/* Bottom spacing */}
            <View style={{ height: isEditing ? 400 : 24 }} />
          </>
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#fff"
  },
  scrollView: {
    flex: 1,
    width
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20
  },
  loadingText: {
    marginTop: 12,
    fontSize: 16,
    color: "#666"
  },
  errorContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20
  },
  errorText: {
    fontSize: 16,
    color: "#e74c3c",
    textAlign: "center",
    marginBottom: 16
  },
  retryButton: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: Rex.getConfig()?.colors?.button || "#333",
    borderRadius: 8
  },
  retryButtonText: {
    fontSize: 16,
    color: "#fff",
    fontWeight: "500"
  },
  headerSection: {
    flexDirection: "row",
    alignItems: "center",
    padding: 20,
    backgroundColor: "#fff",
    borderBottomWidth: 1,
    borderBottomColor: "#f0f0f0"
  },
  profilePhoto: {
    width: 80,
    height: 80,
    borderRadius: 40,
    backgroundColor: "#f0f0f0"
  },
  avatarContainer: {
    width: 80,
    height: 80,
    borderRadius: 40,
    backgroundColor: "#333",
    justifyContent: "center",
    alignItems: "center"
  },
  avatarText: {
    fontSize: 32,
    fontWeight: "bold",
    color: "#fff"
  },
  headerInfo: {
    marginLeft: 16,
    flex: 1
  },
  nameText: {
    fontSize: 22,
    fontWeight: "bold",
    color: "#212529",
    marginBottom: 4
  },
  ageText: {
    fontSize: 16,
    color: "#6c757d"
  },
  contentSection: {
    marginTop: 8,
    paddingHorizontal: 16
  },
  sectionHeader: {
    paddingVertical: 12,
    marginBottom: 8,
    borderBottomWidth: 1,
    borderBottomColor: "#f0f0f0"
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#212529"
  },
  detailItem: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 16,
    paddingHorizontal: 4
  },
  editableDetailItem: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 16,
    paddingHorizontal: 4,
    width: "100%"
  },
  detailIcon: {
    marginRight: 12,
    marginTop: 2
  },
  detailContent: {
    flex: 1
  },
  editableDetailContent: {
    flex: 1
  },
  detailLabel: {
    fontSize: 14,
    color: "#6c757d",
    marginBottom: 2
  },
  detailValue: {
    fontSize: 16,
    color: "#212529"
  },
  notesContainer: {
    backgroundColor: "#f8f9fa",
    padding: 12,
    borderRadius: 8,
    marginTop: 8,
    marginBottom: 16
  },
  notesLabel: {
    fontSize: 14,
    fontWeight: "500",
    color: "#212529",
    marginBottom: 4
  },
  notesText: {
    fontSize: 14,
    color: "#212529",
    lineHeight: 20
  },
  emptyNotes: {
    fontSize: 14,
    color: "#6c757d",
    fontStyle: "italic",
    marginTop: 8
  },
  nameInputContainer: {
    width: "100%"
  },
  editActionsContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingHorizontal: 16,
    paddingVertical: 8,
    gap: 10
  },
  cancelButton: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 4,
    backgroundColor: "#e0e0e0"
  },
  cancelButtonText: {
    color: "#333",
    fontWeight: "500",
    fontSize: 14
  },
  saveButton: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 4,
    backgroundColor: Rex.getConfig()?.colors?.button || "#007bff"
  },
  saveButtonText: {
    color: "#fff",
    fontWeight: "500",
    fontSize: 14
  },
  parentCard: {
    backgroundColor: "#f8f9fa",
    borderRadius: 8,
    padding: 12,
    marginBottom: 8,
    flexDirection: "column"
  },
  parentCardContent: {
    flexDirection: "row",
    alignItems: "center"
  },
  parentPhoto: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 12
  },
  parentAvatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: "#333",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12
  },
  parentAvatarText: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#fff"
  },
  parentInfo: {
    flex: 1
  },
  parentName: {
    fontSize: 16,
    fontWeight: "600",
    color: "#212529"
  },
  parentRoles: {
    fontSize: 14,
    color: "#6c757d",
    marginTop: 2
  },
  parentSkeletonContainer: {
    marginBottom: 8
  },
  noDataText: {
    fontSize: 14,
    color: "#6c757d",
    fontStyle: "italic",
    marginTop: 8
  },
  currentPaymentProfile: {
    backgroundColor: "#f8f9fa",
    borderRadius: 8,
    padding: 12,
    marginBottom: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  paymentProfileLabel: {
    fontSize: 13,
    color: "#4CAF50",
    fontWeight: "500",
    marginTop: 2
  },
  changePaymentProfileButton: {
    backgroundColor: "#edf2f7",
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 4,
    marginLeft: 12
  },
  changePaymentProfileText: {
    fontSize: 13,
    color: "#4a5568",
    fontWeight: "500"
  },
  noPaymentProfile: {
    backgroundColor: "#f8f9fa",
    borderRadius: 8,
    padding: 16,
    alignItems: "center"
  },
  noPaymentProfileText: {
    fontSize: 14,
    color: "#718096",
    marginBottom: 12,
    textAlign: "center"
  },
  addPaymentProfileButton: {
    backgroundColor: Rex.getConfig()?.colors?.button || "#4299e1",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 6
  },
  addPaymentProfileText: {
    fontSize: 14,
    color: "#fff",
    fontWeight: "600"
  },
  paymentProfileSelector: {
    backgroundColor: "#f8f9fa",
    borderRadius: 8,
    padding: 16,
    marginBottom: 8
  },
  paymentSelectorLabel: {
    fontSize: 14,
    fontWeight: "600",
    color: "#4a5568",
    marginBottom: 12
  },
  userSelectorScrollView: {
    maxHeight: 250,
    borderWidth: 1,
    borderColor: "#e2e8f0",
    borderRadius: 6,
    marginBottom: 16,
    backgroundColor: "#fff"
  },
  userSelectItem: {
    paddingVertical: 10,
    paddingHorizontal: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#f0f0f0"
  },
  selectedUserItem: {
    backgroundColor: "#ebf8ff"
  },
  userSelectItemContent: {
    flexDirection: "row",
    alignItems: "center"
  },
  userSelectAvatar: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: "#4299e1",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10
  },
  userSelectAvatarText: {
    fontSize: 14,
    fontWeight: "600",
    color: "#fff"
  },
  userSelectInfo: {
    flex: 1
  },
  userSelectName: {
    fontSize: 14,
    fontWeight: "600",
    color: "#2d3748"
  },
  userSelectEmail: {
    fontSize: 12,
    color: "#718096"
  },
  paymentProfileActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: 10
  },
  disabledButton: {
    opacity: 0.6
  },
  savingContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#f8f9fa",
    borderRadius: 6,
    padding: 12,
    marginVertical: 10
  },
  savingText: {
    marginLeft: 12,
    fontSize: 14,
    color: "#4a5568"
  },
  loadingIndicator: {
    padding: 8,
    marginLeft: 12
  }
});
