import React, { useState, useEffect, useMemo } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Image,
  RefreshControl,
  TouchableOpacity
} from "react-native";
import NavBar from "src/components/navBar";
import Glob from "src/globalConstants";
import Analytics from "src/backend/analytics";
import Database from "src/backend/database";
import Rex from "src/globalState";
import Icon from "src/components/Icon";
import TCLogoIcon from "src/components/TCLogoIcon";
import TCActivityContainer from "src/components/TCActivityContainer";
import AlertModal from "src/components/AlertModal";
import Util from "src/utility";
import Moment from "moment";

const { width } = Glob.get("dimensions");

export default function TCChildDashboard({ route, navigation }) {
  const { child } = route.params;
  const [loading, setLoading] = useState(true);
  const [childActivity, setChildActivity] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [forms, setForms] = useState([]);
  const [alert, setAlert] = useState(null);
  const [showAllForms, setShowAllForms] = useState(false);

  useEffect(() => {
    Analytics.logEvent("view_tcChildDashboard");
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      // Fetch child's activity
      const activityResponse = await Database.tcFetchOwnChildActivity(child.id);
      if (activityResponse.success && activityResponse.data) {
        setChildActivity(activityResponse.data);
      }

      // Fetch classrooms to display classroom information
      if (child.classroom_ids && child.classroom_ids.length > 0) {
        const classroomsResponse = await Database.tcFetchOwnClassrooms();
        if (classroomsResponse.success && classroomsResponse.data) {
          const childClassrooms = classroomsResponse.data.filter((classroom) =>
            child.classroom_ids.includes(classroom.id)
          );
          setClassrooms(childClassrooms);
        }
      }

      // Fetch forms to display form links
      if (child.id) {
        const formsResponse = await Database.tcFetchOwnChildForms(child.id);
        if (formsResponse.success && formsResponse.data) {
          // console.log("formsResponse.data");
          // console.log(JSON.stringify(formsResponse.data, null, 2));
          setForms(formsResponse.data);
        }
      }
    } catch (error) {
      console.error("Error fetching child data:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const onRefresh = () => {
    setRefreshing(true);
    fetchData();
  };

  const calculateAge = (birthDate) => {
    if (!birthDate) return "N/A";

    // Parse the date parts directly from the string to avoid timezone issues
    const [year, month, day] = birthDate
      .split("-")
      .map((num) => parseInt(num, 10));

    // Create a date using local timezone
    const bDate = new Date(year, month - 1, day);
    const today = new Date();

    let monthsTotal = (today.getFullYear() - bDate.getFullYear()) * 12;
    monthsTotal += today.getMonth() - bDate.getMonth();

    if (today.getDate() < bDate.getDate()) {
      monthsTotal -= 1;
    }

    const years = Math.floor(monthsTotal / 12);
    const months = monthsTotal % 12;

    let ageString = "";
    if (years > 0) {
      ageString += `${years} year${years !== 1 ? "s" : ""}`;
      if (months > 0) ageString += ` ${months} month${months !== 1 ? "s" : ""}`;
    } else {
      ageString = `${months} month${months !== 1 ? "s" : ""}`;
    }

    return ageString;
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";

    // Parse the date parts directly from the string to avoid timezone issues
    const [year, month, day] = dateString
      .split("-")
      .map((num) => parseInt(num, 10));

    // Create a date using local timezone by specifying all parts
    const date = new Date(year, month - 1, day);

    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric"
    });
  };

  const formatFormDate = (dateString) => {
    return Moment(dateString).format("MMM D, YYYY");
  };

  const getFormStateBackgroundColor = (state) => {
    if (state === "accepted") return "#d4edda";
    if (state === "submitted") return "#fff3cd";
    return "#d6f5ff";
  };

  const getFormStateTextColor = (state) => {
    if (state === "accepted") return "#155724";
    if (state === "submitted") return "#856404";
    return "#337b91";
  };

  const handlePreviewForm = (form) => {
    let messageContent = "";

    if (form.state === "submitted" || form.state === "accepted") {
      // For submitted or accepted forms, show the actual responses
      const formResponses = [];

      // Process each widget to match with field values
      form.formTemplate.widgets.forEach((widget) => {
        if (widget.staff_only) return; // Skip staff-only fields

        // Handle special case for name fields which are stored as first/last
        if (widget.title === "Child's Name") {
          const firstName = form.fields["Child's Name.first"] || "";
          const lastName = form.fields["Child's Name.last"] || "";
          formResponses.push(`• ${widget.title}: ${firstName} ${lastName}`);
        }
        // Handle checkbox groups which are arrays
        else if (
          widget.type === "CheckBoxGroup" &&
          Array.isArray(form.fields[widget.title])
        ) {
          formResponses.push(
            `• ${widget.title}: ${form.fields[widget.title].join(", ")}`
          );
        }
        // Handle all other fields
        else if (form.fields[widget.title] !== undefined) {
          formResponses.push(
            `• ${widget.title}: ${form.fields[widget.title] || "Not filled"}`
          );
        }
      });

      messageContent = `This form was created for ${
        child.first_name
      } on ${formatFormDate(
        form.created_at
      )}. Here are the responses that were submitted:\n${formResponses.join(
        "\n"
      )}`;
    } else {
      // For requested forms, just show the form structure
      const widgetsContent = form.formTemplate.widgets
        .filter((widget) => !widget.staff_only)
        .map((widget) => `• ${widget.title}`)
        .join("\n");

      messageContent = `This form was created for ${
        child.first_name
      } on ${formatFormDate(
        form.created_at
      )}. It includes the following questions:\n${widgetsContent}`;
    }

    setAlert({
      title: form.formTemplate.name,
      message: messageContent,
      cancel: { text: "Done Previewing", color: "gray" },
      confirm: {
        text: "Open Form ✍️",
        onPress: () => openFormLink(form.formLink)
      }
    });
  };

  const openFormLink = (formLink) => {
    Analytics.logEvent("touch_tcChildDashboard_openForm");
    Util.openURL(formLink);
  };

  const InfoCard = ({ title, children }) => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{title}</Text>
      {children}
    </View>
  );

  const DetailItem = ({ icon, label, value, color = "#6c757d" }) => {
    if (!value) return null;

    return (
      <View style={styles.detailItem}>
        {icon && (
          <Icon icon={icon} size={20} color={color} style={styles.detailIcon} />
        )}
        <View style={styles.detailContent}>
          <Text style={styles.detailLabel}>{label}</Text>
          <Text style={styles.detailValue}>{value}</Text>
        </View>
      </View>
    );
  };

  const navigateToActivityFeed = () => {
    Analytics.logEvent("touch_tcChildDashboard_viewAllActivity", {
      childID: child.id
    });
    navigation.push("tcActivityFeed", {
      childActivity,
      childName: `${child.first_name} ${child.last_name}`
    });
  };

  // Memoize the Recent Activity component to prevent unnecessary re-renders
  const MemoizedActivityComponent = useMemo(() => {
    const showViewMore = childActivity.length > 3;

    return (
      <InfoCard title="Recent Activity">
        {childActivity.length > 0 ? (
          <View>
            <TCActivityContainer
              activityData={childActivity}
              navigation={navigation}
              maxItems={3}
              showSearch={false}
              showViewMore={showViewMore}
              onViewMore={navigateToActivityFeed}
              numberOfLines={3}
              itemStyle={styles.dashboardPostCard}
            />
          </View>
        ) : (
          <Text style={styles.noDataText}>No recent activity</Text>
        )}
      </InfoCard>
    );
  }, [childActivity, navigation]);

  if (loading && !refreshing) {
    return (
      <View style={styles.container}>
        <NavBar
          navigation={navigation}
          text={`${child.first_name} ${child.last_name}`}
        />
        <View style={styles.loadingContainer}>
          <TCLogoIcon size={40} spinning />
          <Text style={styles.loadingText}>
            Loading {child.first_name}'s dashboard...
          </Text>
        </View>
      </View>
    );
  }

  // Determine gender text and icon
  let genderText = child?.gender;
  let genderIcon = "0c3e9c95-9597-476d-9c9f-7f0ce4dcac9d";
  if (genderText === "F") {
    genderText = "Female";
    genderIcon = "6084bd7a-b722-4153-a34d-1c4cc3e3c631";
  } else if (genderText === "M") {
    genderText = "Male";
    genderIcon = "0d55f237-4034-4630-82c1-1a6b7ce99f3f";
  } else if (genderText === "X") {
    genderText = "Nonbinary";
    genderIcon = "b34fdca3-fb02-4798-a2e5-990d3646df53";
  }

  return (
    <View style={styles.container}>
      <NavBar
        navigation={navigation}
        text={`${child.first_name} ${child.last_name}`}
      />

      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.scrollContent}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        showsVerticalScrollIndicator={false}
      >
        {/* Header with photo and basic info */}
        <View style={styles.headerSection}>
          {child.profile_photo && child.profile_photo.includes("http") ? (
            <Image
              source={{ uri: child.profile_photo }}
              style={styles.profilePhoto}
            />
          ) : (
            <View
              style={[
                styles.avatarContainer,
                {
                  backgroundColor: Rex.getConfig()?.colors?.background || "#333"
                }
              ]}
            >
              <Text style={styles.avatarText}>
                {child.first_name?.charAt(0)}
                {child.last_name?.charAt(0)}
              </Text>
            </View>
          )}

          <View style={styles.headerInfo}>
            <Text style={styles.nameText}>
              {child.first_name} {child.last_name}
            </Text>
            <Text style={styles.ageText}>{calculateAge(child.birth_date)}</Text>
          </View>
        </View>

        {/* Recent Activity Section */}
        {MemoizedActivityComponent}

        {/* Forms Section */}
        <InfoCard title="Forms">
          {forms.length > 0 ? (
            <View>
              {forms
                .slice()
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .slice(0, showAllForms ? forms.length : 6)
                .map((form) => (
                  <View key={form.id} style={styles.formItem}>
                    <TouchableOpacity
                      style={styles.formInfo}
                      onPress={() => handlePreviewForm(form)}
                    >
                      <Text style={styles.formName}>
                        {form.formTemplate.name}
                      </Text>
                      <Text style={styles.formDate}>
                        {formatFormDate(form.created_at)}
                      </Text>
                      <View style={styles.formStateContainer}>
                        <Text
                          style={[
                            styles.formState,
                            {
                              backgroundColor: getFormStateBackgroundColor(
                                form.state
                              ),
                              color: getFormStateTextColor(form.state)
                            }
                          ]}
                        >
                          {form.state.capitalize()}
                        </Text>
                      </View>
                    </TouchableOpacity>
                    <View style={styles.formActions}>
                      <TouchableOpacity
                        style={styles.formButton}
                        onPress={() => handlePreviewForm(form)}
                      >
                        <Icon
                          icon="75052aef-24e1-47f3-a1ff-db3ceaacce67"
                          size={22}
                          color={Rex.getConfig()?.colors?.button || "#337b91"}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={styles.formButton}
                        onPress={() => openFormLink(form.formLink)}
                      >
                        <Icon
                          icon="d24166f4-d993-4d3a-8692-ae839b70b6af"
                          size={22}
                          color={Rex.getConfig()?.colors?.button || "#337b91"}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                ))}

              {forms.length > 6 && !showAllForms && (
                <TouchableOpacity
                  style={[
                    styles.viewMoreButton,
                    {
                      backgroundColor: Util.getSuperLightColor(
                        Rex.getConfig()?.colors?.button || "#000000"
                      )
                    }
                  ]}
                  onPress={() => setShowAllForms(true)}
                >
                  <Text
                    style={[
                      styles.viewMoreText,
                      { color: Rex.getConfig()?.colors?.button || "black" }
                    ]}
                  >
                    View all forms ({forms.length})
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          ) : (
            <Text style={styles.noDataText}>No forms available</Text>
          )}
        </InfoCard>

        {/* Classroom Information */}
        <InfoCard title="Classroom Information">
          {classrooms.length > 0 ? (
            classrooms.map((classroom) => (
              <DetailItem
                key={classroom.id}
                icon="01f1d136-1b73-4f8f-98d6-e6c65f8433e0" // classroom/school icon
                label={classroom.name}
                value={`Level: ${classroom.level || "Not specified"}`}
              />
            ))
          ) : (
            <Text style={styles.noDataText}>
              No classroom information available
            </Text>
          )}
        </InfoCard>

        {/* Basic Information */}
        <InfoCard title="Basic Information">
          <DetailItem
            icon="0e52f4e2-68e5-40f5-bd8e-2aa22e80d868" // calendar icon
            label="Birth Date"
            value={formatDate(child.birth_date)}
          />

          <DetailItem icon={genderIcon} label="Gender" value={genderText} />

          {child.program && (
            <DetailItem
              icon="0e52f4e2-68e5-40f5-bd8e-2aa22e80d868" // program icon
              label="Program"
              value={child.program}
            />
          )}

          {child.grade && (
            <DetailItem
              icon="0e52f4e2-68e5-40f5-bd8e-2aa22e80d868" // grade icon
              label="Grade"
              value={child.grade}
            />
          )}

          {child.hours_string && (
            <DetailItem
              icon="46e962ab-43a3-48d6-acdd-8eb7f60d9405" // hours icon
              label="Hours"
              value={child.hours_string}
            />
          )}
        </InfoCard>

        {/* Health Information */}
        {child.allergies && (
          <InfoCard title="Health Information">
            <DetailItem
              icon="ab63e4da-7ea6-4f8e-a437-4687707517a2" // allergies/health icon
              label="Allergies"
              value={child.allergies}
              color="#e74c3c" // red color for allergies
            />
          </InfoCard>
        )}

        {/* Bottom spacing */}
        <View style={{ height: 40 }} />
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#f5f7fa"
  },
  scrollView: {
    flex: 1,
    width
  },
  scrollContent: {
    padding: 16
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20
  },
  loadingText: {
    marginTop: 12,
    fontSize: 16,
    color: "#666"
  },
  headerSection: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: 16,
    padding: 16,
    marginBottom: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 8,
    elevation: 2
  },
  profilePhoto: {
    width: 80,
    height: 80,
    borderRadius: 40,
    backgroundColor: "#f0f0f0"
  },
  avatarContainer: {
    width: 80,
    height: 80,
    borderRadius: 40,
    backgroundColor: "#333",
    justifyContent: "center",
    alignItems: "center"
  },
  avatarText: {
    fontSize: 32,
    fontWeight: "bold",
    color: "#fff"
  },
  headerInfo: {
    marginLeft: 16,
    flex: 1
  },
  nameText: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#212529",
    marginBottom: 4
  },
  ageText: {
    fontSize: 16,
    color: "#6c757d"
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: 16,
    padding: 16,
    marginBottom: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 8,
    elevation: 2
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#212529",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#f0f0f0",
    paddingBottom: 8
  },
  detailItem: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 16
  },
  detailIcon: {
    marginRight: 12,
    marginTop: 2
  },
  detailContent: {
    flex: 1
  },
  detailLabel: {
    fontSize: 14,
    color: "#6c757d",
    marginBottom: 2
  },
  detailValue: {
    fontSize: 16,
    color: "#212529"
  },
  noDataText: {
    fontSize: 14,
    color: "#6c757d",
    fontStyle: "italic",
    marginTop: 8,
    marginBottom: 8
  },
  dashboardPostCard: {
    backgroundColor: "white",
    padding: 12,
    marginBottom: 8,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#D3D8E0",
    shadowColor: "transparent",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0
  },
  formItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#e9ecef"
  },
  formInfo: {
    flex: 1
  },
  formName: {
    fontSize: 16,
    fontWeight: "600",
    color: "#212529",
    marginBottom: 4
  },
  formDate: {
    fontSize: 14,
    color: "#6c757d",
    marginBottom: 4
  },
  formStateContainer: {
    flexDirection: "row"
  },
  formState: {
    fontSize: 12,
    fontWeight: "500",
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 12,
    overflow: "hidden"
  },
  formActions: {
    flexDirection: "row",
    alignItems: "center"
  },
  formButton: {
    padding: 8,
    marginLeft: 8
  },
  viewMoreButton: {
    alignItems: "center",
    padding: 12,
    marginTop: 8,
    backgroundColor: "#f0f0f0",
    borderRadius: 8
  },
  viewMoreText: {
    fontSize: 14,
    fontWeight: "bold",
    color: "black"
  }
});
