import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, ScrollView } from "react-native";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Util from "src/utility";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import Checkbox from "src/components/Checkbox";
import Dropdown from "src/components/Dropdown";
import InputBox from "src/components/InputBox";

const { width } = Glob.get("dimensions");
const CODES = Glob.get("onespotCheckoutCodes");
const ONESPOT_K12_ID = "onespotk12";
const ONESPOT_MONTESSORI_ID = "montessori";

const HorizontalLine = () => (
  <View
    style={{
      height: 60,
      width: "90%",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center"
    }}
  >
    <View
      style={{
        height: 2,
        width: "100%",
        backgroundColor: "lightgray"
      }}
    />
  </View>
);

export default function SuperAdminDashboard({ navigation }) {
  // const [schoolType, setSchoolType] = useState("Montessori");
  const [freeTrialDays, setFreeTrialDays] = useState("0");
  const [allowNonACHPayments, setAllowNonACHPayments] = useState(false);
  // const [pricePerStudent, setPricePerStudent] = useState("48"); // Default's to the TC + Onespot bundle
  const pricePerStudent = "48";
  // const [removeStandaloneFee, setRemoveStandaloneFee] = useState(false);
  // const [allowPromoCodes, setAllowPromoCodes] = useState(false);
  // const [includeBuildFeeDiscount, setIncludeBuildFeeDiscount] = useState(false);
  const [prefilledName, setPrefilledName] = useState(null);
  const [prefilledEmail, setPrefilledEmail] = useState(null);
  const [prefilledSchoolName, setPrefilledSchoolName] = useState(null);
  const [prefilledNumberOfStudents, setPrefilledNumberOfStudents] = useState(
    null
  );
  const [autoSubmit, setAutoSubmit] = useState(false);
  const [saveToSpreadsheet, setSaveToSpreadsheet] = useState(true);
  const [isInOnespot, setIsInOnespot] = useState(false);
  const [isInOnespotK12, setIsInOnespotK12] = useState(false);
  const [isInTheMontessoriApp, setIsInTheMontessoriApp] = useState(false);
  const [isAppCreationEnabled, setIsAppCreationEnabled] = useState(
    Rex.getMetaApp()?.appCreation?.enabled
  );
  const [
    limitAppCreationToAdminsWhoCanPublish,
    setLimitAppCreationToAdminsWhoCanPublish
  ] = useState(Rex.getMetaApp()?.appCreation?.limitToAdminsWhoCanPublish);
  const [isAppPublishingEnabled, setIsAppPublishingEnabled] = useState(
    !Rex.getMetaApp()?.appCreation?.publishingDisabled
  );
  const [
    appCreationExplanationHeader,
    setAppCreationExplanationHeader
  ] = useState(Rex.getMetaApp()?.appCreation?.explanationHeader);
  const [
    appCreationExplanationDescription,
    setAppCreationExplanationDescription
  ] = useState(Rex.getMetaApp()?.appCreation?.explanationDescription);
  const [phoneCredits, setPhoneCredits] = useState(0);
  const [newPhoneCredits, setNewPhoneCredits] = useState("");
  const [totalApps, setTotalApps] = useState(null);
  const [metaAppID, setMetaAppID] = useState(null);
  const [billing, setBilling] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_superAdminDashboard");
    Database.fetchAppMetadata().then((appMetadata) =>
      setIsInOnespot(!appMetadata?.hideInOnespot)
    );
    Database.appIsInMetaApp(ONESPOT_K12_ID).then(setIsInOnespotK12);
    Database.appIsInMetaApp(ONESPOT_MONTESSORI_ID).then(
      setIsInTheMontessoriApp
    );
    Database.subscribeToTextsAndCalls((data) => {
      const { currentCredits } = data || {};
      setPhoneCredits(currentCredits);
    });
    Database.fetchTotalAppsCreated().then(setTotalApps);
    Util.localStorageGetItemAsync("metaAppID").then(setMetaAppID);
    Database.fetchBillingInfo().then(setBilling);
  }, []);

  // Generate checkout page URL
  // let checkoutPageURL = `https://themontessoriapp.com?checkout=${CODES.SCHOOL_TYPE[schoolType]}`;
  let checkoutPageURL = `https://themontessoriapp.com?checkout=${CODES.PRICE_PER_STUDENT[pricePerStudent]}`;
  if (freeTrialDays && freeTrialDays !== "0")
    checkoutPageURL += `-${CODES.FREE_TRIAL_DAYS[freeTrialDays]}`;
  if (allowNonACHPayments)
    checkoutPageURL += `-${CODES.ALLOW_NON_ACH_PAYMENTS}`;
  // if (pricePerStudent)
  //   checkoutPageURL += `-${CODES.PRICE_PER_STUDENT[pricePerStudent]}`;
  // if (removeStandaloneFee) checkoutPageURL += `-${CODES.REMOVE_STANDALONE_FEE}`;
  // if (allowPromoCodes) checkoutPageURL += `-${CODES.ALLOW_PROMO_CODES}`;
  // if (includeBuildFeeDiscount)
  //   checkoutPageURL += `-${CODES.ADD_BUILD_FEE_DISCOUNT}`;
  // Set form submission options
  if (!autoSubmit) checkoutPageURL += `-${CODES.DO_NOT_AUTO_SUBMIT}`;
  if (!saveToSpreadsheet)
    checkoutPageURL += `-${CODES.DO_NOT_SAVE_TO_SPREADSHEET}`;
  // Prefill info on the checkout page
  if (prefilledName) checkoutPageURL += `&name=${prefilledName}`;
  if (prefilledEmail) checkoutPageURL += `&email=${prefilledEmail}`;
  if (prefilledSchoolName) checkoutPageURL += `&school=${prefilledSchoolName}`;
  if (prefilledNumberOfStudents)
    checkoutPageURL += `&students=${prefilledNumberOfStudents}`;

  let checkoutPageURLForTesting = checkoutPageURL
    .replace(`-${CODES.DO_NOT_AUTO_SUBMIT}`, "")
    .replace("checkout=", `checkout=${CODES.DO_NOT_SAVE_TO_SPREADSHEET}-`);

  checkoutPageURL = encodeURI(checkoutPageURL);
  checkoutPageURLForTesting = encodeURI(checkoutPageURLForTesting);

  const billingPlatform = billing?.subscription?.stripeID
    ? "Stripe"
    : "RevenueCat";

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="Super Admin Zone" />
      <ScrollView
        style={{ paddingHorizontal: 15, width }}
        scrollIndicatorInsets={{ right: 1 }}
        keyboardDismissMode="on-drag"
      >
        <Text style={{ marginTop: 15, textAlign: "center" }}>
          <Text>Welcome to your administrative super </Text>
          <Text style={{ fontStyle: "italic" }}>duper </Text>
          <Text>powers 😎</Text>
        </Text>

        <HorizontalLine />

        <Text style={{ textAlign: "center", fontSize: 20 }}>
          Total Onespot Apps
        </Text>
        <Text style={{ fontWeight: "bold", textAlign: "center", fontSize: 50 }}>
          {(totalApps || "Loading...").toLocaleString()}
        </Text>

        <HorizontalLine />

        <Text style={Style.get("headerText")}>
          {Rex.getConfig()?.names?.nickname} billing info
        </Text>
        <Text>
          <Text style={{ fontWeight: "bold" }}>Billing platform:</Text>{" "}
          {billing?.subscription ? billingPlatform : "Not set up yet"}
        </Text>
        {billingPlatform === "RevenueCat" ? (
          <>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Product:</Text>{" "}
              {`${billing?.subscription?.details?.title || "(none)"}`}
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Price:</Text>{" "}
              {`${billing?.subscription?.details?.priceString || "(none)"}`}
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Billing Cadence:</Text>{" "}
              {`${billing?.subscription?.details?.subscriptionPeriod ||
                "(none)"}`}
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Product ID:</Text>{" "}
              {`${billing?.subscription?.revenueCatID || "(none)"}`}
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Customer ID:</Text>{" "}
              {`${billing?.customer?.revenueCatID || "(none)"}`}
            </Text>
          </>
        ) : (
          <>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Price:</Text>{" "}
              {`${
                billing?.subscription?.amountTotal
                  ? `$${billing.subscription.amountTotal / 100}`
                  : "(none)"
              }`}
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Country:</Text>{" "}
              {`${billing?.customer?.details?.address?.country || "(none)"}`}
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Subscription ID:</Text>{" "}
              {`${billing?.subscription?.stripeID || "(none)"}`}
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Customer ID:</Text>{" "}
              {`${billing?.customer?.stripeID || "(none)"}`}
            </Text>
          </>
        )}
        <Text>
          <Text style={{ fontWeight: "bold" }}>Checkout time:</Text>{" "}
          {`${billing?.subscription?.checkoutTimestamp || "(none)"}`}
        </Text>
        <Text>
          <Text style={{ fontWeight: "bold" }}>Email:</Text>{" "}
          {`${billing?.customer?.email || "(none)"}`}
        </Text>
        {billingPlatform === "RevenueCat" ? (
          <Button
            text="Open in RevenueCat"
            outline
            flat
            small
            onPress={() =>
              Util.openURL(
                `https://app.revenuecat.com/customers/0e8ca3d3/${billing?.customer?.revenueCatID}`
              )
            }
          />
        ) : (
          <Button
            text="Open in Stripe"
            outline
            flat
            small
            onPress={() =>
              Util.openURL(
                `https://dashboard.stripe.com/customers/${billing?.customer?.stripeID}`
              )
            }
          />
        )}

        <HorizontalLine />

        <Text style={Style.get("headerText")}>
          {Rex.getConfig()?.names?.nickname} meta app settings
        </Text>
        <Text style={styles.subheader}>
          Show this app ({Rex.getConfig()?.names?.full}) in these meta apps:
        </Text>
        <Checkbox
          text="Onespot"
          checked={isInOnespot}
          onChange={(checked) => {
            setIsInOnespot(checked);
            Database.setAppMetadata({ hideInOnespot: !checked || null });
          }}
        />
        <Checkbox
          text="Onespot K12"
          checked={isInOnespotK12}
          onChange={(checked) => {
            setIsInOnespotK12(checked);
            Database.toggleAppInMetaApp(ONESPOT_K12_ID, checked);
          }}
        />
        <Checkbox
          text="The Montessori App"
          checked={isInTheMontessoriApp}
          onChange={(checked) => {
            setIsInTheMontessoriApp(checked);
            Database.toggleAppInMetaApp(ONESPOT_MONTESSORI_ID, checked);
          }}
        />

        <HorizontalLine />

        <Text style={Style.get("headerText")}>
          {Rex.getConfig()?.names?.nickname} Phone Credits
        </Text>
        <Text style={Style.get("subheaderText")}>Current: {phoneCredits}</Text>
        <InputBox
          placeholder="Enter a new value here"
          onChangeText={setNewPhoneCredits}
          value={newPhoneCredits}
        />
        {newPhoneCredits !== "" && (
          <Button
            text="Set Phone Credits"
            flat
            small
            align="left"
            onPress={() => {
              Analytics.logEvent("touch_superAdminDashboard_setPhoneCredits", {
                oldPhoneCredits: phoneCredits,
                newPhoneCredits
              });
              Database.superAdminUpdatePhoneCredits(
                parseInt(newPhoneCredits, 10)
              );
              setNewPhoneCredits("");
            }}
          />
        )}

        <HorizontalLine />

        {!!Rex.getMetaApp()?.title && !!metaAppID && (
          <>
            <Text style={Style.get("headerText")}>
              Spot creation settings in the {Rex.getMetaApp()?.title} meta app
              ("{metaAppID}
              ")
            </Text>
            <Text style={Style.get("subheaderText")}>
              Note: You need to close and reopen the app to see these changes
            </Text>
            <Text style={styles.subheader}>Options</Text>
            <Checkbox
              text="Allow creating spots"
              checked={isAppCreationEnabled}
              onChange={(checked) => {
                setIsAppCreationEnabled(checked);
                Database.updateMetaAppCreationSettings(metaAppID, {
                  enabled: checked
                });
              }}
            />
            <Checkbox
              disabled={!isAppCreationEnabled}
              text="Limit creating spots to admins who have the 'publishing & privacy' superpower"
              checked={limitAppCreationToAdminsWhoCanPublish}
              onChange={(checked) => {
                setLimitAppCreationToAdminsWhoCanPublish(checked);
                Database.updateMetaAppCreationSettings(metaAppID, {
                  limitToAdminsWhoCanPublish: checked
                });
              }}
            />
            <Checkbox
              text="Allow publishing spots"
              checked={isAppPublishingEnabled}
              onChange={(checked) => {
                setIsAppPublishingEnabled(checked);
                Database.updateMetaAppCreationSettings(metaAppID, {
                  publishingDisabled: !checked
                });
              }}
            />
            <Text style={styles.subheader}>Explanation Header</Text>
            <InputBox
              placeholder="Default: 'Make an app'"
              onChangeText={setAppCreationExplanationHeader}
              value={appCreationExplanationHeader}
            />
            <Button
              text="Save Header"
              flat
              small
              align="left"
              onPress={() => {
                Database.updateMetaAppCreationSettings(metaAppID, {
                  explanationHeader: appCreationExplanationHeader
                });
              }}
            />
            <Text style={styles.subheader}>Explanation Description</Text>
            <InputBox
              placeholder="Default: 'for your business, organization, or community in 15 minutes, with no code, right from your phone. Seriously.'"
              onChangeText={setAppCreationExplanationDescription}
              value={appCreationExplanationDescription}
              multiline
            />
            <Button
              text="Save Description"
              flat
              small
              align="left"
              onPress={() => {
                Database.updateMetaAppCreationSettings(metaAppID, {
                  explanationDescription: appCreationExplanationDescription
                });
              }}
            />
            <HorizontalLine />
          </>
        )}

        <Text style={Style.get("headerText")}>
          Generate a Transparent Classroom + Onespot bundle checkout page
        </Text>
        {/* <Text style={styles.subheader}>School Type</Text>
        <Dropdown
          value={schoolType}
          items={Object.keys(CODES.SCHOOL_TYPE).map((t) => ({
            value: t,
            text: t
          }))}
          onSelect={setSchoolType}
        /> */}
        <Text style={styles.subheader}>Free Trial</Text>
        <Dropdown
          value={freeTrialDays}
          items={Object.keys(CODES.FREE_TRIAL_DAYS).map((d) => ({
            value: d,
            text: d === "0" ? "None" : `${d}-day trial`
          }))}
          onSelect={setFreeTrialDays}
        />
        <Text style={styles.subheader}>Payment Methods</Text>
        <Checkbox
          text="Restrict to ACH Payments Only (U.S. Bank)"
          checked={!allowNonACHPayments}
          onChange={() => setAllowNonACHPayments(!allowNonACHPayments)}
        />
        {/* <Text style={styles.subheader}>Price per Student</Text>
        <Dropdown
          value={pricePerStudent}
          items={Object.keys(CODES.PRICE_PER_STUDENT).map((p) => ({
            value: p,
            text: `$${p}`
          }))}
          onSelect={setPricePerStudent}
        /> */}
        {/* <Text style={styles.subheader}>Fees</Text>
        <Checkbox
          text="Include the standalone app fee (+$1,200/year)"
          checked={!removeStandaloneFee}
          onChange={(checked) => setRemoveStandaloneFee(!checked)}
        /> */}
        {/* <Text style={styles.subheader}>Discounts</Text>
        <Checkbox
          text="Allow promo codes"
          checked={allowPromoCodes}
          onChange={(checked) => {
            if (checked) setIncludeBuildFeeDiscount(false); // can't have both
            setAllowPromoCodes(checked);
          }}
        />
        <Checkbox
          text="Waive the one-time build fee (-$1,200)"
          checked={includeBuildFeeDiscount}
          onChange={(checked) => {
            if (checked) setAllowPromoCodes(false); // can't have both
            setIncludeBuildFeeDiscount(checked);
          }}
        /> */}

        <Text style={styles.subheader}>Prefill Info</Text>
        <InputBox
          placeholder="Customer name"
          onChangeText={setPrefilledName}
          value={prefilledName}
        />
        <InputBox
          placeholder="Customer email"
          onChangeText={setPrefilledEmail}
          value={prefilledEmail}
          keyboardType="email-address"
        />
        <InputBox
          placeholder="School name"
          onChangeText={setPrefilledSchoolName}
          value={prefilledSchoolName}
        />
        <InputBox
          placeholder="Number of students"
          onChangeText={(number) => {
            if (!number) setAutoSubmit(false);
            setPrefilledNumberOfStudents(number);
          }}
          value={prefilledNumberOfStudents}
          keyboardType="number-pad"
        />
        <Text style={styles.subheader}>Form Submission</Text>
        <Checkbox
          text="Save responses to spreadsheet"
          checked={saveToSpreadsheet}
          onChange={setSaveToSpreadsheet}
        />
        <Checkbox
          text="Automatically submit form with prefilled info"
          disabled={!prefilledNumberOfStudents}
          checked={autoSubmit}
          onChange={setAutoSubmit}
        />

        <Text style={styles.subheader}>Checkout Page</Text>
        <View style={{ backgroundColor: "lightyellow", padding: 5 }}>
          <Text style={{ fontWeight: "bold" }}>{checkoutPageURL}</Text>
        </View>
        <Button
          text="🧑‍🔬 Test the Pricing"
          outline
          disabled={!prefilledNumberOfStudents}
          style={{ opacity: prefilledNumberOfStudents ? 1 : 0.2 }}
          onPress={() => Util.openURL(checkoutPageURLForTesting)}
        />
        <Button
          text="🛒 Open Checkout Page"
          outline
          onPress={() => Util.openURL(checkoutPageURL)}
        />
        <Button
          text="📖 View All Responses"
          flat
          outline
          onPress={() =>
            Util.openURL(
              "https://docs.google.com/spreadsheets/d/19kRXNht3_TTaV6rVKw9qKrLhJhTT3FAa9iGT6e-jWT8/edit#gid=1713764517"
            )
          }
        />

        <HorizontalLine />

        <Text style={Style.get("headerText")}>Other checkout pages</Text>
        <Text style={styles.subheader}>Onespot Pro ($65/month)</Text>
        <Text>https://buy.stripe.com/5kA0412VLdsMfnidRG</Text>
        <Button
          text="Open"
          flat
          small
          outline
          align="left"
          onPress={() =>
            Util.openURL("https://buy.stripe.com/5kA0412VLdsMfnidRG")
          }
        />
        <Text style={styles.subheader}>Onespot Basic ($29/month)</Text>
        <Text>https://buy.stripe.com/6oEbMJ2VL0G0grmg05</Text>
        <Button
          text="Open"
          flat
          small
          outline
          align="left"
          onPress={() =>
            Util.openURL("https://buy.stripe.com/6oEbMJ2VL0G0grmg05")
          }
        />

        <View style={{ height: 100 }} />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  subheader: {
    ...Style.get("subheaderText"),
    marginTop: 15
  }
});
