import React, { useEffect, useState } from "react";
import { Platform, StyleSheet, Text, View, ScrollView } from "react-native";
import WebView from "react-native-webview";
import LottieView from "src/components/Lottie";
import Glob from "src/globalConstants";
import Style from "src/globalStyles";
import Util from "src/utility";
import Analytics from "src/backend/analytics";
import Database from "src/backend/database";
import Rex from "src/globalState";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import AlertModal from "src/components/AlertModal";

const PRODUCT_ASSEMBLY_ANIMATION = require("resources/animations/productAssembly.json");

const { height, width } = Glob.get("dimensions");

export default function Help({ navigation, route }) {
  const { isSchool } = route.params || {};
  const [user, setUser] = useState(null);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_help");
    Database.fetchAllUserData().then((userData) => {
      setUser(userData);
    });
  }, []);

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="Contact Support" />
      <ScrollView
        style={{ paddingHorizontal: 15, marginTop: 5, width }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        <View>
          <View style={{ height: height / 3.8, alignItems: "center" }}>
            <LottieView
              autoPlay
              loop
              style={{ height: "100%", width: "100%" }}
              source={PRODUCT_ASSEMBLY_ANIMATION}
            />
          </View>
          <Text
            style={{
              ...Style.get("headerText"),
              marginTop: 15
            }}
          >
            Confused by any of our features?
          </Text>
          <Text style={styles.bodyText}>Don't panic!</Text>
          <Text style={styles.bodyText}>
            We believe in <Text style={{ fontWeight: "bold" }}>insanely</Text>{" "}
            good customer service.
          </Text>
          <Text style={styles.bodyText}>
            That's why we give you a bunch of options to get in touch with us
            directly about any question or concern, no matter how small. No
            phone trees, no bots, no nonsense.
          </Text>
        </View>
        <View style={{ marginHorizontal: 15, marginVertical: 20 }}>
          {isSchool && (
            <Button
              text="Schedule a Video Call"
              onPress={() => {
                Analytics.logEvent("touch_help_scheduleCall");
                if (Platform.OS === "web") {
                  Util.openURL(
                    `https://calendly.com/seancann/school-app-support-session?primary_color=5b59d6&name=${user.firstName ||
                      ""} ${user.lastName || ""}&email=${user.email ||
                      ""}&a1=${Rex.getConfig()?.names?.full || ""}`,
                    "_blank"
                  );
                } else {
                  setAlert({
                    title: "Schedule a Video Call",
                    message:
                      "Select any time open on the calendar below to schedule a video call with us.",
                    customView: (
                      <View
                        style={{ height: 0.6 * height, width: 0.8 * width }}
                      >
                        <WebView
                          style={{ flex: 1 }}
                          onLoadStart={() => {}}
                          onLoadEnd={() => {}}
                          source={{
                            // todo: swich with this: https://calendly.com/contact-onespotapps/onespot-tech-support-meeting
                            uri: `https://calendly.com/seancann/school-app-support-session?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=5b59d6&name=${user.firstName ||
                              ""} ${user.lastName || ""}&email=${user.email ||
                              ""}&a1=${Rex.getConfig()?.names?.full || ""}`
                          }}
                          userAgent="Mozilla/5.0 (iPhone; CPU iPhone OS 15_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/95.0.4638.50 Mobile/15E148 Safari/604.1"
                        />
                      </View>
                    ),
                    confirm: { text: "Close" }
                  });
                }
              }}
            />
          )}
          <Button
            text="Message Us"
            onPress={() => {
              Analytics.logEvent("touch_help_chatMessage");
              Util.startChatWithSuperAdmin(navigation, true);
            }}
          />
          <Button
            text="Email Us"
            type="email"
            outline
            options={{ emailAddress: "team@seabirdapps.com" }}
          />
          <Button
            text="Call Our CEO"
            type="call"
            outline
            options={{ phoneNumber: "4407817739" }}
          />
          <Text style={styles.noteText}>
            (Please leave a voicemail if he doesn't answer)
          </Text>
          <Button
            text="Text Our CEO"
            type="sms"
            outline
            options={{ smsRecipient: "4407817739" }}
          />
        </View>
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}

const styles = StyleSheet.create({
  list: {
    flex: 1
  },
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  questionDescription: {
    fontSize: 0.021 * height,
    color: "gray"
  },
  bodyText: {
    fontSize: 16,
    marginTop: 10
  },
  noteText: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: "center"
  },
  textInput: {
    fontSize: 0.03 * height,
    width: 0.82 * width,
    height: 0.056 * height,
    borderWidth: 1,
    borderColor: Glob.get("light gray"),
    borderRadius: 6,
    paddingLeft: 8,
    marginTop: 8,
    color: "black"
  }
});
